import React from "react";
import { useParams } from "react-router-dom";

import ViewWrapper from "../../../../../components/view-wrapper";
import ReportTable from "../../../../../components/report-table";
import DealOutputFilterFields from "../../../../../components/deal-output-filter-fields";
import { downloadUserDealReport } from "../../../../../apis/report/deal";
import { useAPI, useSessionStorage } from "../../../../../utils/hooks";
import { AppContext } from "../../../../../utils/context/app-context";
import { useAxios } from "../../../../../components/axios-provider";
import {
  getDealReportingUrl,
  generateCashReportTableRows,
} from "../../../../../utils/helpers";
import {
  CASH_REPORT_TABLE_COLUMNS_SPONSOR_EQUITY_FOR_DP,
  CASH_REPORT_TABLE_COLUMNS_SPONSOR_EQUITY_FOR_TRANSFER,
  CASH_REPORT_TABLE_COLUMNS_WO_TRANSFER_PROCEEDS_SPONSOR_EQUITY,
} from "../../../../../constants";
import {
  IReport,
  ITableRow,
  ReportTerm,
  ReportType,
  ReportPerspective,
  OutputReportPeriodicityType,
} from "../../../../../interfaces";

interface IProps {
  getDealReporting: (
    dealId: number,
    reportPerspective: ReportPerspective,
    reportType: ReportType,
    reportTerm: ReportTerm,
    reportPeriodicityType: OutputReportPeriodicityType,
  ) => Promise<IReport>;
}

export default function DealOutputSponsorEquityCashView({
  getDealReporting,
}: IProps): JSX.Element {
  const { dealId, caseDealId } = useParams();

  const { urlLoadingPercentages } = useAxios();

  const { currentDeal } = React.useContext(AppContext);

  const [report, setReport] = React.useState<IReport>();
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  const { setDealOutputLoaded } = React.useContext(AppContext);

  const [buyout, setBuyout] = useSessionStorage<ReportTerm>(
    `deal-${dealId}-output-buyout`,
    "default",
  );
  const [periodicityType, setPeriodicityType] =
    useSessionStorage<OutputReportPeriodicityType>(
      `deal-${dealId}-output-periodicity`,
      "MO",
    );

  React.useEffect(() => {
    if (Number(caseDealId)) {
      getDealReportingCallAPI(Number(caseDealId), buyout, periodicityType).then(
        (response) => {
          if (response?.["pship/cashdetail"]) {
            setReport(response);
          }
        },
      );
    }
  }, [caseDealId, buyout, periodicityType]);

  const {
    callAPI: getDealReportingCallAPI,
    errored: getReportFailed,
    loading: loadingReport,
    errorObj: getReportErrorObj,
    showUsageLimitHitScreen,
  } = useAPI(
    (
      dealId: number,
      term: ReportTerm,
      reportPeriodicityType: OutputReportPeriodicityType,
    ) =>
      getDealReporting(
        dealId,
        "sponsorequity",
        "cashdetail",
        term,
        reportPeriodicityType,
      ),
    { initialLoading: true },
  );

  React.useEffect(() => {
    !loadingReport && setDealOutputLoaded(!getReportFailed);
  }, [getReportFailed, loadingReport]);

  const cashTableRows: ITableRow[] = React.useMemo(() => {
    return report ? generateCashReportTableRows(report) : [];
  }, [report]);

  const handleDownloadReport = async () => {
    if (caseDealId) {
      setIsDownloading(true);
      await downloadUserDealReport(
        Number(caseDealId),
        "sponsorequity",
        "cashdetail",
        buyout,
        periodicityType,
      ).catch(() => null);
      setIsDownloading(false);
    }
  };

  const sponsorEquityCashTableColumns = React.useMemo(() => {
    if (!report) return [];
    if (report?.["pship/cashdetail"].transfer_proceeds !== undefined) {
      return currentDeal?.tax_credit_structure === "DP"
        ? CASH_REPORT_TABLE_COLUMNS_SPONSOR_EQUITY_FOR_DP
        : CASH_REPORT_TABLE_COLUMNS_SPONSOR_EQUITY_FOR_TRANSFER;
    }
    return CASH_REPORT_TABLE_COLUMNS_WO_TRANSFER_PROCEEDS_SPONSOR_EQUITY;
  }, [currentDeal, report]);

  return (
    <ViewWrapper
      loading={loadingReport}
      error={getReportFailed}
      errorDescription={getReportErrorObj?.description}
      errorHeading={getReportErrorObj?.heading}
      showLimitHitView={showUsageLimitHitScreen}
      loadingPercentage={
        urlLoadingPercentages[
          getDealReportingUrl(
            Number(caseDealId),
            "sponsorequity",
            "cashdetail",
            buyout,
            periodicityType,
          )
        ]
      }
    >
      <DealOutputFilterFields
        buyout={buyout}
        periodicityType={periodicityType}
        setBuyout={setBuyout}
        setPeriodicityType={setPeriodicityType}
        showBuyout={report?.has_investor_buyout}
        downloadReport={handleDownloadReport}
        disableDownload={isDownloading}
        showPeriodicity
        showSizingRedirectionButton
      />
      <ReportTable
        columns={sponsorEquityCashTableColumns}
        hideCols={buyout === "default" ? ["equity_buyout"] : []}
        rows={cashTableRows}
        boldTotal
        stickyTotal
      />
    </ViewWrapper>
  );
}
