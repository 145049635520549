import React from "react";
import ArrowBackIcon from "@mui/icons-material/NavigateBefore";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Typography from "@mui/material/Typography";
import MuiButton from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { isEmpty, isString, max } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { Protect } from "@clerk/clerk-react";
import {
  DataGridPro,
  DataGridProProps,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridCsvExportMenuItem,
  GridRowClassNameParams,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";

import useStyles from "./styles";
import Modal from "../../../components/modal";
import Tooltip from "../../../components/tooltip";
import Button from "../../../components/button";
import Layout from "../../../components/layout";
import ViewWrapper from "../../../components/view-wrapper";
import DealPagesLayout from "../../../components/deal-pages-layout";
import UpdateDealComparisonFormModal from "../../../components/update-deal-comparison-form-modal";
import UpdateDealCaseComparisonFormModal from "../../../components/update-deal-case-comparison-form-modal";
import CheckboxInput from "../../../components/checkbox-input";
import { getDealCasesLite } from "../../../apis/deal/case";
import { AppContext } from "../../../utils/context/app-context";
import { updateDealCaseComparison } from "../../../apis/deal/analysis";
import { useAPI, useLocalStorage } from "../../../utils/hooks";
import {
  cn,
  trimString,
  getDealCapitalSourcesComparisonRows,
  getDealCashEquityComparisonRows,
  getDealComparisonRows,
  getDealCostComparisonRows,
  getDealCostsPerWattDcComparisonRows,
  getDealDebtComparisonRows,
  getDealDirectPayComparisonRows,
  getDealGeneralComparisonRows,
  getDealPartnershipPorfolioComparisonRows,
  getDealProformaComparisonRows,
  getDealSponsorEquityComparisonRows,
  getDealTaxCreditComparisonRows,
  getDealTaxEquityComparisonRows,
  getDealTimingComparisonRows,
  getDealTransferComparisonRows,
  getDealCreditSupportComparisonRows,
  getDealFeesComparisonRows,
  getDealDepreciationComparisonRows,
} from "../../../utils/helpers";
import {
  IDealCase,
  IDeal,
  IGetDealsParams,
  ISelectOption,
  IUpdateDealCaseComparisonForm,
  IUpdateDealCaseComparisonFormErrors,
  ServerPaginatedResponse,
  IAddDealComparisonFormErrors,
  IDealComparison,
  IDealComparisonInputs,
  IDealComparisonOutputs,
  IUpdateDealComparisonForm,
  IDealCaseLite,
} from "../../../interfaces";
import {
  USER_PERMISSIONS,
  DEAL_STRUCTURE_TYPE,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE,
  UPDATE_DEAL_COMPARISON_FORM_DEFAULT_STATE,
  UPDATE_CASE_COMPARISON_FORM_DEFAULT_STATE,
} from "../../../constants";

interface IProps {
  getDeals: (
    params: IGetDealsParams,
  ) => Promise<ServerPaginatedResponse<IDeal[]>>;
  getDealComparison: (id: number) => Promise<IDealComparison>;
  updateDealComparison: (
    id: number,
    form: IUpdateDealComparisonForm,
  ) => Promise<IDealComparison>;
  deleteDealComparison: (id: number) => Promise<boolean>;
  getDealAnalysisInputs: (
    dealComparisonId: number,
    dealId: number,
  ) => Promise<{ data: IDealComparisonInputs }>;
  getDealAnalysisOutputs: (
    dealComparisonId: number,
    dealId: number,
  ) => Promise<{ data: IDealComparisonOutputs }>;
  type: "deal" | "case";
}

export default function DealComparisonDetail({
  getDeals,
  getDealComparison,
  updateDealComparison,
  deleteDealComparison,
  getDealAnalysisInputs,
  getDealAnalysisOutputs,
  type,
}: IProps): JSX.Element {
  const styles = useStyles({ reduceHeight: type === "deal" ? 205 : 295 });

  const { dealId, id } = useParams();
  const navigate = useNavigate();

  const { setConfirmDeleteModalProps } = React.useContext(AppContext);

  const [showDifferences, setShowDifferences] = useLocalStorage<boolean>(
    `show-differences-${type}-${id}`,
    false,
  );
  const [highlightDifferences, setHighlightDifferences] =
    useLocalStorage<boolean>(`highlight-differences-${type}-${id}`, false);

  const [collapseAll, setCollapseAll] = React.useState<boolean>(false);
  const [fullScreen, setFullScreen] = React.useState<boolean>(false);
  const [reportsLoaded, setReportsLoaded] = React.useState(false);
  const [dealAnalysisInput, setDealAnalysisInput] = React.useState<
    IDealComparisonInputs[]
  >([]);
  const [dealAnalysisOutput, setDealAnalysisOutput] = React.useState<
    IDealComparisonOutputs[]
  >([]);

  const [dealComparisonData, setDealComparisonData] =
    React.useState<IDealComparison>();
  const [form, setForm] = React.useState<IUpdateDealComparisonForm>(
    UPDATE_DEAL_COMPARISON_FORM_DEFAULT_STATE,
  );
  const [updateDealCaseComparisonForm, setUpdateDealCaseComparisonForm] =
    React.useState<IUpdateDealCaseComparisonForm>(
      UPDATE_CASE_COMPARISON_FORM_DEFAULT_STATE,
    );
  const [openEditFormModal, setOpenEditFormModal] =
    React.useState<boolean>(false);
  const [dealOptions, setDealOptions] = React.useState<ISelectOption[]>([]);
  const [dealCasesLite, setDealCasesLite] = React.useState<IDealCaseLite[]>([]);

  const {
    callAPI: getDealComparisonCallAPI,
    errored: getDealComparisonFailed,
    loading: loadingDealComparison,
  } = useAPI((id: number) => getDealComparison(id), { initialLoading: true });

  const { callAPI: getDealsCallAPI } = useAPI(() => getDeals({}));

  const { callAPI: deleteDealComparisonCallAPI } = useAPI(
    (id: number) => deleteDealComparison(id),
    { setConfirmModalLoading: true },
  );

  const {
    callAPI: updateDealComparisonCallAPI,
    loading: loadingUpdateDealComparison,
    fieldErrors: formErrors,
    setFieldErrors: setFormErrors,
  } = useAPI<IDealComparison, IAddDealComparisonFormErrors>(
    (id: number, form: IUpdateDealComparisonForm) => {
      const updatedForm = {
        name: form.name,
        deal_ids: [form.benchmark_deal, ...form.deal_ids],
      };
      return updateDealComparison(id, updatedForm as IUpdateDealComparisonForm);
    },
    { initialLoading: false },
  );

  const { callAPI: getDealAnalysisInputsCallAPI } = useAPI(
    (dealComparisonId: number, dealId: number) =>
      getDealAnalysisInputs(dealComparisonId, dealId)
        .then((res) => res.data)
        .catch(() => null),
    { initialLoading: true },
  );

  const { callAPI: getDealAnalysisOutputsCallAPI } = useAPI(
    (dealComparisonId: number, dealId: number) =>
      getDealAnalysisOutputs(dealComparisonId, dealId)
        .then((res) => res.data)
        .catch(() => null),
    { initialLoading: true },
  );

  React.useEffect(() => {
    getDealComparisonCallAPI(Number(id)).then((response) => {
      if (response) {
        setDealComparisonData(response);
        // don't allow deal analysis to open through case analysis ui
        if (type === "case" && response?.base_case_deal === null) {
          navigate(`/deal/${dealId}/analysis`);
        }
        // don't allow case analysis to open through deal analysis ui
        if (type === "deal" && response?.base_case_deal !== null) {
          navigate(`/analysis/deal-comparison-list`);
        }
      }
    });
    if (type === "case") {
      getDealCasesLiteCallAPI(Number(dealId)).then((res) => {
        res && setDealCasesLite(res);
      });
    }
  }, [id]);

  React.useEffect(() => {
    if (id && (dealComparisonData?.deals_to_compare?.length || 0) > 0) {
      getDealAnalysisInputOutput();
    }
  }, [id, dealComparisonData]);

  const getDealAnalysisInputOutput = async () => {
    await Promise.all([
      getDealAnalysisInput(),
      getDealAnalysisOutput(),
    ]).finally(() => setReportsLoaded(true));
  };

  const getDealAnalysisInput = async () => {
    const promises = (dealComparisonData?.deals_to_compare || []).map(
      async (deal) => {
        try {
          const res = await getDealAnalysisInputsCallAPI(id, deal?.id);

          const index =
            dealComparisonData?.deals_to_compare?.findIndex(
              (d) => d.id === deal?.id,
            ) || 0;

          setDealAnalysisInput((prev: IDealComparisonInputs[]) => {
            prev[index] = res as IDealComparisonInputs;
            return prev;
          });
        } catch (error) {
          console.error(`Error fetching input for deal ${deal?.id}:`, error);
        }
      },
    );

    // Wait for all promises to resolve
    await Promise.all(promises);
  };

  const getDealAnalysisOutput = async () => {
    const promises = (dealComparisonData?.deals_to_compare || []).map(
      async (deal) => {
        try {
          const res = await getDealAnalysisOutputsCallAPI(id, deal?.id);

          const index =
            dealComparisonData?.deals_to_compare?.findIndex(
              (d) => d.id === deal?.id,
            ) || 0;

          setDealAnalysisOutput((prev: IDealComparisonOutputs[]) => {
            prev[index] = res as IDealComparisonOutputs;
            return prev;
          });
        } catch (error) {
          console.error(`Error fetching output for deal ${deal?.id}:`, error);
        }
      },
    );

    // Wait for all promises to resolve
    await Promise.all(promises);
  };

  React.useEffect(() => {
    getDealsCallAPI().then((res) => {
      res &&
        res.results &&
        setDealOptions(
          res.results.map((data) => {
            return {
              label: data?.name,
              value: String(data?.id),
            };
          }),
        );
    });
  }, []);

  const { callAPI: getDealCasesLiteCallAPI } = useAPI((dealId) =>
    getDealCasesLite(Number(dealId)),
  );

  const {
    callAPI: updateDealCaseComparisonCallAPI,
    fieldErrors: updateDealCaseFormErrors,
    setFieldErrors: setUpdateDealCaseFormErrors,
    loading: loadingUpdateDealCaseComparison,
  } = useAPI<IDealComparison, IUpdateDealCaseComparisonFormErrors>(
    (dealId, form: IUpdateDealCaseComparisonForm) => {
      const updatedForm = {
        base_case_deal: form.base_case_deal,
        deal_ids: [form.base_case_deal, ...form.deal_ids],
        name: form.name,
        id: Number(id),
      };
      return updateDealCaseComparison(Number(dealId), updatedForm);
    },
  );

  const handleEditDealComparison = async () => {
    if (dealComparisonData) {
      const { name, benchmark_deal, deals_to_compare } = dealComparisonData;
      setForm({
        name,
        benchmark_deal: String(benchmark_deal?.id),
        deal_ids: deals_to_compare
          .map((d) => String(d?.id))
          .filter((d) => d !== String(benchmark_deal?.id)),
        id: Number(id),
      });
      if (type === "case") {
        await getDealCasesLiteCallAPI(Number(dealId)).then((res) => {
          res && setDealCasesLite(res);
        });
        setUpdateDealCaseComparisonForm({
          deal_ids: deals_to_compare
            .map((d) => String(d?.id))
            .filter((d) => d !== String(benchmark_deal?.id)),
          base_case_deal: String(dealComparisonData?.benchmark_deal?.id),
          name: dealComparisonData?.name,
          id: Number(id),
        });
      }
      setOpenEditFormModal(true);
    }
  };

  const handleUpdate = async (form: IUpdateDealComparisonForm) => {
    const deal = await updateDealComparisonCallAPI(Number(id), form);

    if (deal && dealComparisonData) {
      const { name, benchmark_deal, deals_to_compare } = deal;
      setDealComparisonData(() => ({
        ...dealComparisonData,
        name,
        benchmark_deal,
        deals_to_compare,
      }));
      window.location.reload(); // TODO refreshing page isn’t ideal long term
    }

    return deal;
  };

  const handleUpdateDealCase = async (form: IUpdateDealCaseComparisonForm) => {
    const deal = await updateDealCaseComparisonCallAPI(Number(id), form);
    if (deal && dealComparisonData) {
      const { name, benchmark_deal, deals_to_compare } = deal;
      setDealComparisonData(() => ({
        ...dealComparisonData,
        name,
        benchmark_deal,
        deals_to_compare,
      }));
      window.location.reload(); // TODO refreshing page isn’t ideal long term
    }
    return deal;
  };

  const handleDeleteDealComparison = async (id: number) => {
    const deleted = await deleteDealComparisonCallAPI(id);

    if (deleted) {
      const redirectUrl =
        type === "deal"
          ? `/analysis/deal-comparison-list/`
          : `/deal/${dealId}/analysis/`;
      navigate(redirectUrl);
    }
  };

  const handleOnDelete = (id: number | undefined) => {
    if (id === undefined) {
      return;
    }
    setConfirmDeleteModalProps({
      open: true,
      title: "Delete Deal Comparison",
      description: "Are you sure you want to delete?",
      onConfirm: () => handleDeleteDealComparison(id),
    });
  };

  const dealIds: number[] = React.useMemo(() => {
    return (dealComparisonData?.deals_to_compare || []).map((d) => d.id);
  }, [dealComparisonData]);

  const columns: GridColDef[] = [
    ...dealIds.map((dId) => ({
      field: String(dId),
      headerName: dealComparisonData?.deals_to_compare?.find((d) => {
        return d?.id === dId;
      })?.name,
      width: 250,
      headerClassName: () => {
        if (dId === dealComparisonData?.benchmark_deal?.id) {
          return "dealHeading bgGray";
        }
        return "dealHeading";
      },
      cellClassName: () => {
        if (dId === dealComparisonData?.benchmark_deal?.id) {
          return "bgGray";
        }
        return "";
      },
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        let headerName = params?.colDef?.headerName;
        if (params?.colDef?.field == String(dealId)) {
          headerName = "Base Case";
        }
        return (
          <Tooltip title={headerName} placement="top-end">
            <div
              className={cn(
                "w-full flex items-center justify-between whitespace-normal break-words",
              )}
              onClick={() => onHeaderClick(params)}
            >
              <strong>{trimString(headerName, 45)}</strong>
              <OpenInNewIcon />
            </div>
          </Tooltip>
        );
      },
      renderCell: (params: any) => {
        if (params?.id == "macrs_election" && isString(params?.value)) {
          const macrsElections = params?.value?.split(", ");
          return (
            <div>
              {macrsElections.map((macrsElection: string, idx: number) => (
                <div key={idx}>{macrsElection}</div>
              ))}
            </div>
          );
        }
        return <div>{params?.value}</div>;
      },
    })),
  ];

  const hasDealStructureType = (
    type: keyof typeof DEAL_STRUCTURE_TYPE,
  ): boolean => {
    return dealAnalysisInput?.some((input) => {
      return input?.deal?.structure === type;
    });
  };

  const hasDealTaxCreditStructureType = (
    type: keyof typeof DEAL_TAX_CREDIT_STRUCTURE_TYPE,
  ): boolean => {
    return dealAnalysisInput?.some((input) => {
      return input?.deal?.tax_credit_structure === type;
    });
  };

  const hasDealDebt = () => {
    return dealAnalysisInput?.some((input) => {
      return !isEmpty(input?.debt);
    });
  };

  const rows = [
    ...getDealComparisonRows(dealIds, dealAnalysisInput, showDifferences),
    ...getDealGeneralComparisonRows(
      dealIds,
      dealAnalysisInput,
      showDifferences,
    ),
    ...getDealTimingComparisonRows(dealIds, dealAnalysisInput, showDifferences),
    ...getDealCostComparisonRows(dealIds, dealAnalysisInput, showDifferences),
    ...getDealCostsPerWattDcComparisonRows(
      dealIds,
      dealAnalysisInput,
      showDifferences,
    ),
    ...getDealCreditSupportComparisonRows(
      dealIds,
      dealAnalysisInput,
      showDifferences,
    ),
    ...getDealFeesComparisonRows(dealIds, dealAnalysisInput, showDifferences),
    ...getDealDepreciationComparisonRows(
      dealIds,
      dealAnalysisInput,
      showDifferences,
    ),
    ...getDealTaxCreditComparisonRows(
      dealIds,
      dealAnalysisInput,
      showDifferences,
    ),
    ...getDealProformaComparisonRows(
      dealIds,
      dealAnalysisInput,
      showDifferences,
    ),
    ...getDealCapitalSourcesComparisonRows(
      dealIds,
      dealAnalysisOutput,
      showDifferences,
    ),
    ...getDealPartnershipPorfolioComparisonRows(
      dealIds,
      dealAnalysisOutput,
      showDifferences,
    ),
    ...getDealSponsorEquityComparisonRows(
      dealIds,
      dealAnalysisOutput,
      showDifferences,
    ),
    ...(hasDealDebt()
      ? [
          ...getDealDebtComparisonRows(
            dealIds,
            dealAnalysisOutput,
            showDifferences,
          ),
        ]
      : []),
    ...(hasDealStructureType("TEP")
      ? [
          ...getDealTaxEquityComparisonRows(
            dealIds,
            dealAnalysisOutput,
            showDifferences,
          ),
        ]
      : []),
    ...(hasDealStructureType("CEP")
      ? [
          ...getDealCashEquityComparisonRows(
            dealIds,
            dealAnalysisOutput,
            showDifferences,
          ),
        ]
      : []),
    ...(hasDealTaxCreditStructureType("TR")
      ? [
          ...getDealTransferComparisonRows(
            dealIds,
            dealAnalysisInput,
            dealAnalysisOutput,
            showDifferences,
          ),
        ]
      : []),
    ...(hasDealTaxCreditStructureType("DP")
      ? [
          ...getDealDirectPayComparisonRows(
            dealIds,
            dealAnalysisInput,
            dealAnalysisOutput,
            showDifferences,
          ),
        ]
      : []),
  ];

  const onHeaderClick = (params: { field: string }) => {
    const columnId = dealComparisonData?.deals_to_compare?.find(
      (deal) => deal?.id == Number(params?.field),
    )?.id;

    if (type === "deal" && columnId) {
      window.open(`/deal/${columnId}/case-deal/${columnId}/output`);
    }
    if (type === "case" && columnId) {
      window.open(`/deal/${dealId}/case-deal/${columnId}/output`);
    }
  };

  const getRowClassName = (params: GridRowClassNameParams) => {
    return Object.keys(params?.row).length > 1 ? "" : "underlinedSection";
  };

  const dealCasesOptions: ISelectOption[] = dealCasesLite.map((d) => {
    return {
      label: d.name,
      value: String(d.child_deal_id),
    };
  });

  const gotoAnalysisList = () => {
    if (type === "case") {
      navigate(`/deal/${dealId}/analysis`);
    } else {
      navigate("/analysis/deal-comparison-list");
    }
  };

  const DetailLayout = type === "case" ? DealPagesLayout : Layout;

  const pinnedColumns = React.useMemo(() => {
    return {
      left: [
        "__tree_data_group__",
        "property",
        String(dealComparisonData?.benchmark_deal?.id),
      ],
    };
  }, [dealComparisonData]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (name === "show") {
      setShowDifferences(checked);
      setHighlightDifferences(false);
      return;
    }
    setHighlightDifferences(checked);
    setShowDifferences(false);
  };

  const checkIfDealIsLoaded = (index: number): boolean => {
    return Boolean(dealAnalysisInput[index] && dealAnalysisOutput[index]);
  };

  const getIndexOfDealById = (id: number): number => {
    const deal = dealComparisonData?.deals_to_compare.find((d) => d.id === id);
    const index = dealComparisonData?.deals_to_compare.indexOf(deal) ?? -1;
    return index;
  };

  const getDataGridCellClassName = (params: GridCellParams) => {
    const baseDealValue = params.row[dealComparisonData?.benchmark_deal.id];
    const indexOfCurrentColumn = getIndexOfDealById(Number(params.field));
    // adding "datagridCell-highlight" class to highlight if there is any difference between base deal and other deal
    if (
      highlightDifferences &&
      params.field !== "__tree_data_group__" && // checking if it is not a grouping column
      params.formattedValue !== baseDealValue && // checking if the values are not same
      checkIfDealIsLoaded(0) && // checking if base deal is loaded
      checkIfDealIsLoaded(indexOfCurrentColumn) // checking if the current cell's column is loaded
    ) {
      return "datagridCell-highlight";
    }
    return "";
  };

  const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) =>
    row.hierarchy;

  const renderCustomToolbar = (options?: { withName: boolean }) => {
    return (
      <GridToolbarContainer>
        <div className={cn("flex justify-between w-full align-middle")}>
          {options?.withName && (
            <p className={cn("text-2xl text-nowrap content-center ml-2")}>
              {trimString(dealComparisonData?.name, 45)}
            </p>
          )}
          <div className={cn("flex w-full justify-end items-center gap-2")}>
            <CheckboxInput
              label="Collapse All"
              checked={collapseAll}
              onChange={(e) => setCollapseAll(e.target.checked)}
              formControlLabelClasses={{ root: cn("!m-0") }}
            />
            <CheckboxInput
              label="Show Differences"
              name="show"
              checked={showDifferences}
              onChange={handleCheckboxChange}
              formControlLabelClasses={{ root: cn("!m-0") }}
            />
            <CheckboxInput
              label="Highlight Differences"
              name="highlight"
              checked={highlightDifferences}
              onChange={handleCheckboxChange}
              formControlLabelClasses={{ root: cn("!m-0") }}
            />
            <IconButton onClick={() => setFullScreen((f) => !f)}>
              {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
            <MuiButton
              classes={{
                root: styles.classes.downloadBtn,
                disabled: styles.classes.disabledDownloadBtn,
              }}
              startIcon={<DownloadIcon />}
              disabled={!reportsLoaded}
            >
              <GridCsvExportMenuItem />
            </MuiButton>
          </div>
        </div>
      </GridToolbarContainer>
    );
  };

  const disableGridVirtualization = React.useMemo(() => {
    return (
      process.env.REACT_APP_NODE_ENV === "dev" ||
      process.env.REACT_APP_NODE_ENV === "uat"
    );
  }, [process.env.REACT_APP_NODE_ENV]);

  const renderDataGrid = (options?: { withName: boolean }) => {
    return (
      <DataGridPro
        disableVirtualization={disableGridVirtualization}
        classes={{ columnHeaderTitle: cn("font-bold") }}
        density="compact"
        treeData
        rows={rows}
        columns={columns}
        rowBuffer={100} // Prevent flickering
        loading={
          dealAnalysisInput?.length === 0 && dealAnalysisOutput?.length === 0
        }
        getRowClassName={getRowClassName}
        getRowHeight={(params) => {
          // sets the height for the depreciation row because showing depreciation data in multi line
          if (params.model.id === "macrs_election") {
            const macrsLength = dealAnalysisInput.map(
              (d) => Object.keys(d?.depreciation?.macrs_election)?.length,
            );
            const maxLine = max(macrsLength) || 1;
            return maxLine === 1 ? 36 : maxLine * 25;
          }
          return undefined;
        }}
        pinnedRows={{
          top: rows?.slice(0, 3),
        }}
        initialState={{
          pinnedColumns: pinnedColumns,
          pagination: {
            paginationModel: {
              pageSize: 200,
            },
          },
        }}
        groupingColDef={{
          headerName: "Name",
          minWidth: 350,
          hideDescendantCount: true,
          valueGetter: (params: any) => {
            if (params?.rowNode?.type === "pinnedRow") {
              return params?.row?.property;
            }
            return params?.rowNode?.groupingKey;
          },
        }}
        getTreeDataPath={getTreeDataPath}
        isGroupExpandedByDefault={() => !collapseAll}
        slots={{ toolbar: () => renderCustomToolbar(options) }}
        hideFooter
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        getCellClassName={getDataGridCellClassName}
      />
    );
  };

  return (
    <>
      <DetailLayout title={dealComparisonData?.name}>
        <ViewWrapper
          loading={loadingDealComparison}
          error={getDealComparisonFailed}
        >
          <div className={type === "deal" ? styles.classes.container : ""}>
            {type === "deal" && (
              <div
                className={styles.classes.backButtonContainer}
                onClick={gotoAnalysisList}
              >
                <ArrowBackIcon />
                <Typography>Back to Comparison List</Typography>
              </div>
            )}
            <div
              className={cn("flex justify-between mb-4 items-center w-full")}
            >
              <p className={cn("text-2xl text-nowrap content-center")}>
                {trimString(dealComparisonData?.name, 45)}
              </p>
              <div>
                <Protect permission={USER_PERMISSIONS.DEALS_CRUD}>
                  <Button
                    label="Edit"
                    btnType="primary"
                    startIcon={<EditIcon />}
                    disabled={loadingDealComparison || getDealComparisonFailed}
                    onClick={handleEditDealComparison}
                  />
                  <Button
                    label="Delete"
                    btnType="danger"
                    style={{ marginLeft: "10px" }}
                    disabled={loadingDealComparison || getDealComparisonFailed}
                    onClick={() => handleOnDelete(Number(id))}
                  />
                </Protect>
              </div>
            </div>

            <div className={styles.classes.datagrid}>{renderDataGrid()}</div>
          </div>
        </ViewWrapper>
      </DetailLayout>

      <Modal
        fullScreen
        hideActionButtons
        noContentPadding
        open={fullScreen}
        onClose={() => setFullScreen(false)}
      >
        <div
          className={[styles.classes.datagrid, cn("!w-full !h-full")].join(" ")}
        >
          {renderDataGrid({ withName: true })}
        </div>
      </Modal>

      <UpdateDealComparisonFormModal
        headerLabel="Edit Deal Comparison"
        open={openEditFormModal && type === "deal"}
        loading={loadingUpdateDealComparison}
        form={form}
        setForm={setForm}
        onClose={() => setOpenEditFormModal(false)}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        existingDeals={dealOptions}
        onConfirm={handleUpdate}
      />

      <UpdateDealCaseComparisonFormModal
        headerLabel="Edit Deal Case Comparison"
        open={openEditFormModal && type === "case"}
        loading={loadingUpdateDealCaseComparison}
        form={updateDealCaseComparisonForm}
        formErrors={updateDealCaseFormErrors}
        setFormErrors={setUpdateDealCaseFormErrors}
        setForm={setUpdateDealCaseComparisonForm}
        onClose={() => setOpenEditFormModal(false)}
        onConfirm={handleUpdateDealCase}
        existingCases={dealCasesOptions}
        baseCase={{
          label: "Base Case",
          value: String(dealId),
        }}
      />
    </>
  );
}
