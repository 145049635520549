import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Typography from "@mui/material/Typography";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/system";
import { useParams } from "react-router-dom";

import useStyles from "./styles";
import ViewWrapper from "../../../../components/view-wrapper";
import { useAPI } from "../../../../utils/hooks";
import { IProjectValuation } from "../../../../interfaces";
import { cn, numberWithCommas } from "../../../../utils/helpers";

interface StickyTableCellProps {
  left?: number;
  border?: boolean;
}

interface IProps {
  getProjectValuation: (projectId: number) => Promise<IProjectValuation[]>;
}

// sticky table cell component
const StickyTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "left",
})<StickyTableCellProps>(({ theme, left = 0, border = false }) => ({
  position: "sticky",
  left: left,
  backgroundColor: theme.palette.background.paper,
  zIndex: 12,
  borderRight: border ? "1px solid #e0e0e0" : "none",
  minWidth: `${FIXED_COLUMN_WIDTH}px`,
  fontWeight: "bold",
}));

const FIXED_COLUMN_WIDTH = 200;

export default function ProjectValuationCalculationView({
  getProjectValuation,
}: IProps) {
  const styles = useStyles();
  const { projectId } = useParams();
  const [projectValuation, setProjectValuation] =
    React.useState<IProjectValuation>();

  const {
    callAPI: getProjectValuationCallAPI,
    loading: getProjectValuationLoading,
    errored: getProjectValuationFailed,
  } = useAPI((projectId) => getProjectValuation(Number(projectId)), {
    initialLoading: true,
  });

  React.useEffect(() => {
    getProjectValuationCallAPI(projectId).then((res) => {
      res && setProjectValuation(res[0]);
    });
  }, [projectId]);

  return (
    <ViewWrapper
      loading={getProjectValuationLoading}
      error={getProjectValuationFailed}
    >
      <React.Fragment>
        {!projectValuation && (
          <Box className={styles.classes.emptyContainer}>
            <Typography
              variant="body1"
              className={styles.classes.createInfo}
              data-pw="empty-project-valuation-calculation-message"
            >
              No Valuation Calculations found. Add Project Valuation to get
              started.
            </Typography>
          </Box>
        )}

        {projectValuation && (
          <TableContainer
            component={Paper}
            style={{ maxWidth: "100%", maxHeight: "66vh" }}
            data-pw="project-valuation-calculations-table"
          >
            <Table stickyHeader>
              {/* Add z-index of 20 to the table head so that it is over other cells of the table */}
              <TableHead classes={{ root: cn("relative z-20") }}>
                <TableRow>
                  <StickyTableCell>Name</StickyTableCell>
                  <StickyTableCell
                    left={FIXED_COLUMN_WIDTH}
                    border
                    align="right"
                  >
                    Total
                  </StickyTableCell>
                  {projectValuation?.valuation_calculations?.[0]?.values.map(
                    (col, index) => (
                      <TableCell
                        classes={{ root: cn("!font-semibold") }}
                        key={index}
                        align="right"
                      >
                        {col}
                      </TableCell>
                    ),
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {projectValuation?.valuation_calculations
                  .slice(1)
                  .map((row, rowIndex) => {
                    const isFormatted = ["DOLLAR"].includes(row.dtype);
                    return (
                      <TableRow key={rowIndex}>
                        <StickyTableCell component="th" scope="row">
                          {row.label}
                        </StickyTableCell>
                        <StickyTableCell
                          left={FIXED_COLUMN_WIDTH}
                          border
                          align="right"
                        >
                          {isFormatted
                            ? numberWithCommas.format(Number(row.total))
                            : row.total}
                        </StickyTableCell>
                        {row.values.map((value, index) => (
                          <TableCell key={index} align="right">
                            {isFormatted
                              ? numberWithCommas.format(Number(value))
                              : value}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </React.Fragment>
    </ViewWrapper>
  );
}
