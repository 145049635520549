import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import AddIcon from "@mui/icons-material/Add";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Protect } from "@clerk/clerk-react";
import { useNavigate, useParams } from "react-router-dom";

import useStyle from "./styles";
import Button from "../../../../../components/button";
import ViewWrapper from "../../../../../components/view-wrapper";
import PopoverMenu from "../../../../../components/popover-menu";
import ProjectExpensesFormModal from "../../../../../components/project-expenses-form-modal";
import StickyTableCell from "../../../../../components/sticky-table-cell";
import { useAPI } from "../../../../../utils/hooks";
import {
  numberToUSD,
  sortArrayOfObjects,
  trimString,
} from "../../../../../utils/helpers";
import { AppContext } from "../../../../../utils/context/app-context";
import {
  PROJECT_EXPENSE_UNIT_TYPE,
  PROJECT_EXPENSE_TYPE,
  PROJECT_EXPENSE_UNIT_PERCENTAGE_OPTIONS,
  USER_PERMISSIONS,
  PROJECT_EXPENSE_FORM_DEFAULT_STATE,
  ESCALATION_METHOD_TYPE,
} from "../../../../../constants";
import {
  ITableSort,
  ITableColumn,
  IProjectExpense,
  IProjectTiming,
  IProjectExpenseForm,
  ServerPaginatedResponse,
  IProjectExpenseFormErrors,
} from "../../../../../interfaces";

export const EXPENSE_TABLE_COLUMNS: ITableColumn[] = [
  { id: "type", label: "Type", minWidth: 80, align: "left" },
  { id: "name", label: "Name", minWidth: 80, align: "left" },
  {
    id: "expense_unit_type",
    label: "Unit Type",
    minWidth: 80,
    align: "left",
  },
  { id: "expense", label: "Input", minWidth: 80, align: "left" },
  { id: "rate", label: "Rate", minWidth: 80, align: "left" },
  { id: "escalator", label: "Esc.", minWidth: 80, align: "left" },
  {
    id: "escalation_method",
    label: "Esc. Method",
    minWidth: 80,
    align: "left",
  },
  {
    id: "base_year",
    label: "Esc. Base Year",
    minWidth: 80,
    align: "left",
  },
  {
    id: "first_year_total",
    label: "1st Year Total",
    minWidth: 80,
    align: "left",
  },
  { id: "start_date", label: "Start Date", minWidth: 80, align: "left" },
  { id: "term", label: "Term", minWidth: 80, align: "left" },
  { id: "end_date", label: "End Date", minWidth: 80, align: "left" },
  {
    id: "cash_basis_lag",
    label: "Cash Lag (Months)",
    minWidth: 80,
    align: "left",
  },
  { id: "action", label: "Action", minWidth: 80, align: "right" },
];

interface IProps {
  getProjectTiming: (projectId: number) => Promise<IProjectTiming[]>;
  getProjectExpenses: (
    projectId: number,
  ) => Promise<ServerPaginatedResponse<IProjectExpense[]>>;
  addProjectExpense: (
    projectId: number,
    form: IProjectExpenseForm,
  ) => Promise<IProjectExpense>;
  updateProjectExpense: (
    projectId: number,
    expenseId: number,
    form: IProjectExpenseForm,
  ) => Promise<IProjectExpense>;
  deleteExpense: (projectId: number, expenseId: number) => Promise<boolean>;
}

export default function ProjectExpenseTableView({
  getProjectTiming,
  getProjectExpenses,
  addProjectExpense,
  updateProjectExpense,
  deleteExpense,
}: IProps): JSX.Element {
  const styles = useStyle();
  const navigate = useNavigate();

  const { projectId } = useParams();

  const { ctrlPressed, setConfirmDeleteModalProps } =
    React.useContext(AppContext);

  const [projectTiming, setProjectTiming] = React.useState<IProjectTiming>();
  const [expenses, setExpenses] = React.useState<IProjectExpense[]>([]);
  const [addExpenseModalOpen, setAddExpenseModalOpen] =
    React.useState<boolean>(false);
  const [updateExpenseModalOpen, setUpdateExpenseModalOpen] =
    React.useState<boolean>(false);
  const [selectedExpenseToEdit, setSelectedExpenseToEdit] =
    React.useState<number>();
  const [form, setForm] = React.useState<IProjectExpenseForm>(
    PROJECT_EXPENSE_FORM_DEFAULT_STATE,
  );
  const [sortTable, setSortTable] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });

  React.useEffect(() => {
    getProjectExpensesCallAPI(Number(projectId)).then((response) => {
      response && setExpenses(response.results);
    });
    getProjectTimingCallAPI(Number(projectId)).then((response) => {
      response && setProjectTiming(response[0]);
    });
  }, [projectId]);

  const { callAPI: getProjectTimingCallAPI } = useAPI((projectId: number) =>
    getProjectTiming(projectId),
  );

  const {
    callAPI: getProjectExpensesCallAPI,
    errored: getExpensesFailed,
    loading: loadingExpenses,
  } = useAPI((projectId: number) => getProjectExpenses(projectId), {
    initialLoading: true,
  });

  const {
    callAPI: addProjectExpenseCallAPI,
    fieldErrors: addProjectExpenseFormErrors,
    setFieldErrors: setAddProjectExpenseFormErrors,
    loading: addProjectExpenseLoading,
  } = useAPI<IProjectExpense, IProjectExpenseFormErrors>(
    (projectId: number, form: IProjectExpenseForm) =>
      addProjectExpense(Number(projectId), form),
  );

  const {
    callAPI: updateProjectExpenseCallAPI,
    fieldErrors: updateProjectExpenseFormErrors,
    setFieldErrors: setUpdateProjectExpenseFormErrors,
    loading: updateProjectExpenseLoading,
  } = useAPI<IProjectExpense, IProjectExpenseFormErrors>(
    (projectId: number, expenseId: number, form: IProjectExpenseForm) =>
      updateProjectExpense(projectId, expenseId, form),
  );

  const { callAPI: deleteExpenseCallAPI } = useAPI(
    (projectId, expenseId) => deleteExpense(projectId, expenseId),
    { setConfirmModalLoading: true },
  );

  const handleOnAddProjectExpense = async (form: IProjectExpenseForm) => {
    const expense = await addProjectExpenseCallAPI(Number(projectId), form);
    if (expense) {
      getProjectExpensesCallAPI(Number(projectId)).then((response) => {
        response && setExpenses(response.results);
      });
      navigate(`/project/${projectId}/pro-forma/expenses/${expense?.id}`);
    }
    return expense;
  };

  const handleOnUpdateProjectExpense = async (form: IProjectExpenseForm) => {
    const expense = await updateProjectExpenseCallAPI(
      Number(projectId),
      Number(selectedExpenseToEdit),
      form,
    );

    getProjectExpensesCallAPI(Number(projectId)).then((response) => {
      response && setExpenses(response.results);
    });

    return expense;
  };

  const getDefaultBaseYear = (cod?: string) => {
    return !isNaN(Date.parse(cod || ""))
      ? new Date(cod || "").getFullYear()
      : "";
  };

  const handleOpenAddExpenseModal = () => {
    setForm((prevState) => ({
      ...prevState,
      base_year: getDefaultBaseYear(projectTiming?.cod),
      start_date: projectTiming?.cod || "",
      expense_input_type: "NUMBER",
      expense_unit_type: "DOLPERKWYR",
      cash_basis_lag: 0,
      term_input_method: "EP",
      start_input_method: "SP",
      term_start_point: "COD",
      rate: 100,
      term_end_point: "PEOL",
      expense_strip: new Array(projectTiming?.date_schedule.length || 0).fill(
        0,
      ),
    }));
    setAddExpenseModalOpen(true);
  };

  const handleCloseAddExpenseModal = () => {
    setAddExpenseModalOpen(false);
  };

  const handleOpenUpdateProjectExpenseModal = (id: number) => {
    const selectedExpense = expenses.find((e) => e.id === id);
    if (selectedExpense) {
      const {
        expense_input_type,
        expense_unit_type,
        cash_basis_lag,
        expense_strip,
        start_date,
        escalator,
        escalation_method,
        base_year,
        acres,
        expense,
        rate,
        term,
        type,
        name,
        term_input_method,
        start_input_method,
        term_start_point,
        term_end_point,
        monthly_allocation_type,
      } = selectedExpense;
      setSelectedExpenseToEdit(selectedExpense.id);
      const formData: IProjectExpenseForm = {
        base_year: base_year || getDefaultBaseYear(projectTiming?.cod),
        acres: acres || "",
        escalation_method: escalation_method || "",
        expense: expense || "",
        rate: rate || "",
        expense_strip: expense_strip?.length
          ? expense_strip
          : new Array(projectTiming?.date_schedule?.length || 0).fill(0),
        escalator,
        expense_input_type,
        start_input_method: start_input_method || "",
        term_start_point: term_start_point || "",
        expense_unit_type,
        cash_basis_lag: cash_basis_lag,
        start_date: start_date || "",
        term_end_point: term_end_point || "",
        term_input_method: term_input_method || "",
        term: term || "",
        name: name || "",
        monthly_allocation_type: monthly_allocation_type || "",
        type,
        dollars_per_yr_percentage: "",
      };
      setForm(formData);
      setUpdateExpenseModalOpen(true);
    }
  };

  const handleDeleteExpense = async (id: number) => {
    const expense = await deleteExpenseCallAPI(Number(projectId), id);

    if (expense) {
      setExpenses((prev) => prev.filter((e) => e.id !== id));
    }
  };

  const handleOnDeleteClick = async (id: number) => {
    setConfirmDeleteModalProps({
      open: true,
      title: "Delete Expense",
      description: "Are you sure you want to delete this expense?",
      onConfirm: () => handleDeleteExpense(id),
    });
  };

  const handleCloseUpdateProjectExpenseModal = () => {
    setUpdateExpenseModalOpen(false);
  };

  const goToExpense = (id: number) => {
    if (ctrlPressed) {
      window.open(`/project/${projectId}/pro-forma/expenses/${id}`, "_blank");
      return;
    }
    navigate(`/project/${projectId}/pro-forma/expenses/${id}`);
  };

  const sortRows = (orderBy: string) => {
    if (orderBy === sortTable.orderBy) {
      setSortTable({
        orderBy,
        order: sortTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const createTableData = (expenses: IProjectExpense[]) => {
    return expenses.map((expense) => {
      const isStrip = expense.expense_input_type === "STRIP";
      const isEscalatorFieldsVisible =
        expense.expense_unit_type &&
        [
          "DOLPERKWYR",
          "DOLPERYR",
          "DOLPERMWYR",
          "DOLPERACRE",
          "PERCENTCOST",
          "PERCENTBLDCOST",
        ].includes(expense.expense_unit_type);
      return {
        id: expense.id,
        type: PROJECT_EXPENSE_TYPE[expense.type],
        name: expense.name,
        cash_basis_lag:
          expense.cash_basis_lag !== undefined &&
          expense.cash_basis_lag !== null
            ? expense.cash_basis_lag
            : "N/A",
        expense: isStrip
          ? "Schedule"
          : PROJECT_EXPENSE_UNIT_PERCENTAGE_OPTIONS.includes(
                expense.expense_unit_type ?? "",
              )
            ? `${expense?.expense || ""}%`
            : String(numberToUSD.format(expense?.expense || 0)),
        expense_unit_type: isStrip
          ? "N/A"
          : PROJECT_EXPENSE_UNIT_TYPE[
              expense?.expense_unit_type as keyof typeof PROJECT_EXPENSE_UNIT_TYPE
            ] || "",
        rate: isStrip
          ? "N/A"
          : expense?.rate !== undefined && expense?.rate !== null
            ? `${expense.rate}%`
            : "",
        escalator: isStrip
          ? "N/A"
          : expense?.escalator !== undefined &&
              expense?.escalator !== null &&
              isEscalatorFieldsVisible
            ? `${expense.escalator}%`
            : "N/A",
        base_year: isStrip ? "N/A" : String(expense?.base_year ?? ""),
        first_year_total: String(
          numberToUSD.format(expense.first_year_total ?? 0),
        ),
        start_date: isStrip ? "N/A" : expense.start_date ?? "",
        term: isStrip ? "N/A" : String(expense.term ?? ""),
        end_date: isStrip ? "N/A" : expense.end_date ?? "",
        escalation_method: isEscalatorFieldsVisible
          ? ESCALATION_METHOD_TYPE[expense.escalation_method]
          : "N/A",
      };
    });
  };

  const expenseTableData = React.useMemo(
    () => createTableData(expenses),
    [expenses],
  );

  const sortedRows = React.useMemo(
    () =>
      sortArrayOfObjects(
        expenseTableData,
        sortTable?.orderBy,
        sortTable?.order,
        {
          isDollars: ["expense", "first_year_total"],
        },
      ),
    [sortTable, expenseTableData],
  );

  return (
    <>
      <ViewWrapper loading={loadingExpenses} error={getExpensesFailed}>
        <Box className={styles.classes.addExpenseBtnContainer}>
          <Protect permission={USER_PERMISSIONS.PROJECTS_CRUD}>
            <Button
              canOpenUpgrade
              startIcon={<AddIcon />}
              btnType="primary"
              label="Add Expense"
              onClick={handleOpenAddExpenseModal}
            />
          </Protect>
        </Box>

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer classes={{ root: styles.classes.tableContainer }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className={styles.classes.header}>
                <TableRow>
                  {EXPENSE_TABLE_COLUMNS.map((column, idx) => {
                    if (column.id === "action") {
                      return (
                        <StickyTableCell
                          key={idx}
                          direction="right"
                          fixedColumnWidth={80}
                          align="center"
                          highZIndex
                        >
                          {column.label}
                        </StickyTableCell>
                      );
                    }
                    return (
                      <TableCell
                        key={idx}
                        align={column.align as "left"}
                        style={{ minWidth: column.minWidth }}
                      >
                        <TableSortLabel
                          active={sortTable.orderBy === column.id}
                          direction={
                            sortTable.orderBy === column.id
                              ? sortTable.order
                              : "asc"
                          }
                          onClick={() => sortRows(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {expenses.length === 0 ? (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={EXPENSE_TABLE_COLUMNS.length}
                    >
                      There are no expenses in place currently, please add one.
                    </TableCell>
                  </TableRow>
                ) : null}
                {sortedRows.map((expense, idx) => {
                  return (
                    <TableRow
                      hover
                      key={idx}
                      tabIndex={-1}
                      className={styles.classes.dataRow}
                      onClick={() => goToExpense(expense.id)}
                    >
                      <TableCell align="left">{expense?.type}</TableCell>
                      <TableCell align="left">
                        {trimString(expense?.name || "", 25)}
                      </TableCell>
                      <TableCell align="left">
                        {expense?.expense_unit_type}
                      </TableCell>
                      <TableCell align="left">{expense?.expense}</TableCell>
                      <TableCell align="left">{expense?.rate}</TableCell>
                      <TableCell align="left">{expense?.escalator}</TableCell>
                      <TableCell align="left">
                        {expense?.escalation_method}
                      </TableCell>
                      <TableCell align="left">{expense?.base_year}</TableCell>
                      <TableCell align="left">
                        {expense?.first_year_total}
                      </TableCell>
                      <TableCell align="left">{expense.start_date}</TableCell>
                      <TableCell align="left">{expense.term}</TableCell>
                      <TableCell align="left">{expense.end_date}</TableCell>
                      <TableCell align="left">
                        {expense?.cash_basis_lag}
                      </TableCell>
                      <StickyTableCell
                        direction="right"
                        fixedColumnWidth={80}
                        align="center"
                      >
                        <Protect permission={USER_PERMISSIONS.PROJECTS_CRUD}>
                          <PopoverMenu
                            uniqueId={idx}
                            canOpenUpgrade
                            items={[
                              {
                                label: "Edit",
                                onClick: () =>
                                  handleOpenUpdateProjectExpenseModal(
                                    expense.id,
                                  ),
                              },
                              {
                                label: "Delete",
                                onClick: () => handleOnDeleteClick(expense.id),
                              },
                            ]}
                          />
                        </Protect>
                      </StickyTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </ViewWrapper>

      <ProjectExpensesFormModal
        open={addExpenseModalOpen}
        dateSchedule={projectTiming?.date_schedule || []}
        headerLabel="Add Expense"
        loading={addProjectExpenseLoading}
        formErrors={addProjectExpenseFormErrors}
        setFormErrors={setAddProjectExpenseFormErrors}
        form={form}
        setForm={setForm}
        onClose={handleCloseAddExpenseModal}
        onConfirm={handleOnAddProjectExpense}
      />

      <ProjectExpensesFormModal
        open={updateExpenseModalOpen}
        dateSchedule={projectTiming?.date_schedule || []}
        loading={updateProjectExpenseLoading}
        headerLabel="Update Expense"
        formErrors={updateProjectExpenseFormErrors}
        setFormErrors={setUpdateProjectExpenseFormErrors}
        form={form}
        setForm={setForm}
        onClose={handleCloseUpdateProjectExpenseModal}
        onConfirm={handleOnUpdateProjectExpense}
      />
    </>
  );
}
