import { Skeleton } from "@mui/material";
import { isEmpty, isNumber } from "lodash";

import {
  IComparisonItem,
  IDealComparisonInputs,
  IDealComparisonOutputs,
} from "../../interfaces";
import {
  CARRYFORWARD_CREDITS,
  DEAL_COMMON_COMPARISON_ITEMS,
  DEAL_GENERAL_COMPARISON_ITEMS,
  DEAL_TIMING_COMPARISON_ITEMS,
  DEAL_COST_COMPARISON_ITEMS,
  PROJECT_COST_TYPE,
  DEAL_COST_PER_WDC_COMPARISON_ITEMS,
  DEAL_CREDIT_SUPPORT_COMPARISON_ITEMS,
  DEAL_FEE_COMPARISON_ITEMS,
  DEAL_DEPRECIATION_FEE_COMPARISON_ITEMS,
  DEAL_TAX_CREDIT_COMPARISON_ITEMS,
  DEAL_PROFORMA_COMPARISON_ITEMS,
  DEAL_PARTNERSHIP_PORTFOLIO_COMPARISON_ITEMS,
  DEAL_SPONSOR_EQUITY_COMPARISON_ITEMS,
  DEAL_DEBT_COMPARISON_ITEMS,
  DEAL_TAX_EQUITY_COMPARISON_ITEMS,
  DEAL_CASH_EQUITY_COMPARISON_ITEMS,
  DEAL_TRANSFER_COMPARISON_ITEMS,
  DEAL_DIRECT_PAY_COMPARISON_ITEMS,
  DATE_FORMAT_REGEX,
} from "../../constants";
import { formatCurrency, numberWithDecimalsAndCommas } from "./common.helpers";
import { differenceInMonths } from "date-fns";

const Error = "Error";
const Loading = (
  <Skeleton width={150} height={50} sx={{ bgcolor: "#5335fa45" }} />
);

const getDataState = (data: any) => {
  if (data === null) {
    return Error; // data is null only when api has failed.
  }
  return Loading;
};

const invertStringIntSign = (val: string) => {
  // if value is 0, return val as it is without any sign
  if (Number(val) === 0) {
    return val;
  }
  return val.includes("-") ? val.replace("-", "+") : "-" + val;
};

interface IGetDifferenceArgs extends Omit<IComparisonItem, "id" | "hierarchy"> {
  baseCaseItemValue?: string | number | null;
  itemValue?: string | number | null;
}

const getDifference = (args: IGetDifferenceArgs): string | number => {
  const {
    baseCaseItemValue,
    itemValue,
    suffix,
    decimalDigits,
    isDollar,
    enum: enumObj,
  } = args;

  if (baseCaseItemValue === itemValue) {
    return "";
  }

  if (
    (baseCaseItemValue === undefined || baseCaseItemValue === null) &&
    (itemValue === undefined || itemValue === null)
  ) {
    return "";
  }

  if (enumObj && itemValue) {
    return enumObj[itemValue];
  }

  if (typeof itemValue === "number") {
    const parsedBaseCaseItemValure = Number(baseCaseItemValue) || 0;
    if (isDollar) {
      const result = formatCurrency(
        parsedBaseCaseItemValure - itemValue,
        decimalDigits,
      );
      return invertStringIntSign(result) + (suffix || "");
    }
    if (typeof decimalDigits === "number") {
      const result = numberWithDecimalsAndCommas(
        parsedBaseCaseItemValure - itemValue,
        decimalDigits,
      );
      return invertStringIntSign(result) + (suffix || "");
    }
  }

  if (
    DATE_FORMAT_REGEX.test(String(baseCaseItemValue)) &&
    DATE_FORMAT_REGEX.test(String(itemValue))
  ) {
    const result = differenceInMonths(
      new Date(String(baseCaseItemValue)),
      new Date(String(itemValue)),
    );

    return invertStringIntSign(String(result)) + "Months";
  }

  if (itemValue !== undefined && itemValue !== null) {
    return itemValue + (suffix || "");
  }

  return itemValue ?? "N/A";
};

export const getDealComparisonRows = (
  dealIds: number[] = [],
  input: IDealComparisonInputs[],
  showDiff: boolean,
) => {
  return DEAL_COMMON_COMPARISON_ITEMS.map((item) => {
    const { hierarchy, id, property, enum: enumObj } = item;

    return {
      id,
      hierarchy,
      property,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealId: number, i: number) => {
          const itemValue = input[i]?.deal[id];

          let cellData: string | number = "N/A";
          if (enumObj && itemValue) {
            cellData = enumObj[itemValue];
          }

          if (showDiff && i !== 0 && input[0]) {
            const baseCaseItemValue = input[0]?.deal[id];

            cellData = getDifference({ baseCaseItemValue, itemValue, ...item });
          }

          acc[dealId] = input[i] ? cellData : getDataState(input[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealGeneralComparisonRows = (
  dealIds: number[] = [],
  input: IDealComparisonInputs[],
  showDiff: boolean,
) => {
  return DEAL_GENERAL_COMPARISON_ITEMS.map((item) => {
    const { id, hierarchy, suffix } = item;

    return {
      id,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealId: number, i: number) => {
          const itemValue = input[i]?.deal[id];

          let cellData = itemValue ?? "N/A";

          if (itemValue !== null && itemValue !== undefined) {
            cellData = itemValue + (suffix || "");
          }

          if (showDiff && i !== 0 && input[0]) {
            const baseCaseItemValue = input[0]?.deal[id];

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
              decimalDigits: 2,
            });
          }

          acc[dealId] = input[i] ? cellData : getDataState(input[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealTimingComparisonRows = (
  dealIds: number[] = [],
  input: IDealComparisonInputs[],
  showDiff: boolean,
) => {
  return DEAL_TIMING_COMPARISON_ITEMS.map((item) => {
    const { id, hierarchy, suffix } = item;

    return {
      id,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealId: number, i: number) => {
          const itemValue = input[i]?.timing[id];

          let cellData = itemValue ?? "N/A";

          if (itemValue !== null && itemValue !== undefined) {
            cellData = itemValue + (suffix || "");
          }

          if (showDiff && i !== 0 && input[0]) {
            const baseCaseItemValue = input[0]?.timing[id];

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
              decimalDigits: 2,
            });
          }

          acc[dealId] = input[i] ? cellData : getDataState(input[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealCostComparisonRows = (
  dealIds: number[] = [],
  input: IDealComparisonInputs[],
  showDiff: boolean,
) => {
  return DEAL_COST_COMPARISON_ITEMS.map((item, idx) => {
    const { id, hierarchy, nestedObjLevel, isDollar, decimalDigits, suffix } =
      item;

    return {
      id: id + idx,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealId: number, i: number) => {
          const costObj = input[i]?.cost;

          let cellData: string | number = "N/A";

          if (costObj) {
            if (nestedObjLevel) {
              const itemValue =
                costObj["costs"][id as keyof typeof PROJECT_COST_TYPE];

              cellData = isNumber(itemValue)
                ? formatCurrency(Number(itemValue))
                : "N/A";

              if (showDiff && i !== 0 && input[0]) {
                const baseCaseItemValue =
                  input[0]?.cost["costs"][id as keyof typeof PROJECT_COST_TYPE];

                cellData = getDifference({
                  baseCaseItemValue,
                  itemValue,
                  ...item,
                });
              }
            } else {
              const typeBindedId = id as keyof Pick<
                IDealComparisonInputs["cost"],
                | "fmv"
                | "fmv_step_up"
                | "profit_percent"
                | "total_cost_calculated"
              >;
              const baseCaseItemValue = input[0]?.cost[typeBindedId];
              const itemValue = costObj[typeBindedId];

              if (isDollar) {
                cellData = isNumber(itemValue)
                  ? `${formatCurrency(itemValue)}${suffix || ""}`
                  : "N/A";
              }

              if (decimalDigits) {
                cellData = isNumber(itemValue)
                  ? `${numberWithDecimalsAndCommas(
                      itemValue,
                      decimalDigits ?? 0,
                    )}${suffix || ""}`
                  : "N/A";
              }

              if (showDiff && i !== 0) {
                cellData = getDifference({
                  baseCaseItemValue,
                  itemValue,
                  ...item,
                });
              }
            }
          }

          acc[dealId] = input[i] ? cellData : getDataState(input[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealCostsPerWattDcComparisonRows = (
  dealIds: number[] = [],
  input: IDealComparisonInputs[],
  showDiff: boolean,
) => {
  return DEAL_COST_PER_WDC_COMPARISON_ITEMS.map((item) => {
    const { id, hierarchy, nestedObjLevel, isDollar, decimalDigits, suffix } =
      item;

    return {
      id,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealId: number, i: number) => {
          const costObj = input[i]?.cost_per_watt_dc;

          let cellData: string | number = "N/A";

          if (costObj) {
            if (nestedObjLevel) {
              const itemValue =
                costObj["costs"][id as keyof typeof PROJECT_COST_TYPE];

              cellData = isNumber(itemValue)
                ? formatCurrency(itemValue, decimalDigits) + (suffix || "")
                : "N/A";

              if (showDiff && i !== 0 && input[0]) {
                const baseCaseItemValue =
                  input[0]?.cost_per_watt_dc["costs"][
                    id as keyof typeof PROJECT_COST_TYPE
                  ];

                cellData = getDifference({
                  baseCaseItemValue,
                  itemValue,
                  ...item,
                });
              }
            } else {
              const typeBindedId = id as keyof Pick<
                IDealComparisonInputs["cost_per_watt_dc"],
                "fmv" | "fmv_step_up" | "total_cost_calculated"
              >;

              const baseCaseItemValue =
                input[0]?.cost_per_watt_dc[typeBindedId];
              const itemValue = costObj[typeBindedId];

              if (isDollar) {
                cellData = isNumber(itemValue)
                  ? `${formatCurrency(itemValue, decimalDigits)}${suffix || ""}`
                  : "N/A";
              }

              if (showDiff && i !== 0) {
                cellData = getDifference({
                  baseCaseItemValue,
                  itemValue,
                  ...item,
                });
              }
            }
          }

          acc[dealId] = input[i] ? cellData : getDataState(input[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealCreditSupportComparisonRows = (
  dealIds: number[] = [],
  input: IDealComparisonInputs[],
  showDiff: boolean,
) => {
  return DEAL_CREDIT_SUPPORT_COMPARISON_ITEMS.map((item) => {
    const { id, hierarchy, isDollar } = item;

    return {
      id,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealId: number, i: number) => {
          const itemValue = input[i]?.credit_support_costs[id].total;

          let cellData: number | string = itemValue ?? "N/A";

          if (isDollar && isNumber(itemValue)) {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && input[0]) {
            const baseCaseItemValue = input[0]?.credit_support_costs[id].total;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
            });
          }

          acc[dealId] = input[i] ? cellData : getDataState(input[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealFeesComparisonRows = (
  dealIds: number[] = [],
  input: IDealComparisonInputs[],
  showDiff: boolean,
) => {
  return DEAL_FEE_COMPARISON_ITEMS.map((item) => {
    const { id, hierarchy, isDollar } = item;

    return {
      id,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealId: number, i: number) => {
          const itemValue = input[i]?.deal_fees[id];

          let cellData: number | string = itemValue ?? "N/A";

          if (isDollar && isNumber(itemValue)) {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && input[0]) {
            const baseCaseItemValue = input[0]?.deal_fees[id];

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
            });
          }

          acc[dealId] = input[i] ? cellData : getDataState(input[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealDepreciationComparisonRows = (
  dealIds: number[] = [],
  input: IDealComparisonInputs[],
  showDiff: boolean,
) => {
  return DEAL_DEPRECIATION_FEE_COMPARISON_ITEMS.map((item) => {
    const { id, hierarchy, enum: enumObj } = item;
    return {
      id,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealId: number, i: number) => {
          const macrsElection = input[i]?.depreciation?.macrs_election;

          let cellData: string | number = "N/A";

          if (input[i]) {
            if (macrsElection) {
              cellData = Object.entries(macrsElection)
                .map(
                  ([year, election]) =>
                    `${year} - ${(enumObj && enumObj[election]) ?? "N/A"}`,
                )
                .join(", ");
            } else {
              cellData = "N/A";
            }

            if (showDiff && i !== 0 && input[0]) {
              const baseCaseItemValue = Object.entries(
                input[0].depreciation?.macrs_election || {},
              )
                .map(
                  ([year, election]) =>
                    `${year} - ${(enumObj && enumObj[election]) ?? "N/A"}`,
                )
                .join(", ");

              const itemValue = Object.entries(macrsElection || {})
                .map(
                  ([year, election]) =>
                    `${year} - ${(enumObj && enumObj[election]) ?? "N/A"}`,
                )
                .join(", ");

              cellData = getDifference({ baseCaseItemValue, itemValue });
            }
          }

          acc[dealId] = input[i] ? cellData : getDataState(input[i]);

          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealTaxCreditComparisonRows = (
  dealIds: number[] = [],
  input: IDealComparisonInputs[],
  showDiff: boolean,
) => {
  return DEAL_TAX_CREDIT_COMPARISON_ITEMS.map((item) => {
    const { id, hierarchy, isDollar } = item;

    return {
      id,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealId: number, i: number) => {
          const itemValue = input[i]?.tax_credit[id];

          let cellData: number | string = itemValue ?? "N/A";

          if (isDollar && isNumber(itemValue)) {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && input[0]) {
            const baseCaseItemValue = input[0]?.tax_credit[id];
            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
            });
          }

          acc[dealId] = input[i] ? cellData : getDataState(input[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealProformaComparisonRows = (
  dealIds: number[] = [],
  input: IDealComparisonInputs[],
  showDiff: boolean,
) => {
  return DEAL_PROFORMA_COMPARISON_ITEMS.map((item) => {
    const { id, hierarchy, isDollar } = item;

    return {
      id,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealId: number, i: number) => {
          const itemValue = input[i]?.proforma[id];

          let cellData: number | string = itemValue ?? "N/A";

          if (isDollar && isNumber(itemValue)) {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && input[0]) {
            const baseCaseItemValue = input[0]?.proforma[id];

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
            });
          }

          acc[dealId] = input[i] ? cellData : getDataState(input[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealCapitalSourcesComparisonRows = (
  dealIds: number[] = [],
  output: IDealComparisonOutputs[],
  showDiff: boolean,
) => {
  return [
    {
      id: "tax_equity",
      hierarchy: ["Capital Sources", "Tax Equity"],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealId: number, i: number) => {
          const itemValue = output[i]?.tax_equity?.capital_contributions;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue =
              output[0]?.tax_equity?.capital_contributions;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }

          acc[dealId] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
    {
      id: "cash_equity",
      hierarchy: ["Capital Sources", "Cash Equity"],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealId: number, i: number) => {
          const itemValue = output[i]?.cash_equity?.capital_contributions;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue =
              output[0]?.cash_equity?.capital_contributions;
            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }

          acc[dealId] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
    {
      id: "sponsor_equity",
      hierarchy: ["Capital Sources", "Sponsor Equity"],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealId: number, i: number) => {
          const itemValue = output[i]?.sponsor_equity?.negated_equity;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.sponsor_equity?.negated_equity;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }

          acc[dealId] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
    {
      id: "debt_proceeds",
      hierarchy: ["Capital Sources", "Debt Proceeds"],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealId: number, i: number) => {
          const itemValue = output[i]?.debt?.debt_amount;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.debt?.debt_amount;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }

          acc[dealId] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
  ];
};

export const getDealPartnershipPorfolioComparisonRows = (
  dealIds: number[] = [],
  output: IDealComparisonOutputs[],
  showDiff: boolean,
) => {
  return DEAL_PARTNERSHIP_PORTFOLIO_COMPARISON_ITEMS.map((item) => {
    const { id, hierarchy, isDollar, suffix, decimalDigits } = item;

    return {
      id,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealId: number, i: number) => {
          const itemValue = output[i]?.partnership[id];

          let cellData: number | string = itemValue ?? "N/A";

          if (isDollar && isNumber(itemValue)) {
            cellData = formatCurrency(itemValue) + (suffix || "");
          }

          if (decimalDigits) {
            cellData = isNumber(itemValue)
              ? `${numberWithDecimalsAndCommas(
                  Number(itemValue),
                  decimalDigits ?? 0,
                )}${suffix || ""}`
              : "N/A";
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.partnership[id];

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
            });
          }

          acc[dealId] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealSponsorEquityComparisonRows = (
  dealIds: number[] = [],
  output: IDealComparisonOutputs[],
  showDiff: boolean,
) => {
  const rows: Record<string, any>[] = [];

  let showNewFederalTaxRate = false;
  let showTaxRateChangeYear = false;

  DEAL_SPONSOR_EQUITY_COMPARISON_ITEMS.forEach((item, idx) => {
    const row: Record<string, any> = {};
    const { id, hierarchy, isDollar, decimalDigits, suffix, nullValue } = item;

    row.id = id + idx;
    row.hierarchy = hierarchy;

    dealIds.forEach((dealId, i) => {
      if (output[i]) {
        const itemValue = output[i]["sponsor_equity"][id];

        let cellData = itemValue ?? nullValue ?? "N/A";

        if (typeof itemValue === "number") {
          if (isDollar) {
            cellData = formatCurrency(itemValue) + (suffix || "");
          } else {
            cellData =
              numberWithDecimalsAndCommas(itemValue, decimalDigits ?? 0) +
              (suffix || "");
          }
        }

        if (showDiff && i !== 0 && output[0]) {
          const baseCaseItemValue = output[0]["sponsor_equity"][id];
          cellData = getDifference({
            baseCaseItemValue,
            itemValue,
            ...item,
          });
        }

        row[dealId] = cellData;
      } else {
        row[dealId] = getDataState(output[i]);
      }
    });
    rows.push(row);

    // Insert newFederalTaxRate and taxRateChangeYear rows after federal_tax_rate
    if (id === "federal_tax_rate") {
      const newFederalTaxRate = {
        id: "new_federal_tax_rate",
        hierarchy: ["Sponsor Equity", "New Federal Tax Rate"],
        property: "New Federal Tax Rate",
        ...dealIds.reduce(
          (acc: Record<string, any>, dealId: number, i: number) => {
            const itemValue = output[i]?.sponsor_equity?.new_federal_tax_rate;

            let cellData = itemValue ?? "N/A";

            if (typeof itemValue === "number") {
              cellData = itemValue + "%";
            }

            if (itemValue) {
              showNewFederalTaxRate = true;
            }

            if (showDiff && i !== 0 && output[0]) {
              const baseCaseItemValue =
                output[0]?.sponsor_equity?.new_federal_tax_rate;

              cellData = getDifference({
                baseCaseItemValue,
                itemValue,
                suffix: "%",
              });
            }

            acc[dealId] = output[i] ? cellData : getDataState(output[i]);
            return acc;
          },
          {},
        ),
      };

      const taxRateChangeYear = {
        id: "federal_tax_rate_change_year",
        hierarchy: ["Sponsor Equity", "Tax Rate Change Year"],
        property: "Tax Rate Change Year",
        ...dealIds.reduce(
          (acc: Record<string, any>, dealId: number, i: number) => {
            const itemValue =
              output[i]?.sponsor_equity?.federal_tax_rate_change_year;
            let cellData = itemValue ?? "N/A";

            if (itemValue) {
              showTaxRateChangeYear = true;
            }

            if (showDiff && i !== 0 && output[0]) {
              const baseCaseItemValue =
                output[0]?.sponsor_equity?.federal_tax_rate_change_year;

              cellData = getDifference({ baseCaseItemValue, itemValue });
            }

            acc[dealId] = output[i] ? cellData : getDataState(output[i]);
            return acc;
          },
          {},
        ),
      };

      if (showNewFederalTaxRate) {
        rows.push(newFederalTaxRate);
      }
      if (showTaxRateChangeYear) {
        rows.push(taxRateChangeYear);
      }
      rows.push({
        id: "carryforward_credits_and_losses",
        hierarchy: ["Sponsor Equity", "Carryforward Credits and Losses"],
        ...dealIds.reduce(
          (acc: Record<string, any>, dealId: number, i: number) => {
            const itemValue =
              output[i]?.sponsor_equity?.carryforward_credits_and_losses;

            let cellData: string | number = itemValue
              ? CARRYFORWARD_CREDITS[
                  itemValue as keyof typeof CARRYFORWARD_CREDITS
                ]
              : "N/A";

            if (showDiff && i !== 0 && output[0]) {
              const baseCaseItemValue =
                output[0]?.sponsor_equity?.carryforward_credits_and_losses;

              cellData = getDifference({ baseCaseItemValue, itemValue });
            }

            acc[dealId] = output[i] ? cellData : getDataState(output[i]);
            return acc;
          },
          {},
        ),
      });
    }
  });

  return rows;
};

export const getDealDebtComparisonRows = (
  dealIds: number[] = [],
  output: IDealComparisonOutputs[],
  showDiff: boolean,
) => {
  return DEAL_DEBT_COMPARISON_ITEMS.map((item) => {
    const { id, hierarchy, isDollar, suffix, decimalDigits } = item;

    return {
      id,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealId: number, i: number) => {
          const itemValue = output[i]?.debt[id];

          let cellData: number | string = itemValue ?? "N/A";

          if (isDollar && isNumber(itemValue)) {
            cellData = formatCurrency(itemValue) + (suffix || "");
          }

          if (decimalDigits) {
            cellData = isNumber(itemValue)
              ? `${numberWithDecimalsAndCommas(
                  Number(itemValue),
                  decimalDigits ?? 0,
                )}${suffix || ""}`
              : "N/A";
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.debt[id];

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
            });
          }

          acc[dealId] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealTaxEquityComparisonRows = (
  dealIds: number[] = [],
  output: IDealComparisonOutputs[],
  showDiff: boolean,
) => {
  return DEAL_TAX_EQUITY_COMPARISON_ITEMS.map((item, idx) => {
    const {
      id,
      hierarchy,
      isDollar,
      suffix,
      enum: enumObj,
      decimalDigits,
      nullValue,
    } = item;

    return {
      id: id + "_TE_" + idx,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealId: number, i: number) => {
          const itemValue = output[i]?.tax_equity[id];

          let cellData: number | string = itemValue ?? nullValue ?? "N/A";

          if (enumObj && itemValue) {
            cellData = enumObj[itemValue];
          }

          if (isDollar && isNumber(itemValue)) {
            cellData = formatCurrency(itemValue) + (suffix || "");
          }

          if (decimalDigits) {
            cellData = isNumber(itemValue)
              ? `${numberWithDecimalsAndCommas(
                  Number(itemValue),
                  decimalDigits ?? 0,
                )}${suffix || ""}`
              : "N/A";
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.tax_equity[id];

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
            });
          }

          acc[dealId] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealCashEquityComparisonRows = (
  dealIds: number[] = [],
  output: IDealComparisonOutputs[],
  showDiff: boolean,
) => {
  return DEAL_CASH_EQUITY_COMPARISON_ITEMS.map((item, idx) => {
    const {
      id,
      hierarchy,
      isDollar,
      suffix,
      enum: enumObj,
      decimalDigits,
      nullValue,
    } = item;

    return {
      id: id + "_CE_" + idx,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealId: number, i: number) => {
          const itemValue = output[i]?.cash_equity[id];

          let cellData: number | string = itemValue ?? nullValue ?? "N/A";

          if (enumObj && itemValue) {
            cellData = enumObj[itemValue];
          }

          if (isDollar && isNumber(itemValue)) {
            cellData = formatCurrency(itemValue) + (suffix || "");
          }

          if (decimalDigits) {
            cellData = isNumber(itemValue)
              ? `${numberWithDecimalsAndCommas(
                  Number(itemValue),
                  decimalDigits ?? 0,
                )}${suffix || ""}`
              : "N/A";
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.cash_equity[id];

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
            });
          }

          acc[dealId] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealTransferComparisonRows = (
  dealIds: number[] = [],
  input: IDealComparisonInputs[],
  output: IDealComparisonOutputs[],
  showDiff: boolean,
) => {
  return DEAL_TRANSFER_COMPARISON_ITEMS.map((item, idx) => {
    const { id, hierarchy, isDollar, suffix, decimalDigits } = item;

    return {
      id: id + "_T_" + idx,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealId: number, i: number) => {
          const itemValue = output[i]?.transfer[id];

          let cellData: number | string = itemValue ?? "N/A";

          if (isDollar && isNumber(itemValue)) {
            cellData = formatCurrency(itemValue) + (suffix || "");
          }

          if (decimalDigits) {
            cellData = isNumber(itemValue)
              ? `${numberWithDecimalsAndCommas(
                  Number(itemValue),
                  decimalDigits ?? 0,
                )}${suffix || ""}`
              : "N/A";
          }

          if (
            isEmpty(output[i]?.transfer) ||
            !(input[i]?.deal?.tax_credit_structure === "TR")
          ) {
            cellData = "N/A";
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.transfer[id];

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
            });
          }

          acc[dealId] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealDirectPayComparisonRows = (
  dealIds: number[] = [],
  input: IDealComparisonInputs[],
  output: IDealComparisonOutputs[],
  showDiff: boolean,
) => {
  return DEAL_DIRECT_PAY_COMPARISON_ITEMS.map((item) => {
    const { id, hierarchy, isDollar, suffix, decimalDigits } = item;

    return {
      id,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealId: number, i: number) => {
          const itemValue = output[i]?.transfer[id];

          let cellData: number | string = itemValue ?? "N/A";

          if (isDollar && isNumber(itemValue)) {
            cellData = formatCurrency(itemValue) + (suffix || "");
          }

          if (decimalDigits) {
            cellData = isNumber(itemValue)
              ? `${numberWithDecimalsAndCommas(
                  Number(itemValue),
                  decimalDigits ?? 0,
                )}${suffix || ""}`
              : "N/A";
          }

          if (
            isEmpty(output[i]?.transfer) ||
            !(input[i]?.deal?.tax_credit_structure === "DP")
          ) {
            cellData = "N/A";
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.transfer[id];

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
            });
          }

          acc[dealId] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    };
  });
};
