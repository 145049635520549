import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";

import ViewWrapper from "../../../../../components/view-wrapper";
import ReportTable from "../../../../../components/report-table";
import ToggleSizingOutputButton from "../../../../../components/toggle-sizing-output-button";
import { useAPI } from "../../../../../utils/hooks";
import { useAxios } from "../../../../../components/axios-provider";
import { AppContext } from "../../../../../utils/context/app-context";
import {
  IConstructionDebtSummary,
  IDealTiming,
  ITableColumn,
  ITableRow,
} from "../../../../../interfaces";
import {
  CONSTRUCTION_DEBT_LOANS_SUMMARY_TABLE_COLUMNS,
  ITEMS_AND_TOTAL_TABLE_COLUMN,
} from "../../../../../constants";
import {
  cn,
  generateConstructionDebtGeneralSummary,
  generateConstructionDebtLoanSummary,
  generateDealTimingTableRows,
  sortArrayOfObjects,
  getConstructionDebtReportSummaryUrl,
  generateConstructionDebtAdditionalTableRows,
} from "../../../../../utils/helpers";

interface IProps {
  getDealTiming: (dealId: number) => Promise<IDealTiming[]>;
  getConstructionDebtSummary: (
    dealId: number,
  ) => Promise<IConstructionDebtSummary>;
}

const ADDITIONAL_TABLE_COLUMNS: ITableColumn[] = [
  { id: "sources", label: "Sources", minWidth: 100, align: "left" },
  { id: "amount", label: "Amount", minWidth: 100, align: "left" },
  {
    id: "amount_per_watt_dc",
    label: "Amount ($/Wdc)",
    minWidth: 100,
    align: "left",
  },
  {
    id: "percentage_of_total_cost",
    label: "% of Project Costs",
    minWidth: 100,
    align: "left",
  },
];

export default function DealOutputConstructionDebtSummaryView({
  getDealTiming,
  getConstructionDebtSummary,
}: IProps): JSX.Element {
  const { caseDealId } = useParams();

  const { urlLoadingPercentages } = useAxios();

  const { setDealOutputLoaded } = React.useContext(AppContext);

  const {
    callAPI: getDealSummaryCallAPI,
    errored: getSummaryFailed,
    errorObj: getSummaryErrorObj,
    response: summary,
    loading: loadingSummary,
    showUsageLimitHitScreen,
  } = useAPI((dealId: number) => getConstructionDebtSummary(dealId), {
    initialLoading: true,
  });

  const {
    callAPI: getDealTimingCallAPI,
    errored: getTimingFailed,
    loading: loadingTiming,
    response: dealTiming,
  } = useAPI((dealId: number) => getDealTiming(dealId), {
    initialLoading: true,
  });

  React.useEffect(() => {
    getDealSummaryCallAPI(Number(caseDealId));
    getDealTimingCallAPI(Number(caseDealId));
  }, []);

  React.useEffect(() => {
    !loadingSummary && setDealOutputLoaded(!getSummaryFailed);
  }, [getSummaryFailed, loadingSummary]);

  const generalSummary = React.useMemo(() => {
    if (summary) {
      return generateConstructionDebtGeneralSummary(summary);
    }
    return [];
  }, [summary]);

  const constructionLoans = React.useMemo(() => {
    if (summary) {
      return generateConstructionDebtLoanSummary(summary);
    }
    return [];
  }, [summary]);

  const sortedDealProjectTimings = React.useMemo(() => {
    if (!dealTiming || !dealTiming[0]) return [];
    return sortArrayOfObjects(
      dealTiming[0].project_timings,
      "project_name",
      "asc",
    );
  }, [dealTiming]);

  const constructionSummaryColumns: ITableColumn[] = React.useMemo(() => {
    return new Array((sortedDealProjectTimings.length || 0) + 1)
      .fill(0)
      .map((_, idx) => {
        const columnLabel = idx
          ? sortedDealProjectTimings[idx - 1].project_name
          : "Item";

        return {
          id: columnLabel,
          label: columnLabel,
          minWidth: 200,
          align: "left",
        };
      });
  }, [sortedDealProjectTimings]);

  const constructionSummaryRows: ITableRow[] = React.useMemo(() => {
    if (dealTiming && dealTiming[0]) {
      const rows = generateDealTimingTableRows(sortedDealProjectTimings, [
        "ntp_date",
        "mechanical_completion_date",
        "substantial_completion_date",
        "cod",
      ]);

      return rows.map((row) => ({
        cells: row.map((c) => ({ value: c.value })),
      }));
    }
    return [];
  }, [dealTiming]);

  const additionalTableRows: ITableRow[] = React.useMemo(
    () =>
      summary
        ? generateConstructionDebtAdditionalTableRows(summary.data.sources)
        : [],
    [summary],
  );

  return (
    <>
      <Box className={cn("flex float-end my-2")}>
        <ToggleSizingOutputButton />
      </Box>

      <ViewWrapper
        loading={loadingSummary}
        error={getSummaryFailed}
        errorHeading={getSummaryErrorObj?.heading}
        errorDescription={getSummaryErrorObj?.description}
        showLimitHitView={showUsageLimitHitScreen}
        loadingPercentage={
          urlLoadingPercentages[
            getConstructionDebtReportSummaryUrl(Number(caseDealId))
          ]
        }
      >
        <Box className={cn("mt-8")}>
          <Box>
            <Typography margin={1} fontSize={18} fontWeight={600}>
              General
            </Typography>
            <Box className="grid grid-cols-2 gap-4">
              <ReportTable
                columns={ITEMS_AND_TOTAL_TABLE_COLUMN}
                rows={generalSummary}
              />
              <ReportTable
                columns={ADDITIONAL_TABLE_COLUMNS}
                rows={additionalTableRows}
              />
            </Box>
          </Box>

          <ViewWrapper loading={loadingTiming} error={getTimingFailed}>
            <Box className={cn("mt-6")}>
              <Typography margin={1} fontSize={18} fontWeight={600}>
                Construction Summary
              </Typography>
              <Box>
                <ReportTable
                  columns={constructionSummaryColumns}
                  rows={constructionSummaryRows}
                />
              </Box>
            </Box>
          </ViewWrapper>

          <Box className={cn("mt-6")}>
            <Typography margin={1} fontSize={18} fontWeight={600}>
              Construction Loans
            </Typography>
            <Box>
              <ReportTable
                columns={CONSTRUCTION_DEBT_LOANS_SUMMARY_TABLE_COLUMNS}
                rows={constructionLoans}
              />
            </Box>
          </Box>
        </Box>
      </ViewWrapper>
    </>
  );
}
