import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import format from "date-fns/format";
import { Protect } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

import useStyles from "./styles";
import Button from "../../../components/button";
import ViewWrapper from "../../../components/view-wrapper";
import AddDealFormModal from "../../../components/add-deal-form-modal";
import ProjectFormModal from "../../../components/project-form-modal";
import AddDealComparisonFormModal from "../../../components/add-deal-comparison-form-modal";
import { useAPI } from "../../../utils/hooks";
import { AppContext } from "../../../utils/context/app-context";
import {
  addProjectCountFieldToDealsList,
  sortArrayOfObjects,
  trimString,
} from "../../../utils/helpers";
import {
  IAddDealComparisonForm,
  IAddDealComparisonFormErrors,
  IAddDealForm,
  IAddDealFormErrors,
  IAddDealResponse,
  IProjectForm,
  IProjectFormErrors,
  IAddUpdateProjectResponse,
  IDeal,
  IDealComparison,
  IGetDealsParams,
  IGetProjectsParams,
  IProject,
  ISelectOption,
  ITableSort,
  ServerPaginatedResponse,
} from "../../../interfaces";
import {
  ADD_DEAL_COMPARISON_FORM_DEFAULT_STATE,
  ADD_DEAL_FORM_DEFAULT_VAULES,
  DEAL_FORM_DEFAULT_STATE,
  DEAL_STAGES,
  DEAL_STRUCTURE_TYPE,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE,
  DEAL_TAX_CREDIT_TYPE,
  PROJECT_FORM_DEFAULT_STATE,
  PROJECT_STAGES,
  PROJECT_TYPES,
  USER_PERMISSIONS,
} from "../../../constants";

const DEAL_COLUMNS = [
  { id: "name", label: "Deal Name", minWidth: 150, align: "left" },
  { id: "stage", label: "Stage", minWidth: 80, align: "left" },
  { id: "structure", label: "Structure", minWidth: 80, align: "left" },
  {
    id: "tax_credit_structure",
    label: "Tax Credit Structure",
    minWidth: 80,
    align: "left",
  },
  {
    id: "tax_credit_type",
    label: "Tax Credit Type",
    minWidth: 80,
    align: "left",
  },
  { id: "type_string", label: "Project Type(s)", minWidth: 120, align: "left" },
  {
    id: "project_count",
    label: "Number of Projects",
    minWidth: 35,
    align: "left",
  },
  { id: "capacity_ac", label: "Capacity (AC)", minWidth: 50, align: "left" },
  { id: "modified", label: "Last Modified", minWidth: 120, align: "left" },
];

const PROJECT_COLUMNS = [
  { id: "name", label: "Project Name", minWidth: 80, align: "left" },
  { id: "sponsor_name", label: "Sponsor", minWidth: 80, align: "left" },
  { id: "type", label: "Type", minWidth: 100, align: "left" },
  { id: "capacity_ac", label: "Capacity (AC)", minWidth: 40, align: "left" },
  { id: "stage", label: "Stage", minWidth: 100, align: "left" },
  { id: "cod", label: "COD", minWidth: 120, align: "left" },
  { id: "modified", label: "Last Modified", minWidth: 120, align: "left" },
];

const DEAL_COMPARISON_COLUMNS = [
  { id: "name", label: "Deal Comparison Name", minWidth: 170, align: "left" },
  {
    id: "benchmark_deal.name",
    label: "Benchmark Deal Name",
    minWidth: 80,
    align: "left",
  },
  {
    id: "deal_count",
    label: "Number of Deals",
    minWidth: 50,
    align: "left",
  },
  { id: "modified", label: "Last Modified", minWidth: 120, align: "left" },
];

interface IProps {
  getDeals: (
    params: IGetDealsParams,
  ) => Promise<ServerPaginatedResponse<IDeal[]> | null>;
  getProjects: (
    params: IGetProjectsParams,
  ) => Promise<ServerPaginatedResponse<IProject[]> | null>;
  addDeal: (form: IAddDealForm) => Promise<IAddDealResponse>;
  addProject: (form: IProjectForm) => Promise<IAddUpdateProjectResponse>;
  getDealComparisons: (params: {
    limit?: number;
  }) => Promise<ServerPaginatedResponse<IDealComparison[]>>;
  addDealComparison: (form: IAddDealComparisonForm) => Promise<IDealComparison>;
}

export default function RecentView({
  getDeals,
  getProjects,
  addDeal,
  addProject,
  getDealComparisons,
  addDealComparison,
}: IProps): JSX.Element {
  const styles = useStyles();

  const navigate = useNavigate();

  const { ctrlPressed } = React.useContext(AppContext);

  const [deals, setDeals] = React.useState<IDeal[]>([]);
  const [sortDealsTable, setSortDealsTable] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });
  const [addDealModalOpen, setAddDealModalOpen] =
    React.useState<boolean>(false);
  const [addDealFormData, setAddDealFormData] = React.useState<IAddDealForm>(
    DEAL_FORM_DEFAULT_STATE,
  );

  const [projectOptions, setProjectOptions] = React.useState<ISelectOption[]>(
    [],
  );
  const [projects, setProjects] = React.useState<IProject[]>([]);
  const [sortProjectsTable, setSortProjectsTable] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });
  const [addProjectModalOpen, setAddProjectModalOpen] =
    React.useState<boolean>(false);
  const [projectForm, setProjectForm] = React.useState<IProjectForm>(
    PROJECT_FORM_DEFAULT_STATE,
  );

  const [dealOptions, setDealOptions] = React.useState<ISelectOption[]>([]);
  const [addDealComparisonModalOpen, setAddDealComparisonModalOpen] =
    React.useState<boolean>(false);
  const [addDealComparisonForm, setAddDealComparisonForm] =
    React.useState<IAddDealComparisonForm>(
      ADD_DEAL_COMPARISON_FORM_DEFAULT_STATE,
    );
  const [dealComparisons, setDealComparisons] = React.useState<
    IDealComparison[]
  >([]);
  const [sortDealComparisonTable, setSortDealComparisonTable] =
    React.useState<ITableSort>({
      orderBy: "",
      order: "asc",
    });

  const {
    callAPI: getDealsCallAPI,
    errored: getDealsFailed,
    loading: loadingDeals,
  } = useAPI((limit: number) => getDeals({ limit }), { initialLoading: true });

  const {
    callAPI: getProjectsCallAPI,
    errored: getProjectsFailed,
    loading: loadingProjects,
  } = useAPI((limit: number) => getProjects({ limit }), {
    initialLoading: true,
  });

  const {
    callAPI: addDealCallAPI,
    fieldErrors: addDealFormErrors,
    setFieldErrors: setAddDealFormErrors,
    loading: addDealLoading,
  } = useAPI<IAddDealResponse, IAddDealFormErrors>((form: IAddDealForm) =>
    addDeal(form),
  );

  const {
    callAPI: addProjectCallAPI,
    fieldErrors: addProjectFormErrors,
    setFieldErrors: setAddProjectFormErrors,
    loading: addProjectLoading,
  } = useAPI<IAddUpdateProjectResponse, IProjectFormErrors>(
    (form: IProjectForm) => addProject(form),
  );

  const {
    callAPI: getDealComparisonsAPI,
    errored: getDealComparisonsFailed,
    loading: loadingGetDealComparisons,
  } = useAPI((limit: number) => getDealComparisons({ limit }), {
    initialLoading: true,
  });

  const {
    callAPI: addDealComparisonCallAPI,
    fieldErrors: addDealComparisonFormErrors,
    setFieldErrors: setAddDealComparisonFormErrors,
    loading: addDealComparisonLoading,
  } = useAPI<IDealComparison, IAddDealComparisonFormErrors>(
    (form: IAddDealComparisonForm) => {
      const updatedForm = {
        name: form.name,
        deal_ids: [form.benchmark_deal, ...form.deal_ids],
      };
      return addDealComparison(updatedForm as IAddDealComparisonForm);
    },
  );

  React.useEffect(() => {
    getDealsCallAPI(5).then((response) => {
      response && setDeals(addProjectCountFieldToDealsList(response.results));
    });
    getProjectsCallAPI(5).then((response) => {
      if (response) {
        setProjects(response.results);
        setProjectOptions(
          response.results.map((p) => ({ label: p.name, value: String(p.id) })),
        );
      }
    });
    getDealComparisonsAPI(5).then((res) => {
      res && setDealComparisons(res?.results);
    });
  }, []);

  const visibleDealsRows = React.useMemo(
    () =>
      sortArrayOfObjects(
        deals,
        sortDealsTable.orderBy,
        sortDealsTable.order,
      ).slice(0, 7),
    [deals, sortDealsTable],
  );

  const visibleProjectsRows = React.useMemo(
    () =>
      sortArrayOfObjects(
        projects,
        sortProjectsTable?.orderBy,
        sortProjectsTable?.order,
      ).slice(0, 5),
    [projects, sortProjectsTable],
  );

  const visibleDealComparisonRows = React.useMemo(
    () =>
      sortArrayOfObjects(
        dealComparisons,
        sortDealComparisonTable?.orderBy,
        sortDealComparisonTable?.order,
      ).slice(0, 5),
    [dealComparisons, sortDealComparisonTable],
  );

  const sortDealsRows = (orderBy: string) => {
    if (orderBy === sortDealsTable.orderBy) {
      setSortDealsTable({
        orderBy,
        order: sortDealsTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortDealsTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const sortProjectsRows = (orderBy: string) => {
    if (orderBy === sortProjectsTable.orderBy) {
      setSortProjectsTable({
        orderBy,
        order: sortProjectsTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortProjectsTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const sortDealComparisonRows = (orderBy: string) => {
    if (orderBy === sortDealComparisonTable.orderBy) {
      setSortDealComparisonTable({
        orderBy,
        order: sortDealComparisonTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortDealComparisonTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const renderSeeAllRow = (
    rowsCount: number,
    route: "/deal-list" | "/project-list" | "/analysis/deal-comparison-list",
    colSpan: number,
  ) => {
    return rowsCount === 5 ? (
      <TableRow>
        <TableCell align="right" colSpan={colSpan}>
          <Typography
            onClick={() => navigate(route)}
            className={styles.classes.link}
          >
            See all
          </Typography>
        </TableCell>
      </TableRow>
    ) : null;
  };

  const goToDeal = (dealId: number) => {
    if (ctrlPressed) {
      window.open(`/deal/${dealId}/case-deal/${dealId}/general`, "_blank");
      return;
    }
    navigate(`/deal/${dealId}/case-deal/${dealId}/general`);
  };

  const goToProject = (projectId: number) => {
    if (ctrlPressed) {
      window.open(`/project/${projectId}/general/`, "_blank");
      return;
    }
    navigate(`/project/${projectId}/general/`);
  };

  const goToDealComparison = (id: number) => {
    if (ctrlPressed) {
      window.open(`/analysis/deal-comparison/${id}`);
      return;
    }
    navigate(`/analysis/deal-comparison/${id}`);
  };

  const handleOpenAddDealModal = async () => {
    setAddDealFormData(ADD_DEAL_FORM_DEFAULT_VAULES);
    setAddDealModalOpen(true);
  };

  const handleCloseAddDealModal = () => {
    setAddDealModalOpen(false);
  };

  const handleCloseAddProjectModal = () => {
    setAddProjectModalOpen(false);
  };

  const handleCloseAddDealComparisonModal = () => {
    setAddDealComparisonModalOpen(false);
  };

  const handleOpenAddProjectModal = () => {
    setAddProjectModalOpen(true);
  };

  const handleOpenAddDealComparisonModal = () => {
    setAddDealComparisonModalOpen(true);
    handleGetDealsForComparisonModal();
  };

  const handleAddDeal = async (form: IAddDealForm) => {
    const deal = await addDealCallAPI(form);
    deal && goToDeal(deal.id);
    return deal;
  };

  const handleAddProject = async (form: IProjectForm) => {
    const project = await addProjectCallAPI(form);
    if (project) {
      if (addDealModalOpen) {
        setAddDealFormData((prev) => ({
          ...prev,
          project_ids: [...prev.project_ids, String(project.id)],
        }));
        setProjectOptions((prev) => [
          ...prev,
          { label: project.name, value: String(project.id) },
        ]);
        return project;
      }
      goToProject(project.id);
    }
    return project;
  };

  const handleGetDealsForComparisonModal = async () => {
    const deals = await getDeals({}).catch();
    if (deals) {
      setDealOptions(
        deals.results.map((d: IDeal) => ({
          label: d.name,
          value: String(d.id),
        })),
      );
    }
  };

  const handleAddDealComparison = async (form: IAddDealComparisonForm) => {
    const deal = await addDealComparisonCallAPI(form);
    deal && goToDealComparison(deal?.id);
    return deal;
  };

  return (
    <>
      <ViewWrapper
        loading={loadingProjects || loadingDeals || loadingGetDealComparisons}
        error={false}
      >
        <ViewWrapper loading={false} error={getProjectsFailed}>
          <Box className={styles.classes.tableTitleContainer}>
            <Typography variant="h6" alignSelf="edn">
              Recent Projects
            </Typography>
            <Protect permission={USER_PERMISSIONS.PROJECTS_CRUD}>
              <Button
                canOpenUpgrade
                startIcon={<AddIcon />}
                btnType="primary"
                label="Add Project"
                onClick={handleOpenAddProjectModal}
              />
            </Protect>
          </Box>

          <Paper className={styles.classes.tablePaper}>
            <TableContainer>
              <Table
                stickyHeader
                aria-label="sticky table"
                data-pw="recent-project-table"
              >
                <TableHead className={styles.classes.header}>
                  <TableRow>
                    {PROJECT_COLUMNS.map((column, idx) => {
                      return (
                        <TableCell
                          key={idx}
                          align={column.align as "left"}
                          style={{ minWidth: column.minWidth }}
                        >
                          <TableSortLabel
                            active={sortProjectsTable.orderBy === column.id}
                            direction={
                              sortProjectsTable.orderBy === column.id
                                ? sortProjectsTable.order
                                : "asc"
                            }
                            onClick={() => sortProjectsRows(column.id)}
                          >
                            {column.label}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visibleProjectsRows.length === 0 ? (
                    <TableRow>
                      <TableCell align="center" colSpan={6}>
                        There are no Projects in place currently, please add
                        one.
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {visibleProjectsRows.map((project, idx) => {
                    return (
                      <TableRow
                        hover
                        key={idx}
                        tabIndex={-1}
                        className={styles.classes.dataRow}
                        onClick={() => goToProject(project.id)}
                        data-pw={`recent-project-table-row-${idx + 1}`}
                      >
                        <TableCell align="left" title={project.name}>
                          {trimString(project.name, 40)}
                        </TableCell>
                        <TableCell align="left">
                          {project.sponsor_name}
                        </TableCell>
                        <TableCell align="left">
                          {PROJECT_TYPES[project.type]}
                        </TableCell>
                        <TableCell align="left">
                          {project.capacity_ac} MW
                        </TableCell>
                        <TableCell align="left">
                          {project?.stage +
                            ": " +
                            PROJECT_STAGES[
                              project?.sub_stage as keyof typeof PROJECT_STAGES
                            ]}
                        </TableCell>
                        <TableCell align="left">
                          {format(new Date(project.cod), "M/d/yyyy")}
                        </TableCell>
                        <TableCell align="left">
                          {format(new Date(project.modified), "M/d/yyyy")}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {renderSeeAllRow(
                    visibleProjectsRows.length,
                    "/project-list",
                    7,
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </ViewWrapper>

        <ViewWrapper loading={false} error={getDealsFailed}>
          <Box className={styles.classes.tableTitleContainer}>
            <Typography variant="h6" alignSelf="end">
              Recent Deals
            </Typography>
            <Protect permission={USER_PERMISSIONS.DEALS_CRUD}>
              <Button
                canOpenUpgrade
                btnType="primary"
                label="Add Deal"
                startIcon={<AddIcon />}
                onClick={handleOpenAddDealModal}
              />
            </Protect>
          </Box>

          <Paper className={styles.classes.tablePaper}>
            <TableContainer>
              <Table
                stickyHeader
                aria-label="sticky table"
                data-pw="recent-deal-table"
              >
                <TableHead className={styles.classes.header}>
                  <TableRow>
                    {DEAL_COLUMNS.map((column, idx) => {
                      return (
                        <TableCell
                          key={idx}
                          align={column.align as "left"}
                          style={{ minWidth: column.minWidth }}
                        >
                          <TableSortLabel
                            active={sortDealsTable.orderBy === column.id}
                            direction={
                              sortDealsTable.orderBy === column.id
                                ? sortDealsTable.order
                                : "asc"
                            }
                            onClick={() => sortDealsRows(column.id)}
                          >
                            {column.label}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visibleDealsRows.length === 0 ? (
                    <TableRow>
                      <TableCell align="center" colSpan={6}>
                        There are no Deals in place currently, please add one.
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {visibleDealsRows.map((deal, idx) => {
                    return (
                      <TableRow
                        hover
                        key={idx}
                        tabIndex={-1}
                        className={styles.classes.dataRow}
                        onClick={() => goToDeal(deal.id)}
                        data-pw={`recent-deal-table-row-${idx + 1}`}
                      >
                        <TableCell align="left" title={deal.name}>
                          {trimString(deal.name, 40)}
                        </TableCell>
                        <TableCell align="left">
                          {DEAL_STAGES[deal?.stage as keyof typeof DEAL_STAGES]}
                        </TableCell>
                        <TableCell align="left">
                          {
                            DEAL_STRUCTURE_TYPE[
                              deal?.structure as keyof typeof DEAL_STRUCTURE_TYPE
                            ]
                          }
                        </TableCell>
                        <TableCell align="left">
                          {
                            DEAL_TAX_CREDIT_STRUCTURE_TYPE[
                              deal?.tax_credit_structure as keyof typeof DEAL_TAX_CREDIT_STRUCTURE_TYPE
                            ]
                          }
                        </TableCell>
                        <TableCell align="left">
                          {
                            DEAL_TAX_CREDIT_TYPE[
                              deal?.tax_credit_type as keyof typeof DEAL_TAX_CREDIT_TYPE
                            ]
                          }
                        </TableCell>
                        <TableCell align="left">{deal.type_string}</TableCell>
                        <TableCell align="left">
                          {deal?.project_count || 0}
                        </TableCell>
                        <TableCell align="left">
                          {deal.capacity_ac} MW
                        </TableCell>
                        <TableCell align="left">
                          {format(new Date(deal.modified), "M/d/yyyy")}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {renderSeeAllRow(visibleDealsRows.length, "/deal-list", 9)}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </ViewWrapper>

        <ViewWrapper loading={false} error={getDealComparisonsFailed}>
          <Box className={styles.classes.tableTitleContainer}>
            <Typography variant="h6" alignSelf="edn">
              Recent Deal Comparisons
            </Typography>
            <Protect permission={USER_PERMISSIONS.DEALS_CRUD}>
              <Button
                canOpenUpgrade
                startIcon={<AddIcon />}
                btnType="primary"
                label="Add Deal Comparison"
                onClick={handleOpenAddDealComparisonModal}
              />
            </Protect>
          </Box>

          <Paper className={styles.classes.tablePaper}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead className={styles.classes.header}>
                  <TableRow>
                    {DEAL_COMPARISON_COLUMNS.map((column, idx) => {
                      return (
                        <TableCell
                          key={idx}
                          align={column.align as "left" | "right"}
                          style={{ minWidth: column.minWidth }}
                        >
                          <TableSortLabel
                            active={
                              sortDealComparisonTable.orderBy === column.id
                            }
                            direction={
                              sortDealComparisonTable.orderBy === column.id
                                ? sortDealComparisonTable.order
                                : "asc"
                            }
                            onClick={() => sortDealComparisonRows(column.id)}
                          >
                            {column.label}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visibleDealComparisonRows?.length === 0 && (
                    <TableRow>
                      <TableCell align="center" colSpan={6}>
                        No deal comparison found.
                      </TableCell>
                    </TableRow>
                  )}
                  {visibleDealComparisonRows.map((dealComparison, idx) => {
                    return (
                      <TableRow
                        key={idx}
                        hover
                        tabIndex={-1}
                        className={styles.classes.dataRow}
                        onClick={() => goToDealComparison(dealComparison?.id)}
                      >
                        <TableCell align="left" title={dealComparison.name}>
                          {trimString(dealComparison?.name, 40)}
                        </TableCell>
                        <TableCell align="left" title="">
                          {dealComparison?.benchmark_deal !== null
                            ? trimString(
                                dealComparison?.benchmark_deal.name,
                                80,
                              )
                            : ""}
                        </TableCell>
                        <TableCell align="left">
                          {dealComparison.deal_count}
                        </TableCell>
                        <TableCell align="left">
                          {format(
                            new Date(dealComparison?.modified),
                            "M/d/yyyy",
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {renderSeeAllRow(
                    visibleDealComparisonRows.length,
                    "/analysis/deal-comparison-list",
                    4,
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </ViewWrapper>
      </ViewWrapper>

      <AddDealFormModal
        open={addDealModalOpen}
        formErrors={addDealFormErrors}
        loading={addDealLoading}
        setFormErrors={setAddDealFormErrors}
        form={addDealFormData}
        setForm={setAddDealFormData}
        onClose={handleCloseAddDealModal}
        existingProjects={projectOptions}
        onAdd={handleAddDeal}
        openAddProjectModal={handleOpenAddProjectModal}
      />

      <ProjectFormModal
        open={addProjectModalOpen}
        headerLabel="Add a new Project"
        form={projectForm}
        loading={addProjectLoading}
        setForm={setProjectForm}
        formErrors={addProjectFormErrors}
        setFormErrors={setAddProjectFormErrors}
        onClose={handleCloseAddProjectModal}
        onConfirm={handleAddProject}
      />

      <AddDealComparisonFormModal
        headerLabel="Add a new Deal Comparison"
        open={addDealComparisonModalOpen}
        loading={addDealComparisonLoading}
        form={addDealComparisonForm}
        formErrors={addDealComparisonFormErrors}
        setFormErrors={setAddDealComparisonFormErrors}
        setForm={setAddDealComparisonForm}
        onClose={handleCloseAddDealComparisonModal}
        onConfirm={handleAddDealComparison}
        existingDeals={dealOptions}
      />
    </>
  );
}
