import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { cn } from "../../utils/helpers";
import { AppContext } from "../../utils/context/app-context";
import { SIDEBAR_NAVIGATION } from "../../constants";

export default function ToggleSizingOutputButton() {
  const { dealId, caseDealId } = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  const { currentDeal } = React.useContext(AppContext);

  const getSizingTypeFromUrl = (path: string): string => {
    const segments = path.split("/");
    return segments[6];
  };

  const dealSizingPages = SIDEBAR_NAVIGATION.find(
    (tab) => tab.label === "Deals",
  )?.subLinks?.find((sublink) => sublink.label === "Sizing")?.subLinks;

  const dealOutputPages = SIDEBAR_NAVIGATION.find(
    (tab) => tab.label === "Deals",
  )?.subLinks?.find((sublink) => sublink.label === "Output")?.subLinks;

  const getRedirectPath = (currentPath: string): string | null => {
    const isSizingPath = currentPath.includes("/sizing/");
    let sizingType = getSizingTypeFromUrl(currentPath);

    const outputTypeMap: Record<string, string> = {
      "cash-equity": "tax-equity",
      "direct-pay": "transfer",
    };

    if (isSizingPath) {
      sizingType = outputTypeMap[sizingType] || sizingType;
      const outputPath = dealOutputPages?.find(
        (page) => page.value === sizingType,
      )?.path;

      if (outputPath) {
        return outputPath
          .replace("/sizing/", "/output/")
          .replace(":dealId", dealId!)
          .replace(":caseDealId", caseDealId!);
      }
    } else {
      if (currentDeal?.structure === "CEP" && sizingType === "tax-equity") {
        sizingType = "cash-equity";
      }

      if (
        currentDeal?.tax_credit_structure === "DP" &&
        sizingType === "transfer"
      ) {
        sizingType = "direct-pay";
      }

      if (sizingType === "sponsor-equity-bol") {
        sizingType = "sponsor-equity";
      }

      const sizingPath = dealSizingPages?.find(
        (page) => page.value === sizingType,
      )?.path;

      if (sizingPath) {
        return sizingPath
          .replace("/output/", "/sizing/")
          .replace(":dealId", dealId!)
          .replace(":caseDealId", caseDealId!);
      }
    }
    return null;
  };

  const handleClick = () => {
    const redirectPath = getRedirectPath(location.pathname);
    if (redirectPath) navigate(redirectPath);
  };

  return (
    <Box
      className={cn("!text-secondary !border-secondary !cursor-pointer")}
      onClick={handleClick}
    >
      <Chip
        variant="outlined"
        label={`View ${
          location.pathname.includes("/sizing/") ? "Output" : "Sizing"
        }`}
        icon={<ArrowForwardIcon fontSize="small" />}
      />
    </Box>
  );
}
