import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { groupBy } from "lodash";
import { differenceInDays, format, startOfDay } from "date-fns";

import Log from "../log";
import useStyles from "./styles";
import LoaderScreen from "../loader-screen";
import { ILog } from "../../interfaces";
import { OBJECT_TYPE } from "../../constants";

interface IProps {
  logs: ILog[];
  totalLogs: number;
  type: OBJECT_TYPE;
  loading: boolean;
  nextPage: (type: OBJECT_TYPE, id: number | string) => void;
  id: number | string;
}

export default function Logs({
  logs,
  totalLogs,
  type,
  loading,
  nextPage,
  id,
}: IProps) {
  const styles = useStyles();

  const [expanded, setExpanded] = React.useState<{ [key: string]: boolean }>(
    {},
  );

  // grouping logs based on days
  const groupedLogs = React.useMemo(() => {
    return groupBy(logs, (log) => {
      const logDate = new Date(log.timestamp);
      const todayStart = startOfDay(new Date());
      const diffDays = differenceInDays(todayStart, logDate);

      if (diffDays === 0) {
        return "Today";
      } else if (diffDays === 1) {
        return "Yesterday";
      } else {
        return format(logDate, "MMM d");
      }
    });
  }, [logs]);

  React.useEffect(() => {
    const initialState: { [key: string]: boolean } = {};
    Object.keys(groupedLogs).forEach((group) => {
      initialState[group] = true; // setting all days expanded
    });
    setExpanded(initialState);
  }, [groupedLogs]);

  const toggleExpand = (group: string) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [group]: !prevExpanded[group],
    }));
  };

  return (
    <>
      {/* if no logs then show message */}
      {!loading && groupedLogs && Object.keys(groupedLogs).length === 0 && (
        <Box className={styles.classes.logContainer}>
          <Typography variant="body1">No changes found</Typography>
        </Box>
      )}
      {loading && Object.keys(groupedLogs)?.length === 0 && <LoaderScreen />}
      {Object.entries(groupedLogs).map(([group, logs]) => (
        <Box key={group} className={styles.classes.logContainer}>
          <Box className={styles.classes.logDetails}>
            <Typography variant="subtitle1">{group}</Typography>
            <IconButton onClick={() => toggleExpand(group)}>
              {expanded[group] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
          <Collapse in={expanded[group]}>
            {logs.map((log, i) => (
              <Box key={i} ml={2}>
                <Log log={log} type={type} />
              </Box>
            ))}
          </Collapse>
        </Box>
      ))}
      {logs?.length < totalLogs && (
        <Box className={styles.classes.loadMoreContainer}>
          {loading ? (
            <LoaderScreen />
          ) : (
            <Button onClick={() => nextPage(type, id)} variant="outlined">
              Load More
            </Button>
          )}
        </Box>
      )}
    </>
  );
}
