import React from "react";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import { SignIn as ClerkSignIn } from "@clerk/clerk-react";

import ThemeContext from "../utils/context/theme-context";
import { resetPostHog } from "../utils/helpers";

export default function SignIn(): JSX.Element {
  const environment = process.env.REACT_APP_NODE_ENV;

  React.useEffect(() => {
    // Reset PostHog
    resetPostHog();
  }, []);

  const { darkMode } = React.useContext(ThemeContext);

  let environmentLabel = "";

  switch (environment) {
    case "dev":
      environmentLabel = "Development";
      break;
    case "staging":
      environmentLabel = "Staging";
      break;
    case "uat":
      environmentLabel = "UAT";
      break;
    case "test":
      environmentLabel = "Test";
      break;
    default:
      environmentLabel = "";
  }

  const title = `Sign In ${
    environment !== "prod" ? `(${environmentLabel}) ` : ""
  }- CapeZero`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          alignItems: "center",
        }}
      >
        <ClerkSignIn
          appearance={{
            layout: {
              logoImageUrl: darkMode ? "./logo-dark.svg" : "./logo.svg",
            },
          }}
          path="/sign-in"
        />
      </Box>
    </>
  );
}
