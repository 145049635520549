import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import { useParams } from "react-router-dom";

import ViewWrapper from "../../../../../components/view-wrapper";
import ReportTable from "../../../../../components/report-table";
import ToggleSizingOutputButton from "../../../../../components/toggle-sizing-output-button";
import { useAPI } from "../../../../../utils/hooks";
import { useAxios } from "../../../../../components/axios-provider";
import { AppContext } from "../../../../../utils/context/app-context";
import { downloadUserDealReport } from "../../../../../apis/report/deal";
import { CONSTRUCTION_DEBT_CASH_REPORT_COLUMNS } from "../../../../../constants";
import {
  cn,
  getDealReportingUrl,
  generateConstructionDebtCashReportTableRows,
} from "../../../../../utils/helpers";
import {
  ReportTerm,
  ITableRow,
  ReportType,
  ReportPerspective,
  OutputReportPeriodicityType,
  IConstructionDebtCashReport,
} from "../../../../../interfaces";

interface IProps {
  getDealReporting: (
    dealId: number,
    reportPerspective: ReportPerspective,
    reportType: ReportType,
    reportTerm: ReportTerm,
    reportPeriodicityType: OutputReportPeriodicityType,
  ) => Promise<IConstructionDebtCashReport>;
}

export default function DealOutputConstructionDebtCashView({
  getDealReporting,
}: IProps): JSX.Element {
  const { caseDealId } = useParams();

  const { urlLoadingPercentages } = useAxios();

  const { setDealOutputLoaded } = React.useContext(AppContext);

  const [report, setReport] = React.useState<IConstructionDebtCashReport>();
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  const {
    callAPI: getDealReportingCallAPI,
    errored: getReportFailed,
    loading: loadingReport,
    errorObj: getReportErrorObj,
    showUsageLimitHitScreen,
  } = useAPI(
    (
      dealId: number,
      term: ReportTerm,
      reportPeriodicityType: OutputReportPeriodicityType,
    ) =>
      getDealReporting(
        dealId,
        "constructiondebt",
        "cashdetail",
        term,
        reportPeriodicityType,
      ),
    { initialLoading: true },
  );

  React.useEffect(() => {
    if (Number(caseDealId)) {
      getDealReportingCallAPI(caseDealId, "default", "MO").then((res) => {
        res && setReport(res);
      });
    }
  }, [caseDealId]);

  React.useEffect(() => {
    !loadingReport && setDealOutputLoaded(!getReportFailed);
  }, [getReportFailed, loadingReport]);

  const handleDownloadReport = async () => {
    if (caseDealId) {
      setIsDownloading(true);
      await downloadUserDealReport(
        Number(caseDealId),
        "constructiondebt",
        "cashdetail",
        "default",
      ).catch(() => null);
      setIsDownloading(false);
    }
  };

  const tableRows: ITableRow[] = React.useMemo(() => {
    return report ? generateConstructionDebtCashReportTableRows(report) : [];
  }, [report]);

  const reportLoadingPercentage =
    urlLoadingPercentages[
      getDealReportingUrl(
        Number(caseDealId),
        "constructiondebt",
        "cashdetail",
        "default",
        "MO",
      )
    ];

  return (
    <ViewWrapper
      loading={loadingReport}
      error={getReportFailed}
      errorHeading={getReportErrorObj?.heading}
      errorDescription={getReportErrorObj?.description}
      showLimitHitView={showUsageLimitHitScreen}
      loadingPercentage={reportLoadingPercentage}
    >
      <Box>
        <Box className={cn("flex justify-end gap-2 my-4")}>
          <ToggleSizingOutputButton />
          <IconButton
            title="Download Report"
            onClick={handleDownloadReport}
            disabled={isDownloading}
          >
            <DownloadIcon />
          </IconButton>
        </Box>

        <ReportTable
          columns={CONSTRUCTION_DEBT_CASH_REPORT_COLUMNS}
          rows={tableRows}
          boldTotal
          stickyTotal
        />
      </Box>
    </ViewWrapper>
  );
}
