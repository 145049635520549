import React from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";

import Charts from "./charts";
import useStyles from "./styles";
import ViewWrapper from "../../../../../components/view-wrapper";
import ReportTable from "../../../../../components/report-table";
import ChartWrapper from "../../../../../components/chart-wrapper";
import CheckboxInput from "../../../../../components/checkbox-input";
import ToggleSizingOutputButton from "../../../../../components/toggle-sizing-output-button";
import { AppContext } from "../../../../../utils/context/app-context";
import { useAxios } from "../../../../../components/axios-provider";
import { useAPI, useSessionStorage } from "../../../../../utils/hooks";
import {
  IGNORE_SPONSOR_EQUITY_TABLE_FIELDS_FOR_DP,
  IGNORE_SPONSOR_EQUITY_IRR_TABLE_FIELDS_FOR_EO,
  ITEMS_AND_TOTAL_TABLE_COLUMN,
  IGNORE_SPONSOR_EQUITY_TABLE_FIELDS_FOR_TRANSFER,
} from "../../../../../constants";
import {
  generateShortSponsorEquityReportSummary,
  generateIrrSponsorEquityReportSummary,
  getSponsorEquityReportSummaryUrl,
  cn,
} from "../../../../../utils/helpers";
import {
  ITableRow,
  ISponsorEquityReportSummary,
  ISponsorEquityReportSummaryChart,
  ISponsorEquityReportSummaryMaster,
} from "../../../../../interfaces";

interface IProps {
  getSponsorEquityReportSummary: (
    dealId: number,
    params: { force_unlevered: boolean },
  ) => Promise<ISponsorEquityReportSummaryMaster>;
}

export default function DealOutputSponsorEquitySummaryView({
  getSponsorEquityReportSummary,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { urlLoadingPercentages } = useAxios();

  const { caseDealId } = useParams();

  const [activeShowUnlevered, setActiveShowUnlevered] = useSessionStorage<
    null | boolean
  >(`active-deal-${caseDealId}-show-unlevered`, false);

  const [sponsorEquityReportSummary, setSponsorEquityReportSummary] =
    React.useState<ISponsorEquityReportSummary>();
  const [sponsorEquityReportSummaryChart, setSponsorEquityReportSummaryChart] =
    React.useState<ISponsorEquityReportSummaryChart>();

  const { setDealOutputLoaded, casesOfDeal, currentDeal, currentDealDebt } =
    React.useContext(AppContext);

  const {
    callAPI: getSponsorEquityReportSummaryCallAPI,
    errored: getSponsorEquityReportSummaryFailed,
    loading: loadingSponsorEquityReportSummary,
    errorObj: getSponsorEquityReportSummaryErrorObj,
    showUsageLimitHitScreen,
  } = useAPI(
    (dealId: number, forceUnlevered: boolean) =>
      getSponsorEquityReportSummary(dealId, {
        force_unlevered: forceUnlevered,
      }),
    {
      initialLoading: true,
    },
  );

  React.useEffect(() => {
    !loadingSponsorEquityReportSummary &&
      setDealOutputLoaded(!getSponsorEquityReportSummaryFailed);
  }, [getSponsorEquityReportSummaryFailed, loadingSponsorEquityReportSummary]);

  React.useEffect(() => {
    if (Number(caseDealId)) {
      // Get the current value from sessionStorage
      const currentValue = sessionStorage.getItem(
        `active-deal-${caseDealId}-show-unlevered`,
      );
      // If there's a value in sessionStorage for the new caseDealId, update activeShowUnlevered
      if (currentValue !== null) {
        setActiveShowUnlevered(JSON.parse(currentValue));
      }

      getSponsorEquityReportSummaryCallAPI(
        Number(caseDealId),
        JSON.parse(currentValue ?? "false"),
      ).then((response) => {
        if (response) {
          setSponsorEquityReportSummary(response.data);
          setSponsorEquityReportSummaryChart(response.data.chart);
        }
      });
    }
  }, [caseDealId, activeShowUnlevered]);

  const cashShortTableRows: ITableRow[] = React.useMemo(() => {
    if (sponsorEquityReportSummary) {
      return generateShortSponsorEquityReportSummary(
        sponsorEquityReportSummary.metrics,
      );
    }
    return [];
  }, [sponsorEquityReportSummary]);

  const tableIrrRows: ITableRow[] = React.useMemo(() => {
    if (sponsorEquityReportSummary) {
      return generateIrrSponsorEquityReportSummary(
        sponsorEquityReportSummary.metrics,
      );
    }
    return [];
  }, [sponsorEquityReportSummary]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setActiveShowUnlevered(e.target.checked);
  };

  const isSensitive: boolean = React.useMemo(() => {
    const item = casesOfDeal?.find(
      (c) => c?.child_deal?.id === Number(caseDealId),
    );
    return item?.type === "SENS";
  }, [caseDealId, casesOfDeal]);

  return (
    <>
      <Box className={cn("flex justify-between items-center my-4")}>
        <Box>
          {!isSensitive && currentDealDebt && (
            <CheckboxInput
              label="Show Unlevered"
              onChange={handleCheckboxChange}
              checked={activeShowUnlevered ?? false}
              disabled={loadingSponsorEquityReportSummary}
            />
          )}
        </Box>
        <Box>
          <ToggleSizingOutputButton />
        </Box>
      </Box>
      <ViewWrapper
        loading={loadingSponsorEquityReportSummary}
        error={getSponsorEquityReportSummaryFailed}
        errorHeading={getSponsorEquityReportSummaryErrorObj?.heading}
        errorDescription={getSponsorEquityReportSummaryErrorObj?.description}
        showLimitHitView={showUsageLimitHitScreen}
        loadingPercentage={
          urlLoadingPercentages[
            getSponsorEquityReportSummaryUrl(
              Number(caseDealId),
              activeShowUnlevered ?? false,
            )
          ]
        }
      >
        <React.Fragment>
          <Box className={styles.classes.dealSection}>
            <Box>
              <Box className={styles.classes.innerChartContainer}>
                <ReportTable
                  columns={ITEMS_AND_TOTAL_TABLE_COLUMN}
                  rows={tableIrrRows}
                  ignore={
                    currentDeal?.structure === "EO"
                      ? IGNORE_SPONSOR_EQUITY_IRR_TABLE_FIELDS_FOR_EO
                      : []
                  }
                />
              </Box>
              <ReportTable
                columns={ITEMS_AND_TOTAL_TABLE_COLUMN}
                rows={cashShortTableRows}
                ignore={
                  currentDeal?.tax_credit_structure === "DP"
                    ? IGNORE_SPONSOR_EQUITY_TABLE_FIELDS_FOR_DP
                    : IGNORE_SPONSOR_EQUITY_TABLE_FIELDS_FOR_TRANSFER
                }
              />
            </Box>

            <Box className={styles.classes.chartContainer}>
              <Box className={styles.classes.innerChartContainer}>
                <ChartWrapper title="Sponsor Equity Value Components (NPV)">
                  <Charts
                    sponsorEquityChart={sponsorEquityReportSummaryChart}
                    isDirectPay={currentDeal?.tax_credit_structure === "DP"}
                  />
                </ChartWrapper>
              </Box>
            </Box>
          </Box>
        </React.Fragment>
      </ViewWrapper>
    </>
  );
}
