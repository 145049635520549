import React from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";

import Charts from "./charts";
import useStyles from "./styles";
import ChartWrapper from "../../../../../components/chart-wrapper";
import ViewWrapper from "../../../../../components/view-wrapper";
import ReportTable from "../../../../../components/report-table";
import ToggleSizingOutputButton from "../../../../../components/toggle-sizing-output-button";
import { useAPI } from "../../../../../utils/hooks";
import { AppContext } from "../../../../../utils/context/app-context";
import { useAxios } from "../../../../../components/axios-provider";
import { ITEMS_AND_TOTAL_TABLE_COLUMN } from "../../../../../constants";
import {
  getTransferReportSummaryUrl,
  generateTransferReportSummary,
  generateIrrTransferReportSummary,
  generateShortTransferReportSummary,
  cn,
} from "../../../../../utils/helpers";
import {
  ITableRow,
  ITransferReportSummary,
  ITransferReportSummaryMaster,
  ITransferReportSummaryChart,
} from "../../../../../interfaces";

interface IProps {
  getTransferReportSummary: (
    dealId: number,
  ) => Promise<ITransferReportSummaryMaster>;
}

export default function DealOutputTransferSummaryView({
  getTransferReportSummary,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { caseDealId } = useParams();

  const { urlLoadingPercentages } = useAxios();

  const [transferReportSummary, setTransferReportSummary] =
    React.useState<ITransferReportSummary>();
  const [transferReportSummaryChart, setTransferReportSummaryChart] =
    React.useState<ITransferReportSummaryChart | null>();

  const { setDealOutputLoaded, currentDeal } = React.useContext(AppContext);

  const {
    callAPI: getTransferReportSummaryCallAPI,
    errored: getTransferReportSummaryFailed,
    loading: loadingTransferReportSummary,
    errorObj: transferSummaryErrorObj,
    showUsageLimitHitScreen,
  } = useAPI((dealId: number) => getTransferReportSummary(dealId), {
    initialLoading: true,
  });

  React.useEffect(() => {
    !loadingTransferReportSummary &&
      setDealOutputLoaded(!getTransferReportSummaryFailed);
  }, [getTransferReportSummaryFailed, loadingTransferReportSummary]);

  React.useEffect(() => {
    if (Number(caseDealId)) {
      getTransferReportSummaryCallAPI(Number(caseDealId)).then((response) => {
        if (response) {
          setTransferReportSummary(response.data);
          setTransferReportSummaryChart(response.data.chart);
        }
      });
    }
  }, [caseDealId]);

  const cashTableRows: ITableRow[] | null = React.useMemo(() => {
    if (transferReportSummary) {
      return generateTransferReportSummary(transferReportSummary.summary);
    }
    return [];
  }, [transferReportSummary]);

  const cashShortTableRows: ITableRow[] | null = React.useMemo(() => {
    if (transferReportSummary) {
      return generateShortTransferReportSummary(transferReportSummary.summary);
    }
    return [];
  }, [transferReportSummary]);

  const tableIrrRows: ITableRow[] | null = React.useMemo(() => {
    if (transferReportSummary) {
      return generateIrrTransferReportSummary(
        transferReportSummary.summary,
        transferReportSummary.irr_error,
      );
    }
    return [];
  }, [transferReportSummary]);

  const currentCaseDealStructure = React.useMemo(
    () => currentDeal?.tax_credit_structure,
    [caseDealId],
  );

  return (
    <ViewWrapper
      loading={loadingTransferReportSummary}
      error={getTransferReportSummaryFailed}
      errorHeading={transferSummaryErrorObj?.heading}
      errorDescription={transferSummaryErrorObj?.description}
      showLimitHitView={showUsageLimitHitScreen}
      loadingPercentage={
        urlLoadingPercentages[getTransferReportSummaryUrl(Number(caseDealId))]
      }
    >
      <Box className={cn("flex justify-end my-4")}>
        <ToggleSizingOutputButton />
      </Box>
      {transferReportSummaryChart &&
      cashShortTableRows &&
      cashTableRows &&
      tableIrrRows ? (
        <Box className={styles.classes.dealSection}>
          <Box>
            <Box className={styles.classes.innerChartContainer}>
              <ReportTable
                columns={ITEMS_AND_TOTAL_TABLE_COLUMN}
                rows={tableIrrRows}
                ignore={currentCaseDealStructure == "DP" ? ["ROI"] : []}
              />
            </Box>
          </Box>

          <Box className={styles.classes.chartContainer}>
            <Box className={styles.classes.innerChartContainer}>
              <ChartWrapper
                title={
                  currentCaseDealStructure == "DP"
                    ? "Direct Pay Value Components (NPV)"
                    : "Transfer Value Components (NPV)"
                }
              >
                <Charts transferChart={transferReportSummaryChart} />
              </ChartWrapper>
            </Box>
          </Box>
        </Box>
      ) : (
        <p className={styles.classes.text404}>
          Transfer Summary Not Available!
        </p>
      )}
    </ViewWrapper>
  );
}
