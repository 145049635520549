import React from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableContainer from "@mui/material/TableContainer";
import { format } from "date-fns";
import { Protect } from "@clerk/clerk-react";

import useStyles from "./styles";
import Button from "../../../../components/button";
import PopoverMenu from "../../../../components/popover-menu";
import ViewWrapper from "../../../../components/view-wrapper";
import ProjectOtherFlowFormModal from "../../../../components/other-flow-form-modal";
import StickyTableCell from "../../../../components/sticky-table-cell";
import { AppContext } from "../../../../utils/context/app-context";
import { cn, sortArrayOfObjects } from "../../../../utils/helpers";
import { useNavigate, useParams } from "react-router-dom";
import { useAPI } from "../../../../utils/hooks";
import {
  PROJECT_OTHER_STREAM_TYPE,
  USER_PERMISSIONS,
  PROJECT_OTHER_STREAM_INPUT_TYPE,
  PROJECT_OTHER_FLOW_FORM_DEFAULT_STATE,
} from "../../../../constants";
import {
  ITableSort,
  IProjectTiming,
  IProjectOtherFlow,
  IProjectOtherFlowForm,
  ServerPaginatedResponse,
  IProjectOtherFlowFormErrors,
} from "../../../../interfaces";

interface IProps {
  getProjectTiming: (projectId: number) => Promise<IProjectTiming[]>;
  addProjectOtherFlow: (
    projectId: number,
    form: IProjectOtherFlowForm,
  ) => Promise<IProjectOtherFlow>;
  getProjectOtherFlow: (
    projectId: number,
  ) => Promise<ServerPaginatedResponse<IProjectOtherFlow[]>>;
  editProjectOtherFlow: (
    projectId: number,
    id: number,
    form: IProjectOtherFlowForm,
  ) => Promise<IProjectOtherFlow>;
  deleteProjectOtherFlow: (projectId: number, id: number) => Promise<boolean>;
}

const CASH_TABLE_COLUMNS = [
  { id: "name", label: "Name", minWidth: 200, align: "left" },
  { id: "type", label: "Type", minWidth: 200, align: "left" },
  { id: "input_type", label: "Input Type", minWidth: 200, align: "left" },
  { id: "created", label: "Created", minWidth: 50, align: "left" },
  { id: "modified", label: "Last Modified", minWidth: 50, align: "left" },
  { id: "action", label: "Action", minWidth: 50, align: "right" },
];

export default function ProjectOtherFlowView({
  getProjectTiming,
  addProjectOtherFlow,
  getProjectOtherFlow,
  editProjectOtherFlow,
  deleteProjectOtherFlow,
}: IProps) {
  const styles = useStyles();
  const navigate = useNavigate();

  const { projectId } = useParams();

  const { setConfirmDeleteModalProps } = React.useContext(AppContext);

  const [openAddStreamFormModal, setOpenAddStreamFormModal] =
    React.useState<boolean>(false);
  const [streamForm, setStreamForm] = React.useState<IProjectOtherFlowForm>(
    PROJECT_OTHER_FLOW_FORM_DEFAULT_STATE,
  );
  const [projectStream, setProjectStream] = React.useState<IProjectOtherFlow[]>(
    [],
  );
  const [dateSchedule, setDateSchedule] = React.useState<string[]>([]);
  const [sortTable, setSortTable] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });
  const [selectedOtherStream, setSelectedOtherStream] = React.useState<
    number | null
  >(null);
  const [openEditStreamFormModal, setOpenEditStreamFormModal] =
    React.useState<boolean>(false);

  const { callAPI: getProjectTimingCallAPI } = useAPI((projectId: number) =>
    getProjectTiming(projectId),
  );

  const {
    callAPI: getProjectOtherFlowCallAPI,
    loading: getProjectOtherFlowLoading,
    errored: getProjectOtherFlowErrored,
  } = useAPI((projectId: number) => getProjectOtherFlow(projectId), {
    initialLoading: true,
  });

  const {
    callAPI: addProjectOtherFlowCallAPI,
    fieldErrors: addProjectOtherFlowFormErrors,
    setFieldErrors: setAddProjectOtherFlowFormErrors,
    loading: loadingAddProjectOtherFlow,
  } = useAPI<IProjectOtherFlow, IProjectOtherFlowFormErrors>(
    (projectId: number, form: IProjectOtherFlowForm) =>
      addProjectOtherFlow(projectId, form),
  );

  const {
    callAPI: editProjectOtherFlowCallAPI,
    fieldErrors: editProjectOtherFlowFormErrors,
    setFieldErrors: setEditProjectOtherFlowFormErrors,
    loading: loadingEditProjectOtherFlow,
  } = useAPI<IProjectOtherFlow, IProjectOtherFlowFormErrors>(
    (projectId: number, id: number, form: IProjectOtherFlowForm) =>
      editProjectOtherFlow(projectId, id, form),
  );

  const { callAPI: deleteProjectOtherFlowCallAPI } = useAPI(
    (projectId: number, id: number) => deleteProjectOtherFlow(projectId, id),
    { setConfirmModalLoading: true },
  );

  React.useEffect(() => {
    getProjectOtherFlowCallAPI(Number(projectId)).then((res) => {
      res && setProjectStream(res.results);
    });
    getProjectTimingCallAPI(Number(projectId)).then((response) => {
      response && setDateSchedule(response[0]?.date_schedule || []);
    });
  }, [projectId]);

  const sortRows = (orderBy: string) => {
    if (orderBy === sortTable.orderBy) {
      setSortTable({
        orderBy,
        order: sortTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const visibleRows = React.useMemo(
    () =>
      sortArrayOfObjects(projectStream, sortTable?.orderBy, sortTable?.order),
    [sortTable, projectStream],
  );

  const handleOpenAddStreamModal = () => {
    setStreamForm((prevState) => ({
      ...prevState,
      stream_strip: new Array(dateSchedule.length).fill(0),
    }));
    setOpenAddStreamFormModal(true);
  };

  const handleCloseAddStreamModal = () => {
    setOpenAddStreamFormModal(false);
  };

  const onAddStream = async (form: IProjectOtherFlowForm) => {
    const cash = await addProjectOtherFlowCallAPI(Number(projectId), form);
    if (cash) {
      setProjectStream((prev) => {
        return [cash, ...prev];
      });
      gotoDetailPage(cash.id);
    }
    return cash;
  };

  const gotoDetailPage = (id: number) => {
    navigate(`/project/${projectId}/pro-forma/other-flows/${id}`);
  };

  const handleOpenEditStreamModal = (id: number) => {
    setSelectedOtherStream(id);
    if (id) {
      const cash = projectStream.find((c) => c.id === id);
      if (cash) {
        const { name, stream_strip, type, input_type } = cash;
        setStreamForm({
          name,
          stream_strip,
          type,
          input_type,
        });
        setOpenEditStreamFormModal(true);
      }
    }
  };

  const handleCloseEditStreamModal = () => {
    setOpenEditStreamFormModal(false);
    setSelectedOtherStream(null);
  };

  const onEditStream = async (form: IProjectOtherFlowForm) => {
    const cash = await editProjectOtherFlowCallAPI(
      Number(projectId),
      selectedOtherStream,
      form,
    );
    cash &&
      setProjectStream((prev) => {
        return prev.map((c) => (c.id === selectedOtherStream ? cash : c)); // updating the table
      });
    return cash;
  };

  const handleDeleteStream = async (id: number) => {
    const deleted = await deleteProjectOtherFlowCallAPI(Number(projectId), id);

    if (deleted) {
      // removing from table
      deleted &&
        setProjectStream((prev) => {
          return prev.filter((c) => c.id !== id);
        });
    }
  };

  const onDeleteStream = (id: number) => {
    if (id) {
      setConfirmDeleteModalProps({
        open: true,
        title: "Delete Other Flow",
        description: "Are you sure you want to delete?",
        onConfirm: () => handleDeleteStream(id),
      });
    }
  };

  return (
    <>
      <ViewWrapper
        loading={getProjectOtherFlowLoading}
        error={getProjectOtherFlowErrored}
      >
        <Box>
          <Box className={cn("flex justify-end my-4")}>
            <Protect permission={USER_PERMISSIONS.PROJECTS_CRUD}>
              <Button
                btnType="primary"
                label="Add Other Flow"
                startIcon={<AddIcon />}
                onClick={handleOpenAddStreamModal}
              />
            </Protect>
          </Box>

          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer classes={{ root: cn("!h-[calc(100vh_-_412px)]") }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead className={styles.classes.header}>
                  <TableRow>
                    {CASH_TABLE_COLUMNS.map((column, idx) => {
                      if (column.id === "action") {
                        return (
                          <StickyTableCell
                            key={idx}
                            direction="right"
                            fixedColumnWidth={50}
                            align="center"
                            highZIndex
                          >
                            {column.label}
                          </StickyTableCell>
                        );
                      }
                      return (
                        <TableCell
                          key={idx}
                          align={column.align as "left"}
                          style={{ minWidth: column.minWidth }}
                        >
                          <TableSortLabel
                            active={sortTable.orderBy === column.id}
                            direction={
                              sortTable.orderBy === column.id
                                ? sortTable.order
                                : "asc"
                            }
                            onClick={() => sortRows(column.id)}
                          >
                            {column.label}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectStream.length === 0 ? (
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={CASH_TABLE_COLUMNS.length}
                      >
                        No Flow found. Please add one.
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {visibleRows.map((row, idx) => {
                    return (
                      <TableRow
                        hover
                        key={idx}
                        tabIndex={-1}
                        className={cn("!cursor-pointer")}
                        onClick={() => gotoDetailPage(row?.id)}
                      >
                        <TableCell align="left">{row?.name}</TableCell>
                        <TableCell align="left">
                          {PROJECT_OTHER_STREAM_TYPE[row.type]}
                        </TableCell>
                        <TableCell align="left">
                          {PROJECT_OTHER_STREAM_INPUT_TYPE[row.input_type]}
                        </TableCell>
                        <TableCell align="left">
                          {format(new Date(row.created), "M/d/yyyy")}
                        </TableCell>
                        <TableCell align="left">
                          {format(new Date(row.modified), "M/d/yyyy")}
                        </TableCell>
                        <StickyTableCell
                          direction="right"
                          fixedColumnWidth={50}
                          align="center"
                        >
                          <Protect permission={USER_PERMISSIONS.PROJECTS_CRUD}>
                            <PopoverMenu
                              uniqueId={idx}
                              canOpenUpgrade
                              items={[
                                {
                                  label: "Edit",
                                  onClick: () =>
                                    handleOpenEditStreamModal(row.id),
                                },
                                {
                                  label: "Delete",
                                  onClick: () => onDeleteStream(row.id),
                                },
                              ]}
                            />
                          </Protect>
                        </StickyTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </ViewWrapper>

      <ProjectOtherFlowFormModal
        open={openAddStreamFormModal}
        headerLabel="Add Other Flow"
        form={streamForm}
        loading={loadingAddProjectOtherFlow}
        formErrors={addProjectOtherFlowFormErrors}
        setFormErrors={setAddProjectOtherFlowFormErrors}
        setForm={setStreamForm}
        onClose={handleCloseAddStreamModal}
        onConfirm={onAddStream}
        dateSchedule={dateSchedule}
      />

      <ProjectOtherFlowFormModal
        open={openEditStreamFormModal}
        headerLabel="Edit Other Flow"
        form={streamForm}
        loading={loadingEditProjectOtherFlow}
        formErrors={editProjectOtherFlowFormErrors}
        setFormErrors={setEditProjectOtherFlowFormErrors}
        setForm={setStreamForm}
        onClose={handleCloseEditStreamModal}
        onConfirm={onEditStream}
        dateSchedule={dateSchedule}
      />
    </>
  );
}
