import { LOG_API_ROTES, OBJECT_TYPE } from "../../constants";
import { ILog, ServerPaginatedResponse } from "../../interfaces";
import { AxiosHelper } from "../../utils/helpers";

export const getLogs = async (
  object_type: OBJECT_TYPE,
  object_id: number | string,
  limit: number,
  offset: number,
) => {
  return await AxiosHelper.get<ServerPaginatedResponse<ILog[]>>(
    LOG_API_ROTES.get,
    {
      params: {
        object_type,
        object_id,
        limit,
        offset,
      },
    },
  );
};
