import { get } from "lodash";

export const PROJECT_API_ROUTES = {
  base: {
    get: "project/",
    getProjectOfDeal: "deal/:dealId/projects/",
    getProjectDetails: "project/:projectId/",
    create: "project/",
    update: "project/:projectId/",
    duplicate: "project/:projectId/clone/",
    getDetailsFat: "project/:projectId/fat/",
  },
  cost: {
    summary: {
      get: `project/:projectId/project_cost/summary/`,
      getCostSchedule: `reporting/project/:projectId/pre-cod-proforma?type=:type`,
      download: `reporting/project/:projectId/pre-cod-proforma/download/?type=:type`,
    },
    costUpdate: `project/:projectId/project_cost/:costId/update/`,
    costList: `/project/:projectId/project_cost/`,
    costItem: {
      get: `project/:projectId/project_cost/:costId/cost_item/:itemId/`,
      create: `project/:projectId/project_cost/:costId/cost_item/`,
      update: `project/:projectId/project_cost/:costId/cost_item/:itemId/`,
      delete: `project/:projectId/project_cost/:costId/cost_item/:itemId/`,
    },
    creditSupport: {
      get: `project/:projectId/credit-support/`,
      getDetails: `project/:projectId/credit-support/:creditSupportId/`,
      create: `project/:projectId/credit-support/`,
      update: `project/:projectId/credit-support/:creditSupportId/`,
      delete: `project/:projectId/credit-support/:creditSupportId/`,
    },
    get: `project/:projectId/cost/`,
    create: `project/:projectId/cost/`,
    update: `project/:projectId/cost/:costId/`,
  },
  valuation: {
    get: `project/:projectId/valuation/`,
    create: `project/:projectId/valuation/`,
    update: `project/:projectId/valuation/:valuationId/`,
  },
  expense: {
    get: "project/:projectId/expenses/",
    getExpenseDetails: "project/:projectId/expenses/:expenseId/",
    create: "project/:projectId/expenses/",
    update: "project/:projectId/expenses/:expenseId/",
    delete: "project/:projectId/expenses/:expenseId/",
  },
  depreciation: {
    get: "project/:projectId/depreciation/",
    getStream:
      "project/:projectId/depreciation/:depreciationId/streams/:streamId/",
    createStream: "project/:projectId/depreciation/:depreciationId/streams/",
    updateStream:
      "project/:projectId/depreciation/:depreciationId/streams/:streamId/",
    deleteStream:
      "project/:projectId/depreciation/:depreciationId/streams/:streamId/",
  },
  production: {
    get: "project/:projectId/production/",
    create: "project/:projectId/production/",
    update: "project/:projectId/production/:productionId/",
  },
  revenueContracts: {
    get: "project/:projectId/revenue-contracts/",
    getDetails: "project/:projectId/revenue-contracts/:revenueId/",
    create: "project/:projectId/revenue-contracts/",
    update: "project/:projectId/revenue-contracts/:revenueId/",
    delete: "project/:projectId/revenue-contracts/:revenueId/",
    getMerchantRevenues: "project/:projectId/merchant-revenue/",
    getMerchantRevenue: "project/:projectId/merchant-revenue/:revenueId/",
    updateMerchantRevenue: "project/:projectId/merchant-revenue/:revenueId/",
    deleteMerchantRevenue: "project/:projectId/merchant-revenue/:revenueId/",
    addMerchantRevenue: "project/:projectId/merchant-revenue/",
  },
  scheduleOverrides: {
    get: "project/:projectId/schedule-overrides/",
    create: "project/:projectId/schedule-overrides/",
    update: "project/:projectId/schedule-overrides/:overrideId/",
  },
  taxCredit: {
    get: "project/:projectId/tax-credit/",
    create: "project/:projectId/tax-credit/",
    update: "project/:projectId/tax-credit/:taxCreditId/",
    getPTC_Report: "project/:projectId/tax-credit/ptc/",
  },
  timing: {
    get: `project/:projectId/timing/`,
    create: `project/:projectId/timing/`,
    update: `project/:projectId/timing/:timingId/`,
    delete: `project/:projectId/timing/:timingId/`,
    dateSchedule: `project/:projectId/timing/date_schedule`,
  },
  projectProforma: {
    get: "reporting/project/:projectId/proforma/?periodicity=:periodicity&basis=:basis",
  },
  other: {
    otherFlows: {
      add: "project/:projectId/other-flows/",
      get: "project/:projectId/other-flows/",
      getDetail: "project/:projectId/other-flows/:id",
      edit: "project/:projectId/other-flows/:id/",
      delete: "project/:projectId/other-flows/:id/",
    },
  },
};

export const DEAL_API_ROUTES = {
  base: {
    get: "deal/",
    getDetails: "deal/:dealId/",
    create: "deal/",
    update: "deal/:dealId/",
    duplicate: "deal/:dealId/clone/",
    getDetailsFat: "deal/:dealId/fat",
  },
  cost: {
    summary: {
      getCostSchedule: `reporting/deal/:dealId/pre-cod-proforma?type=:type`,
      download: `reporting/deal/:dealId/pre-cod-proforma/download/`,
    },
    get: "deal/:dealId/cost/?cost_input_type=:cost_input_type",
    getCreditSupportSummart:
      "deal/:dealId/credit-support-summary/?cost_input_type=:cost_input_type",
    deal_cost: "deal/:dealId/deal-cost/",
  },
  tax_credit: {
    get: "deal/:dealId/tax-credits/",
  },
  dealProjectDepreciation: {
    get: "deal/:dealId/project-depreciation/",
    update: "deal/:dealId/project-depreciation/",
  },
  depreciation: {
    get: "deal/:dealId/depreciation/",
    create: "deal/:dealId/depreciation/",
    update: "deal/:dealId/depreciation/:depreciationId/",
  },
  taxEquity: {
    get: "deal/:dealId/tax-equity/",
    create: "deal/:dealId/tax-equity/",
    update: "deal/:dealId/tax-equity/:taxEquityId/",
    delete: "deal/:dealId/tax-equity/:taxEquityId/",
  },
  debt: {
    get: "deal/:dealId/term-debt/",
    create: "deal/:dealId/term-debt/",
    update: "deal/:dealId/term-debt/:debtId/",
    delete: "deal/:dealId/term-debt/:debtId/",
  },
  debtConstruction: {
    get: "deal/:dealId/construction-debt-general-sizing/",
    create: "deal/:dealId/construction-debt-general-sizing/",
    update: "deal/:dealId/construction-debt-general-sizing/:debtId/",
    delete: "deal/:dealId/construction-debt-general-sizing/:debtId/",
    constructionLoan: {
      get: "deal/:dealId/construction-debt/",
      create: "deal/:dealId/construction-debt/",
      update: "deal/:dealId/construction-debt/:debtId/",
      delete: "deal/:dealId/construction-debt/:debtId/",
    },
    investorBridgeLoan: {
      get: "deal/:dealId/investor-bridge-debt/",
      create: "deal/:dealId/investor-bridge-debt/",
      update: "deal/:dealId/investor-bridge-debt/:debtId/",
      delete: "deal/:dealId/investor-bridge-debt/:debtId/",
    },
    transferBridgeLoan: {
      get: "deal/:dealId/transfer-bridge-debt/",
      create: "deal/:dealId/transfer-bridge-debt/",
      update: "deal/:dealId/transfer-bridge-debt/:debtId/",
      delete: "deal/:dealId/transfer-bridge-debt/:debtId/",
    },
  },
  fee: {
    get: "deal/:dealId/fee/",
    getFeeDetails: "deal/:dealId/fee/:feeId/",
    create: "deal/:dealId/fee/",
    update: "deal/:dealId/fee/:feeId/",
    delete: "deal/:dealId/fee/:feeId/",
  },
  timing: {
    get: "deal/:dealId/timing/",
    update: "deal/:dealId/timing/:timingId/",
  },
  dealProforma: {
    get: "reporting/deal/:dealId/proforma/?periodicity=:periodicity&basis=:basis",
  },
  dealTaxCreditTransfer: {
    get: "deal/:dealId/tax-credit-transfer/",
    update: "deal/:dealId/transfer-sizing/:transferSizingId/",
  },
  dealTaxCreditDirectPay: {
    get: "deal/:dealId/tax-credit-direct-pay/",
    update: "deal/:dealId/tax-credit-direct-pay/:directPayId/",
  },
  case: {
    get: "deal/:dealId/case/",
    create: "deal/:dealId/case/",
    getDetails: "deal/:dealId/case/:caseId/",
    delete: "deal/:dealId/case/:caseId/",
    duplicate: "deal/:dealId/case/:caseId/clone/",
    update: "deal/:dealId/case/:caseId/",
    getLite: "deal/:dealId/case/lite/",
  },
  analysis: {
    update: "deal-comparison/:id/",
    get: "deal-comparison/",
    create: "deal-comparison/",
  },
  dealOtherSizing: {
    get: "deal/:dealId/other-sizing/",
    update: "deal/:dealId/other-sizing/:sizingId/",
  },
  dealSaleLeaseback: {
    create: "deal/:dealId/slb-sizing/:id/",
    get: "deal/:dealId/slb-sizing/",
    edit: "deal/:dealId/slb-sizing/:id/",
    details: "deal/:dealId/slb-sizing/:id/",
  },
  sponsorEquity: {
    get: "deal/:dealId/sponsor-equity/",
    create: "deal/:dealId/sponsor-equity/",
    update: "deal/:dealId/sponsor-equity/:sponsorEquityId/",
  },
  transferPortfolio: {
    get: `deal/:dealId/transfer-config/`,
    update: `deal/:dealId/transfer-config/:transferConfigId/`,
  },
};

export const DEAL_COMPARISON_API_ROUTES = {
  base: {
    create: "deal-comparison/",
    get: "deal-comparison/",
    getDetails: "deal-comparison/:id/",
    update: "deal-comparison/:id/",
    delete: "deal-comparison/:id/",
  },
  analysis: {
    getInputs:
      "deal-comparison/:dealComparisonId/deal/:dealId/input-sizing-summary/",
    getOutputs: "deal-comparison/:dealComparisonId/deal/:dealId/outputs/",
  },
};

export const REPORT_API_ROUTES = {
  deal: {
    get: "reporting/deal/:dealId/?perspective=:reportPerspective&type=:reportType&term=:reportTerm&periodicity=:periodicity",
    getDownloadUserDealReport:
      "reporting/deal/:dealId/download/?perspective=:reportPerspective&type=:reportType&term=:reportTerm&periodicity=:periodicity",
    getSponsorEquityBOL_ReportSummary:
      "/reporting/deal/:dealId/sponsor-equity-bol/summary/",
    getDeveloperReportSummary: "reporting/deal/:dealId/developer/summary/",
    getTaxEquityReportSummary: "reporting/deal/:dealId/tax-equity/summary/",
    getSponsorEquityReportSummary:
      "reporting/deal/:dealId/sponsor-equity/summary/",
    getDebtReportSummary: "reporting/deal/:dealId/term-debt/summary/",
    getConstructionDebtSummary:
      "reporting/deal/:dealId/construction-debt/summary/",
    getByStage: "reporting/deals/by-stage/",
    getNonTransferCashChart:
      "reporting/deal/:dealId/cash-charts/?is_percent=:is_percent",
    getTransferCashChart:
      "reporting/deal/:dealId/transfer-cash-charts/?is_percent=:is_percent",
    getDealPartnershipDepreciationChart:
      "reporting/deal/:dealId/partnership/depreciation-charts/?is_percent=:is_percent",
    getDealNetOperatingIncomeChart:
      "reporting/deal/:dealId/partnership/net-operating-income-charts/?is_percent=:is_percent",
    getDealOtherIncomeChart:
      "reporting/deal/:dealId/partnership/other-income-loss-charts/?is_percent=:is_percent",
    getDealDebtServiceChart:
      "reporting/deal/:dealId/term-debt/debt-service-charts/?is_percent=:is_percent",
    getDealDebtBalanceChart:
      "reporting/deal/:dealId/term-debt/debt-balance-charts/?is_percent=:is_percent",
    getDealTaxEquityChart:
      "reporting/deal/:dealId/tax-equity/tax-charts/?is_percent=:is_percent",
    getDealTaxEquityCashChart:
      "reporting/deal/:dealId/tax-equity/cashflow-charts/?is_percent=:is_percent",
    getDealSponsorCashEquityChart:
      "reporting/deal/:dealId/sponsor-equity/sponsor-charts/?is_percent=:is_percent",
    getDealSponsorEquityCashChart:
      "reporting/deal/:dealId/sponsor-equity/cashflow-charts/?is_percent=:is_percent",
    getCapitalChartData: "reporting/deal/:dealId/partnership/capital-sources/",
    getOutputPartnershipSplit: "reporting/deal/:dealId/partnership/split/",
    getOutputPartnershipSummary: "reporting/deal/:dealId/partnership/summary/",
    getTransferReportSummary: "reporting/deal/:dealId/transfer/summary/",
    getDealTransferCash: "reporting/deal/:dealId/transfer/cash/",
    getOutputPartnershipNetValueComponentChart: `reporting/deal/:dealId/partnership/net-value-component-charts/`,
    getOutputLessorSummary: "reporting/deal/:dealId/lessor/summary/",
    getOutputLesseeSummary: "reporting/deal/:dealId/lessee/summary/",
  },
  project: {
    getByStage: "reporting/projects/by-stage/",
  },
  pipeline: {
    getByYear: "reporting/pipeline/by-year/",
  },
};

export const CO_ZE_API_ROUTES = {
  base: {
    get: "coze/",
    create: "coze/",
    update: "coze/:coZeId/",
    delete: "coze/:coZeId/",
    updateCoZeConvertToDeal: "coze/:coZeId/convert-to-deal/",
  },
};

export const LOG_API_ROTES = {
  get: "/common/audit-logs",
};

export const CONFIGURATION_ROUTES = {
  base: {
    get: "org/",
    usage: "org/usage/",
  },
  data: {
    curveGroups: `common/curve-groups/`,
    editCurveGroup: `common/curve-groups/:id/`,
    deleteCurveGroup: `common/curve-groups/:id/`,
    curves: `common/curves/`,
    editCurve: `common/curves/:id/`,
    deleteCurve: `common/curves/:id/`,
    getCurve: `common/curves/:id/`,
  },
  dates_schedule: {
    get: `/common/get-schedule-dates`,
  },
  user: {
    getUser: "/clerk/me/",
  },
};

export const DASHBOARD_ROUTES = {
  metrics: {
    get: "/reporting/global/summary/",
  },
  charts: {
    get: "/reporting/projects/splits/",
  },
};
