import React from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";

import Charts from "./charts";
import CashChart from "./cash-charts";
import useStyles from "./styles";
import ChartWrapper from "../../../../../components/chart-wrapper";
import ViewWrapper from "../../../../../components/view-wrapper";
import ToggleSizingOutputButton from "../../../../../components/toggle-sizing-output-button";
import { useAPI } from "../../../../../utils/hooks";
import { AppContext } from "../../../../../utils/context/app-context";
import { DEAL_STRUCTURE_TYPE } from "../../../../../constants";
import { useAxios } from "../../../../../components/axios-provider";
import { cn, getDealTaxEquityChartUrl } from "../../../../../utils/helpers";
import {
  ITaxEquityCashChart,
  ITaxEquityChart,
} from "../../../../../interfaces";

interface IProps {
  getDealTaxEquityChart: (
    dealId: number,
    is_percent: string,
  ) => Promise<ITaxEquityChart | null>;
  getDealTaxEquityCashChart: (
    dealId: number,
    is_percent: string,
  ) => Promise<ITaxEquityCashChart | null>;
}

export default function DealOutputTaxChartsView({
  getDealTaxEquityChart,
  getDealTaxEquityCashChart,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { caseDealId } = useParams();

  const { urlLoadingPercentages } = useAxios();

  const { currentDeal, setDealOutputLoaded } = React.useContext(AppContext);

  const [taxEquityChart, setTaxEquityChartView] =
    React.useState<ITaxEquityChart>();
  const [taxEquityChartPercent, setTaxEquityChartViewPercent] =
    React.useState<ITaxEquityChart>();

  const [taxEquityCashChart, setTaxEquityCashChartView] =
    React.useState<ITaxEquityCashChart>();
  const [taxEquityCashChartPercent, setTaxEquityCashChartViewPercent] =
    React.useState<ITaxEquityCashChart>();

  React.useEffect(() => {
    if (Number(caseDealId)) {
      getDealTaxEquityChartCallAPI(Number(caseDealId), "no").then(
        (response) => {
          if (response) {
            setTaxEquityChartView(response);
          }
        },
      );
      getDealTaxEquityChartCallAPI(Number(caseDealId), "yes").then(
        (response) => {
          if (response) {
            setTaxEquityChartViewPercent(response);
          }
        },
      );
      getDealTaxEquityCashChartCallAPI(Number(caseDealId), "no").then(
        (response) => {
          if (response) {
            setTaxEquityCashChartView(response);
          }
        },
      );
      getDealTaxEquityCashChartCallAPI(Number(caseDealId), "yes").then(
        (response) => {
          if (response) {
            setTaxEquityCashChartViewPercent(response);
          }
        },
      );
    }
  }, [caseDealId]);

  const {
    callAPI: getDealTaxEquityChartCallAPI,
    errored: getDealTaxEquityChartFailed,
    loading: loadingDealTaxEquityChart,
    errorObj: getDealTaxEquityChartErrorObj,
    showUsageLimitHitScreen,
  } = useAPI(
    (dealId: number, is_percent: string) =>
      getDealTaxEquityChart(dealId, is_percent),
    {
      initialLoading: true,
    },
  );

  React.useEffect(() => {
    !loadingDealTaxEquityChart &&
      setDealOutputLoaded(!getDealTaxEquityChartFailed);
  }, [getDealTaxEquityChartFailed, loadingDealTaxEquityChart]);

  const { callAPI: getDealTaxEquityCashChartCallAPI } = useAPI(
    (dealId: number, is_percent: string) =>
      getDealTaxEquityCashChart(dealId, is_percent),
  );

  const currentCaseDealStructure = React.useMemo(
    () => currentDeal?.structure,
    [caseDealId],
  );

  return (
    <ViewWrapper
      loading={loadingDealTaxEquityChart}
      error={getDealTaxEquityChartFailed}
      errorHeading={getDealTaxEquityChartErrorObj?.heading}
      errorDescription={getDealTaxEquityChartErrorObj?.description}
      showLimitHitView={showUsageLimitHitScreen}
      loadingPercentage={
        urlLoadingPercentages[
          getDealTaxEquityChartUrl(Number(caseDealId), "yes")
        ]
      }
    >
      <Box>
        <Box className={cn("flex justify-end mt-2")}>
          <ToggleSizingOutputButton />
        </Box>
        {taxEquityCashChart && taxEquityCashChartPercent && (
          <Box className={styles.classes.projectChartsContainer}>
            <ChartWrapper
              title={
                DEAL_STRUCTURE_TYPE[
                  currentCaseDealStructure as keyof typeof DEAL_STRUCTURE_TYPE
                ] === DEAL_STRUCTURE_TYPE.TEP
                  ? "Tax Equity Cash Flow"
                  : "Cash Equity Cash Flow"
              }
            >
              <CashChart
                cashReportChart={taxEquityCashChart}
                showChart={"cashReportChart"}
                stack={true}
                isPercentage={false}
                label={
                  DEAL_STRUCTURE_TYPE[
                    currentCaseDealStructure as keyof typeof DEAL_STRUCTURE_TYPE
                  ] === DEAL_STRUCTURE_TYPE.TEP
                    ? "Tax Equity Cash Flow"
                    : "Cash Equity Cash"
                }
              />
            </ChartWrapper>
            <ChartWrapper
              title={
                DEAL_STRUCTURE_TYPE[
                  currentCaseDealStructure as keyof typeof DEAL_STRUCTURE_TYPE
                ] === DEAL_STRUCTURE_TYPE.TEP
                  ? "Tax Equity Cash Flow"
                  : "Cash Equity Cash"
              }
            >
              <CashChart
                cashReportChart={taxEquityCashChartPercent}
                showChart={"cashReportChart"}
                stack={true}
                isPercentage={true}
                label={
                  DEAL_STRUCTURE_TYPE[
                    currentCaseDealStructure as keyof typeof DEAL_STRUCTURE_TYPE
                  ] === DEAL_STRUCTURE_TYPE.TEP
                    ? "Tax Equity Cash Flow"
                    : "Cash Equity Cash"
                }
              />
            </ChartWrapper>
          </Box>
        )}

        {taxEquityChart && taxEquityChartPercent && (
          <Box className={styles.classes.projectChartsContainer}>
            <ChartWrapper
              title={
                DEAL_STRUCTURE_TYPE[
                  currentCaseDealStructure as keyof typeof DEAL_STRUCTURE_TYPE
                ] === DEAL_STRUCTURE_TYPE.TEP
                  ? "Tax Equity Taxable Income"
                  : "Cash Equity Taxable Income"
              }
            >
              <Charts
                cashReportChart={taxEquityChart}
                showChart={"cashReportChart"}
                stack={true}
                isPercentage={false}
                label={
                  DEAL_STRUCTURE_TYPE[
                    currentCaseDealStructure as keyof typeof DEAL_STRUCTURE_TYPE
                  ] === DEAL_STRUCTURE_TYPE.TEP
                    ? "Tax Equity Taxable Income"
                    : "Cash Equity Taxable Income"
                }
              />
            </ChartWrapper>
            <ChartWrapper
              title={
                DEAL_STRUCTURE_TYPE[
                  currentCaseDealStructure as keyof typeof DEAL_STRUCTURE_TYPE
                ] === DEAL_STRUCTURE_TYPE.TEP
                  ? "Tax Equity Taxable Income"
                  : "Cash Equity Taxable Income"
              }
            >
              <Charts
                cashReportChart={taxEquityChartPercent}
                showChart={"cashReportChart"}
                stack={true}
                isPercentage={true}
                label={
                  DEAL_STRUCTURE_TYPE[
                    currentCaseDealStructure as keyof typeof DEAL_STRUCTURE_TYPE
                  ] === DEAL_STRUCTURE_TYPE.TEP
                    ? "Tax Equity Taxable Income"
                    : "Cash Equity Taxable Income"
                }
              />
            </ChartWrapper>
          </Box>
        )}
      </Box>
    </ViewWrapper>
  );
}
