import React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import LogsIcon from "@mui/icons-material/Restore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { Protect } from "@clerk/clerk-react";
import { isNumber } from "lodash";
import { useNavigate, useParams } from "react-router-dom";

import useStyle from "./styles";
import Logs from "../../../../../components/logs";
import Button from "../../../../../components/button";
import LogsWrapper from "../../../../../components/logs-wrapper";
import ViewWrapper from "../../../../../components/view-wrapper";
import DetailsCard from "../../../../../components/details-card";
import DealFeeFormModal from "../../../../../components/deal-fee-form-modal";
import { AppContext } from "../../../../../utils/context/app-context";
import { useLogs, useDrawer, useAPI } from "../../../../../utils/hooks";
import { numberToUSD } from "../../../../../utils/helpers";
import {
  DEAL_FEE_TYPES,
  USER_PERMISSIONS,
  DEAL_FEE_ALLOCATION_METHODS,
  DEAL_FEE_COST_WEIGHTING_METHODS,
  DEAL_FEE_FORM_DEFAULT_STATE,
} from "../../../../../constants";
import {
  IDealFee,
  IDealFeeForm,
  IDealFeeFormErrors,
  ILogsConfiguration,
} from "../../../../../interfaces";

interface IProps {
  getDealFeeDetails: (dealId: number, feeId: number) => Promise<IDealFee>;
  updateDealFee: (
    dealId: number,
    feeId: number,
    form: IDealFeeForm,
  ) => Promise<IDealFee>;
  deleteDealFee: (dealId: number, feeId: number) => Promise<boolean>;
}

export default function DealFeeDetailsView({
  getDealFeeDetails,
  updateDealFee,
  deleteDealFee,
}: IProps): JSX.Element {
  const styles = useStyle();
  const navigate = useNavigate();

  const { dealId, caseDealId, dealFeeId } = useParams();

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const { setConfirmDeleteModalProps } = React.useContext(AppContext);

  const [dealFee, setDealFee] = React.useState<IDealFee>();
  React.useState<boolean>(false);
  const [updateDealFeeModalOpen, setUpdateDealFeeModalOpen] =
    React.useState<boolean>(false);
  const [form, setForm] = React.useState<IDealFeeForm>(
    DEAL_FEE_FORM_DEFAULT_STATE,
  );

  React.useEffect(() => {
    getDealFeeDetailsCallAPI(Number(caseDealId), Number(dealFeeId)).then(
      (res) => res && setDealFee(res),
    );
  }, [caseDealId, dealFeeId]);

  const {
    callAPI: getDealFeeDetailsCallAPI,
    errored: getFeesFailed,
    loading: loadingFees,
  } = useAPI(
    (dealId: number, dealFeeId: number) => getDealFeeDetails(dealId, dealFeeId),
    {
      initialLoading: true,
    },
  );

  const {
    callAPI: updateDealFeeCallAPI,
    fieldErrors: updateDealFeeFormErrors,
    setFieldErrors: setUpdateDealFeeFormErrors,
    loading: updateDealFeeLoading,
  } = useAPI<IDealFee, IDealFeeFormErrors>(
    (dealId: number, feeId: number, form: IDealFeeForm) =>
      updateDealFee(dealId, feeId, form),
  );

  const { callAPI: deleteDealFeeCallAPI } = useAPI(
    (dealId, feeId) => deleteDealFee(dealId, feeId),
    {
      setConfirmModalLoading: true,
    },
  );

  const handleOnUpdateDealFee = async (form: IDealFeeForm) => {
    const fee = await updateDealFeeCallAPI(
      Number(caseDealId),
      Number(dealFee?.id),
      form,
    );
    fee && setDealFee(fee);
    return fee;
  };

  const handleOpenUpdateDealFeeModal = () => {
    if (dealFee) {
      const {
        name,
        fee_type,
        amount,
        allocation_method,
        amortization_term,
        cost_weighting_method,
      } = dealFee;
      setForm({
        fee_type,
        name,
        amount,
        allocation_method,
        amortization_term,
        cost_weighting_method,
      });
      setUpdateDealFeeModalOpen(true);
    }
  };

  const handleDeleteDealFee = async () => {
    const deletedFee = await deleteDealFeeCallAPI(
      Number(caseDealId),
      dealFeeId,
    );
    deletedFee && goBackToDealFees();
  };

  const handleOnDeleteClick = async () => {
    setConfirmDeleteModalProps({
      open: true,
      title: "Delete Fee",
      description: "Are you sure you want to delete this fee?",
      onConfirm: handleDeleteDealFee,
    });
  };

  const handleCloseUpdateDealFeeModal = () => {
    setUpdateDealFeeModalOpen(false);
  };

  const isOnCase = React.useMemo(() => {
    return dealId !== caseDealId;
  }, [dealId, caseDealId]);

  const dealFeeLogConfiguration: ILogsConfiguration = {
    id: Number(dealFeeId),
    type: "dealfee",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(dealFeeLogConfiguration.type, dealFeeLogConfiguration.id);
  };

  const goBackToDealFees = () => {
    navigate(`/deal/${dealId}/case-deal/${caseDealId}/sizing/deal-fee`);
  };

  return (
    <>
      <ViewWrapper loading={loadingFees} error={getFeesFailed}>
        <Box className={styles.classes.header}>
          <IconButton
            classes={{ root: styles.classes.iconButton }}
            onClick={goBackToDealFees}
          >
            <ArrowBackIcon />
          </IconButton>
          <Box className={styles.classes.buttonsContainer}>
            <IconButton onClick={handleOnOpenLogs}>
              <LogsIcon />
            </IconButton>
            <Protect permission={USER_PERMISSIONS.DEALS_CRUD}>
              {!isOnCase && (
                <>
                  <Button
                    canOpenUpgrade
                    startIcon={<EditIcon />}
                    label="Edit"
                    btnType="primary"
                    onClick={handleOpenUpdateDealFeeModal}
                  />
                  <Button
                    canOpenUpgrade
                    label="Delete"
                    btnType="danger"
                    onClick={handleOnDeleteClick}
                  />
                </>
              )}
            </Protect>
          </Box>
        </Box>

        <Box className={styles.classes.content}>
          {dealFee && (
            <DetailsCard
              heading="Fee Details"
              dataPw="fee-details"
              autoHeight
              sections={[
                {
                  fields: [
                    {
                      label: "Fee Type",
                      value: {
                        text: DEAL_FEE_TYPES[dealFee.fee_type],
                      },
                    },
                    {
                      label: "Name",
                      value: {
                        text: dealFee.name || "N/A",
                      },
                    },
                    {
                      label: "Amount",
                      value: {
                        text: numberToUSD.format(dealFee.amount ?? 0),
                      },
                    },
                    {
                      label: "Allocation Method",
                      value: {
                        text: DEAL_FEE_ALLOCATION_METHODS[
                          dealFee.allocation_method
                        ],
                      },
                    },
                    ...(dealFee.allocation_method == "DFA"
                      ? [
                          {
                            label: "Amortization Term",
                            value: {
                              text: isNumber(dealFee.amortization_term)
                                ? dealFee.amortization_term + "Yr"
                                : "N/A",
                            },
                          },
                        ]
                      : []),
                    ...(dealFee.allocation_method == "PIC"
                      ? [
                          {
                            label: "Cost Weighting Method",
                            value: {
                              text:
                                DEAL_FEE_COST_WEIGHTING_METHODS[
                                  dealFee.cost_weighting_method
                                ] ?? "N/A",
                            },
                          },
                        ]
                      : []),
                  ],
                },
              ]}
            />
          )}
        </Box>
      </ViewWrapper>

      <DealFeeFormModal
        open={updateDealFeeModalOpen}
        loading={updateDealFeeLoading}
        headerLabel="Update Deal Fee"
        formErrors={updateDealFeeFormErrors}
        setFormErrors={setUpdateDealFeeFormErrors}
        form={form}
        setForm={setForm}
        onClose={handleCloseUpdateDealFeeModal}
        onConfirm={handleOnUpdateDealFee}
      />

      <LogsWrapper
        label="Activity"
        onClose={handleCloseDrawer}
        open={isDrawerOpen}
      >
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={dealFeeLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={dealFeeLogConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
