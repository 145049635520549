import ProjectPagesLayout from "../../../components/project-pages-layout";
import TabbedLayout from "../../../components/tabbed-layout";
import ProjectValuationCalculationView from "../../../views/project/valuation/calculation";
import { getProjectValuation } from "../../../apis/project/valuation";

export default function Valuation(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Valuation">
        <ProjectValuationCalculationView
          getProjectValuation={getProjectValuation}
        />
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}
