import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import Chip from "@mui/material/Chip";
import MuiButton from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Protect } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

import useStyles from "./styles";
import PopoverMenu from "../../../../components/popover-menu";
import CostItemFormModal from "../../../../components/cost-item-form-modal";
import ThemeContext from "../../../../utils/context/theme-context";
import StickyTableCell from "../../../../components/sticky-table-cell";
import { AppContext } from "../../../../utils/context/app-context";
import { getProjectTimingDateSchedule } from "../../../../apis/project/timing";
import { useAPI, useLocalStorage } from "../../../../utils/hooks";
import {
  COST_ITEM_FORM_DEFAULT_STATE,
  COST_METHOD_TYPES,
  COST_START_DATE_TYPES,
  PROJECT_COST_INPUT_TYPE,
} from "../../../../constants";
import {
  editProjectCostItem,
  deleteProjectCostItem,
} from "../../../../apis/project/cost";
import {
  cn,
  numberToUSD,
  numberWithCommas,
  numberWithDecimalsAndCommas,
  sortArrayOfObjects,
} from "../../../../utils/helpers";
import {
  ICostItemForm,
  ICostItemFormErrors,
  ICostItemResponse,
  IProjectCostItem,
  IProjectTimingDateScheduleResults,
  ITableSort,
} from "../../../../interfaces";

interface IProps {
  title: string;
  rows: IProjectCostItem[];
  projectId: string;
  costId: string;
  onUpdate: () => void;
  totalCost: number;
  disableExpand?: boolean;
  disableTitle?: boolean;
  permission: string;
  costType?: string;
  disableNavigation?: boolean;
}

const TABLE_COLUMNS = [
  { id: "name", label: "Name", minWidth: 170, align: "left" },
  {
    id: "cost_input_type",
    label: "Input Type",
    minWidth: 50,
    align: "left",
  },
  {
    id: "cost_in_dollars",
    label: "Cost ($)",
    minWidth: 50,
    align: "left",
  },
  {
    id: "cost_in_dollars_per_watt",
    label: "Cost ($/Wdc)",
    minWidth: 50,
    align: "left",
  },
  {
    id: "cost_multiplier",
    label: "Cost Multiplier (%)",
    minWidth: 50,
    align: "left",
  },
  {
    id: "cost_method",
    label: "Spend Method",
    minWidth: 50,
    align: "left",
  },
  {
    id: "cost_start_point",
    label: "Start Point",
    minWidth: 50,
    align: "left",
  },
  {
    id: "cost_end_point",
    label: "End Point",
    minWidth: 50,
    align: "left",
  },
  { id: "action", label: "Action", minWidth: 50, align: "right" },
];

export default function ProjectCostTable({
  title,
  rows,
  projectId,
  costId,
  onUpdate,
  totalCost,
  disableExpand = false,
  disableTitle = false,
  permission,
  costType,
  disableNavigation = false,
}: IProps) {
  const styles = useStyles();
  const navigate = useNavigate();

  const { setConfirmDeleteModalProps } = React.useContext(AppContext);
  const { darkMode } = React.useContext(ThemeContext);

  const [openEditCostModal, setOpenEditCostModal] = React.useState(false);
  const [editCostForm, setEditCostForm] = React.useState<ICostItemForm>(
    COST_ITEM_FORM_DEFAULT_STATE,
  );
  const [selectedItem, setSelectedItem] =
    React.useState<IProjectCostItem | null>(null);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(5);
  const [expanded, setExpanded] = useLocalStorage<boolean>(
    `${title}-expanded`,
    rows.length > 0,
  );
  const [sortTable, setSortTable] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = React.useMemo(
    () =>
      sortArrayOfObjects(rows, sortTable?.orderBy, sortTable?.order).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [rows, sortTable, page, rowsPerPage],
  );

  const {
    callAPI: getProjectTimingDateScheduleCallAPI,
    response: projectTimingDateSchedule,
  } = useAPI<IProjectTimingDateScheduleResults, ICostItemFormErrors>(
    (projectId) =>
      getProjectTimingDateSchedule({ period_type: "pre_cod" }, projectId),
  );

  const {
    callAPI: editCostItemCallAPI,
    loading: editCostItemLoading,
    fieldErrors: editCostItemFormErrors,
    setFieldErrors: setEditCostItemFormErrors,
  } = useAPI<ICostItemResponse, ICostItemFormErrors>(
    (form, projectId, costId, itemId) =>
      editProjectCostItem(form, projectId, costId, itemId),
  );

  const { callAPI: deleteCostItemCallAPI, loading: loadingDeleteCostItem } =
    useAPI(
      (projectId, costId, itemId) =>
        deleteProjectCostItem(projectId, costId, itemId),
      { setConfirmModalLoading: true },
    );

  const handleCloseEditCostModal = () => {
    setOpenEditCostModal(false);
    setSelectedItem(null);
  };

  const handleEditCost = async (itemId: number) => {
    const projectTimingDateSchedule = await getProjectTimingDateScheduleCallAPI(
      projectId,
    ).catch(() => null);
    const item = rows.find((row) => row.id === itemId);
    if (item) {
      setSelectedItem(item);
      const {
        cost_input_type,
        cost_method,
        name,
        cost_percentage_curve,
        cost_start_point,
        cost_end_point,
        cost_type,
        cost,
        cost_multiplier,
      } = item;
      setEditCostForm({
        cost: cost.toString(),
        cost_multiplier: cost_multiplier.toString(),
        cost_input_type,
        cost_method,
        cost_percentage_curve: cost_percentage_curve
          ? cost_percentage_curve
          : new Array(
              projectTimingDateSchedule?.data?.date_schedule?.length || 0,
            ).fill(0),
        cost_start_point,
        cost_end_point,
        cost_type,
        name,
      });
      setOpenEditCostModal(true);
    }
  };

  const handleDeleteCost = async (itemId: number) => {
    const res = await deleteCostItemCallAPI(projectId, costId, itemId);
    res && onUpdate();
  };

  const handleOnDeleteClick = (itemId: number) => {
    if (costId) {
      setConfirmDeleteModalProps({
        open: true,
        title: "Delete Cost",
        description: "Are you sure you want to delete this cost?",
        onConfirm: () => handleDeleteCost(itemId),
        loading: loadingDeleteCostItem,
      });
    }
  };

  const onEditConfirm = async (form: ICostItemForm) => {
    const res = await editCostItemCallAPI(
      form,
      projectId,
      costId,
      selectedItem?.id,
    );
    res && onUpdate();
    return res;
  };

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const sortRows = (orderBy: string) => {
    if (orderBy === sortTable.orderBy) {
      setSortTable({
        orderBy,
        order: sortTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const navigateToDetailsPage = (id: number) => {
    if (!disableNavigation) {
      navigate(`/project/${projectId}/cost-inputs/${costId}/${id}`);
    }
  };

  const chipStyles = React.useMemo(() => {
    const baseStyles = {
      base: "!rounded-sm !font-semibold !transition-none",
      darkMode: "!bg-[#292929]",
      lightMode: "!text-gray-500 !bg-[#F5F5F5]",
    };

    return `${baseStyles.base} ${
      darkMode ? baseStyles.darkMode : baseStyles.lightMode
    }`;
  }, [darkMode]);

  const buttonStyles = React.useMemo(() => {
    const baseStyles = {
      base: "!w-full !transition-none !capitalize",
      darkMode: "!border !border-[#292929]",
      lightMode: "!bg-[#fbfbfb] !border !border-[#e0e0e0]]",
    };

    return `${baseStyles.base} ${
      darkMode ? baseStyles.darkMode : baseStyles.lightMode
    }`;
  }, [darkMode]);

  const timingDateSchedule = React.useMemo(() => {
    return projectTimingDateSchedule?.data.date_schedule || [];
  }, [projectTimingDateSchedule]);

  return (
    <Box>
      {!disableTitle && (
        <Box className={cn("flex justify-between items-center mb-4")}>
          <Box className={cn("flex gap-2 items-center p-2")}>
            <Typography variant="h6">{title}</Typography>
            <Chip
              label={numberToUSD.format(totalCost)}
              classes={{ root: chipStyles }}
            />
          </Box>
        </Box>
      )}
      <Collapse in={expanded}>
        <TableContainer>
          <Table
            classes={{
              root: styles.classes.table,
            }}
          >
            <TableHead>
              <TableRow>
                {TABLE_COLUMNS.map((col, idx) => {
                  if (col.id === "action") {
                    return (
                      <StickyTableCell
                        key={idx}
                        direction="right"
                        fixedColumnWidth={50}
                        align="center"
                        highZIndex
                      >
                        {col.label}
                      </StickyTableCell>
                    );
                  }
                  return (
                    <TableCell
                      key={idx}
                      style={{ minWidth: col.minWidth }}
                      align={col.align as "left" | "right"}
                    >
                      <TableSortLabel
                        active={sortTable.orderBy === col.id}
                        direction={
                          sortTable.orderBy === col.id ? sortTable.order : "asc"
                        }
                        onClick={() => sortRows(col.id)}
                      >
                        {col.label}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {visibleRows.length === 0 && (
                <TableRow data-pw={`${title}-no-costs`}>
                  <TableCell
                    style={{ padding: 8 }}
                    align="center"
                    colSpan={TABLE_COLUMNS.length}
                  >
                    No Costs
                  </TableCell>
                </TableRow>
              )}
              {(visibleRows || []).map((row, index) => (
                <TableRow
                  classes={{
                    root: cn(`${!disableNavigation ? "cursor-pointer" : ""}`),
                  }}
                  key={index}
                  onClick={() => navigateToDetailsPage(row.id)}
                  data-pw={`${title}-${index + 1}`}
                >
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    {PROJECT_COST_INPUT_TYPE[row.cost_input_type]}
                  </TableCell>
                  <TableCell>
                    {numberWithCommas.format(row.cost_in_dollars)}
                  </TableCell>
                  <TableCell>
                    {numberWithDecimalsAndCommas(
                      row.cost_in_dollars_per_watt,
                      2,
                    )}
                  </TableCell>
                  <TableCell>
                    {numberWithDecimalsAndCommas(row.cost_multiplier, 2)}
                  </TableCell>
                  <TableCell>{COST_METHOD_TYPES[row.cost_method]}</TableCell>
                  <TableCell>
                    {COST_START_DATE_TYPES[row.cost_start_point] ?? "N/A"}
                  </TableCell>
                  <TableCell>
                    {COST_START_DATE_TYPES[row.cost_end_point] ?? "N/A"}
                  </TableCell>
                  <StickyTableCell
                    direction="right"
                    fixedColumnWidth={50}
                    align="center"
                  >
                    <Protect permission={permission}>
                      <PopoverMenu
                        uniqueId={index}
                        canOpenUpgrade
                        items={[
                          {
                            label: "Edit",
                            onClick: () => handleEditCost(row.id),
                          },
                          {
                            label: "Delete",
                            onClick: () => handleOnDeleteClick(row.id),
                          },
                        ]}
                      />
                    </Protect>
                  </StickyTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={rows.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Collapse>

      {!disableExpand && (
        <Box className={styles.classes.footer}>
          <MuiButton
            onClick={handleToggleExpand}
            endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
            classes={{ root: buttonStyles }}
            data-pw={`${title}-expand-toggle-button`}
          >
            {expanded ? "Show Less" : `Show More (${rows.length})`}
          </MuiButton>
        </Box>
      )}

      <CostItemFormModal
        dateSchedule={timingDateSchedule}
        form={editCostForm}
        headerLabel="Edit Project Cost"
        loading={editCostItemLoading}
        onClose={handleCloseEditCostModal}
        onConfirm={onEditConfirm}
        open={openEditCostModal}
        setForm={setEditCostForm}
        setFormErrors={setEditCostItemFormErrors}
        formErrors={editCostItemFormErrors}
      />
    </Box>
  );
}
