import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import LogsIcon from "@mui/icons-material/Restore";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import IconButton from "@mui/material/IconButton";
import { Protect } from "@clerk/clerk-react";
import { useParams } from "react-router-dom";

import useStyles from "./styles";
import Logs from "../../../../components/logs";
import Button from "../../../../components/button";
import LogsWrapper from "../../../../components/logs-wrapper";
import ViewWrapper from "../../../../components/view-wrapper";
import DetailsCard from "../../../../components/details-card";
import DealTermDebtFormModal from "../../../../components/deal-debt-form-modal";
import ToggleSizingOutputButton from "../../../../components/toggle-sizing-output-button";
import { cn } from "../../../../utils/helpers";
import { useAPI, useDrawer, useLogs } from "../../../../utils/hooks";
import { AppContext } from "../../../../utils/context/app-context";
import {
  DEAL_TERM_DEBT_BASE_INPUT_TYPES,
  DEAL_TERM_DEBT_FORM_DEFAULT_STATE,
  DEAL_TERM_DEBT_PERIODICITY,
  DEAL_TERM_DEBT_SIZING_TIMING,
  DEAL_TERM_DEBT_SOLVE_FORS,
  DEAL_TERM_TERM_DEBT_TYPES,
  USER_PERMISSIONS,
} from "../../../../constants";
import {
  IDealDebt,
  IDealTermDebtForm,
  IDealTermDebtFormErrors,
  IGetCurvesParams,
  ILogsConfiguration,
  IOrganizationCurve,
  ISelectOption,
  ServerPaginatedResponse,
} from "../../../../interfaces";

interface IProps {
  getDealDebt: (dealId: number) => Promise<IDealDebt[]>;
  deleteDealDebt: (dealId: number, taxEquityId: number) => Promise<boolean>;
  addDealDebt: (dealId: number, form: IDealTermDebtForm) => Promise<IDealDebt>;
  updateDealDebt: (
    dealId: number,
    taxEquityId: number,
    form: IDealTermDebtForm,
  ) => Promise<IDealDebt>;
  getCurves: (
    params: IGetCurvesParams,
  ) => Promise<ServerPaginatedResponse<IOrganizationCurve[]>>;
}

export default function DealSizingDebt({
  getDealDebt,
  deleteDealDebt,
  addDealDebt,
  updateDealDebt,
  getCurves,
}: IProps): JSX.Element {
  const styles = useStyles();
  const { dealId, caseDealId } = useParams();

  const {
    setConfirmDeleteModalProps,
    setCurrentDealDebt,
    currentOrganizationCurves,
    setCurrentOrganizationCurves,
    currentDeal,
  } = React.useContext(AppContext);

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const [dealTermDebt, setDealDebt] = React.useState<IDealDebt>();
  const [addDealDebtModalOpen, setAddDealDebtModalOpen] =
    React.useState<boolean>(false);
  const [editDealDebtModalOpen, setEditDealDebtModalOpen] =
    React.useState<boolean>(false);
  const [form, setForm] = React.useState<IDealTermDebtForm>(
    DEAL_TERM_DEBT_FORM_DEFAULT_STATE,
  );

  React.useEffect(() => {
    getDealDebtCallAPI(Number(caseDealId)).then((response) => {
      if (Array.isArray(response)) {
        setDealDebt(response[0]);
        setCurrentDealDebt(response[0]);
      }
    });
  }, [caseDealId]);

  const isOnCase = React.useMemo(() => {
    return dealId !== caseDealId;
  }, [dealId, caseDealId]);

  const {
    callAPI: getDealDebtCallAPI,
    errored: getTaxEquityFailed,
    loading: loadingTaxEquity,
  } = useAPI((dealId: number) => getDealDebt(dealId), { initialLoading: true });

  const {
    callAPI: addDealDebtCallAPI,
    fieldErrors: addDealTermDebtFormErrors,
    setFieldErrors: setAddDealTermDebtFormErrors,
    loading: addDealDebtLoading,
  } = useAPI<IDealDebt, IDealTermDebtFormErrors>(
    (dealId: number, form: IDealTermDebtForm) => addDealDebt(dealId, form),
  );

  const {
    callAPI: updateDealDebtCallAPI,
    fieldErrors: updateDealTermDebtFormErrors,
    setFieldErrors: setUpdateDealTermDebtFormErrors,
    loading: updateDealDebtLoading,
  } = useAPI<IDealDebt, IDealTermDebtFormErrors>(
    (dealId: number, dealTermDebtId: number, form: IDealTermDebtForm) =>
      updateDealDebt(dealId, dealTermDebtId, form),
  );

  const { callAPI: deleteDealDebtCallAPI } = useAPI(
    (dealId: number, debtId: number) => deleteDealDebt(dealId, debtId),
    { setConfirmModalLoading: true },
  );

  const handleGetCurves = async () => {
    const curves = await getCurves({ curve_type: "IRC" }).catch(() => null);
    curves && setCurrentOrganizationCurves(curves.results);
  };

  const organizationCurvesOptions: ISelectOption[] = React.useMemo(() => {
    return currentOrganizationCurves?.map((c) => ({
      label: c.name,
      value: String(c.id),
    }));
  }, [currentOrganizationCurves]);

  const handleOnDeleteDealDebt = async () => {
    setConfirmDeleteModalProps({
      open: true,
      title: "Delete Deal Term Debt",
      description: "Are you sure you want to delete this Deal Debt?",
      onConfirm: handleDeleteDealDebt,
    });
  };

  const handleDeleteDealDebt = async () => {
    if (caseDealId && dealTermDebt) {
      const response = await deleteDealDebtCallAPI(
        Number(caseDealId),
        dealTermDebt.id,
      );
      if (response) {
        setDealDebt(undefined);
        setCurrentDealDebt(undefined);
      }
    }
  };

  const handleOnAddDealDebt = async (form: IDealTermDebtForm) => {
    const dealTermDebt = await addDealDebtCallAPI(Number(caseDealId), form);
    dealTermDebt && setDealDebt(dealTermDebt);
    dealTermDebt && setCurrentDealDebt(dealTermDebt);

    return dealTermDebt;
  };

  const handleOnEditDealDebt = async (form: IDealTermDebtForm) => {
    const updatedDealDebt = await updateDealDebtCallAPI(
      Number(caseDealId),
      Number(dealTermDebt?.id),
      form,
    );
    updatedDealDebt && setDealDebt(updatedDealDebt);
    updatedDealDebt && setCurrentDealDebt(updatedDealDebt);

    return updatedDealDebt;
  };

  const handleOpenAddDealDebtModal = async () => {
    await handleGetCurves();
    setAddDealDebtModalOpen(true);
  };

  const handleCloseAddDealDebtModal = () => {
    setAddDealDebtModalOpen(false);
  };

  const handleOpenEditDealDebtModal = async () => {
    if (dealTermDebt) {
      await handleGetCurves();
      const { id, created, modified, deal, ...formDetails } = dealTermDebt;
      setForm({ ...formDetails });
      setEditDealDebtModalOpen(true);
    }
  };

  const handleCloseEditDealDebtModal = () => {
    setEditDealDebtModalOpen(false);
  };

  const gotoCurveDetailPage = (url: string) => {
    window.open(url);
  };

  const dealSizingDebtLogConfiguration: ILogsConfiguration = {
    id: Number(dealTermDebt?.id),
    type: "dealtermdebt",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      dealSizingDebtLogConfiguration.type,
      dealSizingDebtLogConfiguration.id,
    );
  };

  const debtFundingOptions = React.useMemo(() => {
    return {
      first_cod: currentDeal?.first_cod || "",
      last_cod: currentDeal?.last_cod || "",
    };
  }, [currentDeal]);

  return (
    <>
      <ViewWrapper loading={loadingTaxEquity} error={getTaxEquityFailed}>
        <Box>
          {!dealTermDebt && (
            <Box className={styles.classes.emptyContainer}>
              <Box>
                {!isOnCase && (
                  <Protect permission={USER_PERMISSIONS.DEALS_CRUD}>
                    <Button
                      canOpenUpgrade
                      startIcon={<AddIcon />}
                      label="Add Deal Term Debt"
                      onClick={handleOpenAddDealDebtModal}
                      btnType="primary"
                      className={styles.classes.createBtn}
                    />
                  </Protect>
                )}
                <Typography
                  variant="body1"
                  className={styles.classes.createInfo}
                >
                  Debt on this Deal does not exist.
                  {!isOnCase && `Please click "Add Debt" to add one`}
                </Typography>
              </Box>
            </Box>
          )}

          {dealTermDebt && (
            <Box>
              <Box className={cn("flex justify-end items-center gap-2")}>
                <ToggleSizingOutputButton />
                <IconButton onClick={handleOnOpenLogs}>
                  <LogsIcon />
                </IconButton>
                {!isOnCase && (
                  <Protect permission={USER_PERMISSIONS.DEALS_CRUD}>
                    <Button
                      canOpenUpgrade
                      startIcon={<EditIcon />}
                      label="Edit"
                      btnType="primary"
                      onClick={handleOpenEditDealDebtModal}
                    />
                    <Button
                      canOpenUpgrade
                      label="Delete"
                      btnType="danger"
                      onClick={handleOnDeleteDealDebt}
                    />
                  </Protect>
                )}
              </Box>
              <Box className={styles.classes.content}>
                <Grid container columnSpacing={2}>
                  <Grid item xs={12} md={6}>
                    <Stack gap={2}>
                      <DetailsCard
                        heading="Structure"
                        autoHeight
                        sections={[
                          {
                            fields: [
                              {
                                label: "Type",
                                value: {
                                  text: DEAL_TERM_TERM_DEBT_TYPES[
                                    dealTermDebt.type
                                  ],
                                },
                              },
                              {
                                label: "Solve for",
                                value: {
                                  text: DEAL_TERM_DEBT_SOLVE_FORS[
                                    dealTermDebt.solve_for
                                  ],
                                },
                              },
                            ],
                          },
                        ]}
                      />
                      <DetailsCard
                        heading="Key Parameters"
                        autoHeight
                        sections={[
                          {
                            fields: [
                              {
                                label: "Sizing Timing",
                                value: {
                                  text: DEAL_TERM_DEBT_SIZING_TIMING[
                                    dealTermDebt.sizing_timing as keyof typeof DEAL_TERM_DEBT_SIZING_TIMING
                                  ],
                                },
                              },
                              {
                                label: "Debt Funding Date",
                                value: { text: dealTermDebt.funding_date },
                              },
                              {
                                label: "Amortization Tenor",
                                value: {
                                  text: (dealTermDebt.debt_term || 0) + " Yrs",
                                },
                              },
                              {
                                label: "Payment Periodicity",
                                value: {
                                  text: DEAL_TERM_DEBT_PERIODICITY[
                                    dealTermDebt.payment_periodicity as keyof typeof DEAL_TERM_DEBT_PERIODICITY
                                  ],
                                },
                              },
                              {
                                label: "Base Rate Type",
                                value: {
                                  text: DEAL_TERM_DEBT_BASE_INPUT_TYPES[
                                    dealTermDebt.base_input_type as keyof typeof DEAL_TERM_DEBT_BASE_INPUT_TYPES
                                  ],
                                },
                              },
                              ...(dealTermDebt.base_input_type === "RATE"
                                ? [
                                    {
                                      label: "Base Rate",
                                      value: {
                                        text:
                                          (dealTermDebt.base_rate || 0) + "%",
                                      },
                                    },
                                  ]
                                : []),
                              ...(dealTermDebt.base_input_type === "OC"
                                ? [
                                    {
                                      label: "SOFR (Organization Curve)",
                                      value: {
                                        text: (
                                          <Link
                                            component="button"
                                            variant="body2"
                                            onClick={() =>
                                              gotoCurveDetailPage(
                                                `/configuration/data/curves/${dealTermDebt.sofr_org_curve_detail?.curve_group}/${dealTermDebt.sofr_org_curve_detail?.id}`,
                                              )
                                            }
                                          >
                                            {
                                              dealTermDebt
                                                ?.sofr_org_curve_detail?.name
                                            }
                                            <OpenInNewIcon fontSize="small" />
                                          </Link>
                                        ),
                                      },
                                    },
                                  ]
                                : []),
                              {
                                label: "Spread",
                                value: {
                                  text: (dealTermDebt.spread || 0) + "%",
                                },
                              },
                              {
                                label: "Step Up",
                                value: {
                                  text:
                                    (dealTermDebt.spread_step_up || 0) + "%",
                                },
                              },
                              {
                                label: "Step Up Frequency (Yrs)",
                                value: {
                                  text:
                                    (dealTermDebt.spread_step_up_frequency ||
                                      0) + " Yrs",
                                },
                              },
                              // hide these for now
                              {
                                label: "Hedged Percentage",
                                value: {
                                  text:
                                    (dealTermDebt.hedged_percentage || 0) + "%",
                                },
                              },
                              {
                                label: "Swap Rate",
                                value: {
                                  text: (dealTermDebt.swap_rate || 0) + "%",
                                },
                              },
                              {
                                label: "Swap Rate Credit Spread",
                                value: {
                                  text:
                                    (dealTermDebt.swap_rate_credit_spread ||
                                      0) + "%",
                                },
                              },
                              // all fees hidden for now.
                              // {
                              //   label: "Upfront Fee",
                              //   value: {
                              //     text:
                              //       (dealTermDebt.upfront_fees_percentage || 0) +
                              //       "%",
                              //   },
                              // },
                              // {
                              //   label: "LC Fee",
                              //   value: {
                              //     text:
                              //       (dealTermDebt.lc_fees_percentage || 0) + "%",
                              //   },
                              // },
                              // {
                              //   label: "Admin Fee",
                              //   value: {
                              //     text: numberToUSD.format(
                              //       dealTermDebt.admin_fees || 0,
                              //     ),
                              //   },
                              // },
                            ],
                          },
                        ]}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DetailsCard
                      heading="Additional Parameters"
                      autoHeight
                      sections={[
                        {
                          fields: [
                            {
                              label: "Sizing Method",
                              value: {
                                text: "Coverage Ratio",
                              },
                            },
                            {
                              label: "Contracted Cash DSCR",
                              value: {
                                text:
                                  (dealTermDebt?.contracted_cash_dscr || 0) +
                                  "x",
                              },
                            },
                            {
                              label: "Uncontracted Cash DSCR",
                              value: {
                                text:
                                  (dealTermDebt?.uncontracted_cash_dscr || 0) +
                                  "x",
                              },
                            },
                            {
                              label: "Special Cash DSCR",
                              value: {
                                text:
                                  (dealTermDebt?.special_cash_dscr || 0) + "x",
                              },
                            },
                            {
                              label: "Transfer Cash DSCR",
                              value: {
                                text:
                                  (dealTermDebt?.transfer_cash_dscr || 0) + "x",
                              },
                            },
                          ],
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </Box>
      </ViewWrapper>

      <DealTermDebtFormModal
        open={addDealDebtModalOpen}
        onClose={handleCloseAddDealDebtModal}
        formErrors={addDealTermDebtFormErrors}
        loading={addDealDebtLoading}
        setFormErrors={setAddDealTermDebtFormErrors}
        form={form}
        setForm={setForm}
        headerLabel="Add Deal Term Debt"
        onConfirm={handleOnAddDealDebt}
        org_curves={organizationCurvesOptions}
        debtFundingDate={debtFundingOptions}
      />

      <DealTermDebtFormModal
        open={editDealDebtModalOpen}
        onClose={handleCloseEditDealDebtModal}
        form={form}
        loading={updateDealDebtLoading}
        formErrors={updateDealTermDebtFormErrors}
        setFormErrors={setUpdateDealTermDebtFormErrors}
        setForm={setForm}
        headerLabel="Edit Deal Term Debt"
        onConfirm={handleOnEditDealDebt}
        org_curves={organizationCurvesOptions}
        debtFundingDate={debtFundingOptions}
      />

      <LogsWrapper
        label="Activity"
        onClose={handleCloseDrawer}
        open={isDrawerOpen}
      >
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={dealSizingDebtLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination?.totalItems}
          id={dealSizingDebtLogConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
