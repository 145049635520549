import React from "react";
import LogsIcon from "@mui/icons-material/Restore";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { Protect } from "@clerk/clerk-react";
import { styled } from "@mui/system";

import useStyles from "./styles";
import Logs from "../../../../components/logs";
import Button from "../../../../components/button";
import ViewWrapper from "../../../../components/view-wrapper";
import LogsWrapper from "../../../../components/logs-wrapper";
import ProjectValuationFormModal from "../../../../components/project-valuation-form-modal";
import DetailsCard from "../../../../components/details-card";
import ValuationDisclaimer from "./disclaimer";
import {
  cn,
  convertDateString,
  formatCurrency,
  formatNumberWithDecimals,
} from "../../../../utils/helpers";
import { useAPI, useDrawer, useLogs } from "../../../../utils/hooks";
import {
  IProjectValuation,
  IProjectValuationForm,
  IProjectValuationFormErrors,
  IProjectValuationReportItem,
  ILogsConfiguration,
} from "../../../../interfaces";
import {
  PROJECT_VALUATION_ELIGIBLE_BASIS_METHODS,
  PROJECT_VALUATION_FMV_METHODS,
  PROJECT_VALUATION_FORM_DEFAULT_STATE,
  USER_PERMISSIONS,
} from "../../../../constants";

interface IProps {
  getProjectValuation: (projectId: number) => Promise<IProjectValuation[]>;
  addProjectValuation: (
    projectId: number,
    form: IProjectValuationForm,
  ) => Promise<IProjectValuation>;
  editProjectValuation: (
    projectId: number,
    valuationId: number,
    form: IProjectValuationForm,
  ) => Promise<IProjectValuation>;
}

interface StickyTableCellProps {
  left: number;
}

// sticky table cell component
const StickyTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "left",
})<StickyTableCellProps>(({ theme, left }) => ({
  position: "sticky",
  left: left,
  backgroundColor: theme.palette.background.paper,
  zIndex: 12,
  borderRight: "1px solid #e0e0e0", // Adds a border for visual separation
  width: "100px",
}));

export default function ProjectValuationSummaryView({
  getProjectValuation,
  editProjectValuation,
  addProjectValuation,
}: IProps) {
  const styles = useStyles();

  const { projectId } = useParams();

  const [projectValuation, setProjectValuation] =
    React.useState<IProjectValuation>();
  const [projectValuationForm, setProjectValuationForm] =
    React.useState<IProjectValuationForm>(PROJECT_VALUATION_FORM_DEFAULT_STATE);
  const [openProjectValuationForm, setOpenProjectValuationForm] =
    React.useState<boolean>(false);
  const [openEditProjectValuationForm, setOpenEditProjectValuationForm] =
    React.useState<boolean>(false);

  const handleOpenAddProjectValuationForm = () => {
    setOpenProjectValuationForm(true);
  };
  const handleCloseAddProjectValuationForm = () => {
    setOpenProjectValuationForm(false);
  };

  const handleOpenEditProjectValuationForm = () => {
    const {
      contracted_discount_rate,
      developer_profit,
      fmv_method,
      itc_adder_domestic_content,
      itc_adder_energy_community,
      itc_adder_environmental_justice,
      itc_adder_special_qualified_ej,
      itc_eligible_basis_method,
      merchant_discount_rate,
    } = projectValuation!;
    setProjectValuationForm({
      contracted_discount_rate,
      developer_profit,
      fmv_method,
      itc_adder_domestic_content: itc_adder_domestic_content ?? false,
      itc_adder_energy_community: itc_adder_energy_community ?? false,
      itc_adder_environmental_justice: itc_adder_environmental_justice ?? false,
      itc_adder_special_qualified_ej: itc_adder_special_qualified_ej ?? false,
      itc_eligible_basis_method,
      merchant_discount_rate,
    });
    setOpenEditProjectValuationForm(true);
  };

  const handleCloseEditProjectValuationForm = () => {
    setOpenEditProjectValuationForm(false);
  };

  const {
    callAPI: getProjectValuationCallAPI,
    loading: getProjectValuationLoading,
    errored: getProjectValuationFailed,
  } = useAPI((projectId) => getProjectValuation(Number(projectId)), {
    initialLoading: true,
  });

  const {
    callAPI: editProjectValuationCallAPI,
    fieldErrors: editProjectValuationFormErrors,
    setFieldErrors: setEditProjectValuationFormErrors,
    loading: editProjectValuationLoading,
  } = useAPI<IProjectValuation, IProjectValuationFormErrors>(
    (projectId: number, valuationId: number, form: IProjectValuationForm) =>
      editProjectValuation(projectId, valuationId, form),
  );

  const {
    callAPI: addProjectValuationCallAPI,
    fieldErrors: addProjectValuationFormErrors,
    setFieldErrors: setAddProjectValuationFormErrors,
    loading: addProjectValuationLoading,
  } = useAPI<IProjectValuation, IProjectValuationFormErrors>(
    (projectId: number, form: IProjectValuationForm) =>
      addProjectValuation(projectId, form),
  );

  const onAddProjectValuation = async (form: IProjectValuationForm) => {
    const pValuation = await addProjectValuationCallAPI(projectId, form);
    pValuation && setProjectValuation(pValuation);
    return pValuation;
  };

  const onEditProjectValuation = async (form: IProjectValuationForm) => {
    const pValuation = await editProjectValuationCallAPI(
      projectId,
      projectValuation?.id,
      form,
    );
    pValuation && setProjectValuation(pValuation);
    return pValuation;
  };

  React.useEffect(() => {
    getProjectValuationCallAPI(projectId).then((res) => {
      res && setProjectValuation(res[0]);
    });
  }, [projectId]);

  const formatProjectValuationReportFields = (
    valuation: IProjectValuationReportItem,
  ): string => {
    let value = String(valuation.value);
    const dtype = String(valuation.dtype);
    const suffix = String(valuation.suffix);
    const prefix = String(valuation.prefix);

    if (dtype === "DATE") {
      value = format(new Date(convertDateString(value)), "M/d/yyyy");
    } else if (dtype === "DOLLAR") {
      value = formatCurrency(Number(value));
    } else if (dtype === "PERCENT") {
      value = `${formatNumberWithDecimals(Number(value), 2)}%`;
    }

    if (prefix) {
      value = `${prefix}${value}`;
    }
    if (suffix) {
      value = `${value}${suffix}`;
    }

    return value;
  };

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const projectValuationLogConfiguration: ILogsConfiguration = {
    id: Number(projectValuation?.id),
    type: "projectvaluationconfiguration",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      projectValuationLogConfiguration.type,
      projectValuationLogConfiguration.id,
    );
  };

  return (
    <ViewWrapper
      loading={getProjectValuationLoading}
      error={getProjectValuationFailed}
    >
      {!projectValuation && (
        <Box className={styles.classes.emptyContainer}>
          <Box>
            <Protect permission={USER_PERMISSIONS.PROJECTS_CRUD}>
              <Button
                canOpenUpgrade
                startIcon={<AddIcon />}
                label="Add Project Valuation"
                onClick={handleOpenAddProjectValuationForm}
                btnType="primary"
                className={styles.classes.createBtn}
              />
            </Protect>
            <Typography
              variant="body1"
              className={styles.classes.createInfo}
              data-pw="empty-project-valuation-summary-message"
            >
              Project Valuation does not exist, click on Add Project Valuation
              to add one.
            </Typography>
            <ValuationDisclaimer
              classes={{ root: cn("max-w-md m-auto mt-4") }}
            />
          </Box>
        </Box>
      )}

      {projectValuation && (
        <React.Fragment>
          <Box className={cn("flex justify-end gap-4 mb-4")}>
            <IconButton onClick={handleOnOpenLogs}>
              <LogsIcon />
            </IconButton>
            <Protect permission={USER_PERMISSIONS.PROJECTS_CRUD}>
              <Button
                canOpenUpgrade
                startIcon={<EditIcon />}
                label="Edit Project Valuation"
                onClick={handleOpenEditProjectValuationForm}
                btnType="primary"
              />
            </Protect>
          </Box>

          <ValuationDisclaimer classes={{ root: cn("max-w-fit mb-4") }} />
          <Box className={cn("grid grid-cols-2 gap-4")}>
            <DetailsCard
              heading="Project Valuation Inputs"
              dataPw="project-valuation-inputs-card"
              height="max-content"
              sections={[
                {
                  fields: [
                    {
                      label: "Contracted Discount Rate",
                      value: {
                        text:
                          (projectValuation.contracted_discount_rate ?? 0) +
                          "%",
                      },
                    },
                    {
                      label: "Merchant Discount Rate",
                      value: {
                        text:
                          (projectValuation.merchant_discount_rate ?? 0) + "%",
                      },
                    },
                    {
                      label: "Developer Profit",
                      value: {
                        text: (projectValuation.developer_profit ?? 0) + "%",
                      },
                    },
                    {
                      label: "ITC Eligible Basis Method",
                      value: {
                        text: PROJECT_VALUATION_ELIGIBLE_BASIS_METHODS[
                          projectValuation.itc_eligible_basis_method
                        ],
                      },
                    },
                    {
                      label: "FMV Method",
                      value: {
                        text: PROJECT_VALUATION_FMV_METHODS[
                          projectValuation.fmv_method
                        ],
                      },
                    },
                  ],
                },
                {
                  heading: "Tax Credit Adders",
                  fields: [
                    {
                      label: "Domestic Content",
                      value: {
                        text: projectValuation.itc_adder_domestic_content
                          ? "Yes"
                          : "No",
                      },
                    },
                    {
                      label: "Energy Community",
                      value: {
                        text: projectValuation.itc_adder_energy_community
                          ? "Yes"
                          : "No",
                      },
                    },
                    {
                      label: "Environmental Justice (EJ)",
                      value: {
                        text: projectValuation.itc_adder_environmental_justice
                          ? "Yes"
                          : "No",
                      },
                    },
                    {
                      label: "Special Qualified EJ",
                      value: {
                        text: projectValuation.itc_adder_special_qualified_ej
                          ? "Yes"
                          : "No",
                      },
                    },
                  ],
                },
              ]}
            />

            <DetailsCard
              heading="Project Valuation"
              sections={[
                {
                  fields: projectValuation.valuation_summary.map(
                    (valuation) => {
                      return {
                        label: valuation.label,
                        value: {
                          text: formatProjectValuationReportFields(valuation),
                        },
                      };
                    },
                  ),
                },
              ]}
              autoHeight
            />
          </Box>
        </React.Fragment>
      )}
      <ProjectValuationFormModal
        form={projectValuationForm}
        setForm={setProjectValuationForm}
        headerLabel="Add Project Valuation"
        loading={addProjectValuationLoading}
        onClose={handleCloseAddProjectValuationForm}
        open={openProjectValuationForm}
        onConfirm={onAddProjectValuation}
        setFormErrors={setAddProjectValuationFormErrors}
        formErrors={addProjectValuationFormErrors}
      />
      <ProjectValuationFormModal
        form={projectValuationForm}
        setForm={setProjectValuationForm}
        headerLabel="Edit Project Valuation"
        loading={editProjectValuationLoading}
        onClose={handleCloseEditProjectValuationForm}
        open={openEditProjectValuationForm}
        onConfirm={onEditProjectValuation}
        setFormErrors={setEditProjectValuationFormErrors}
        formErrors={editProjectValuationFormErrors}
      />
      <LogsWrapper
        label="Activity"
        onClose={handleCloseDrawer}
        open={isDrawerOpen}
      >
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={projectValuationLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={projectValuationLogConfiguration.id}
        />
      </LogsWrapper>
    </ViewWrapper>
  );
}
