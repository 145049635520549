import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro";

import { IReportTable } from "../../interfaces";
import { cn, generateReportTableV2Data } from "../../utils/helpers";

interface IProps {
  data: IReportTable;
}

export default function ReportTableV2({ data }: IProps): JSX.Element {
  const { rows, columns } = generateReportTableV2Data(data);

  const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) => {
    return row.hierarchy;
  };

  return (
    <DataGridPro
      columns={columns}
      rows={rows}
      density="compact"
      getTreeDataPath={getTreeDataPath}
      pinnedColumns={{ left: ["__tree_data_group__", "Total"] }}
      classes={{ columnHeaderTitle: cn("!font-bold text-[15px]") }}
      columnHeaderHeight={70}
      groupingColDef={{
        headerName: "Name",
        minWidth: 300,
        hideDescendantCount: true,
      }}
      getCellClassName={(params) => {
        return params.colDef.field === "Total" ? cn("font-bold") : "";
      }}
      isGroupExpandedByDefault={(group) => {
        return group.depth === 0;
      }}
      treeData
      hideFooter
      disableColumnMenu
      disableAutosize
      disableColumnResize
    />
  );
}
