import { format } from "date-fns";

import { convertDateString, numberToUSD } from "./common.helpers";
import {
  IProject,
  IProjectCostSchduleResponseData,
  IProjectProduction,
  ITableCell,
  ITableColumn,
  ITableRow,
} from "../../interfaces";

export const updateStageInProjectList = (projects: IProject[]) => {
  return projects.map((p) => ({
    ...p,
    full_stage: p.stage + p.sub_stage,
  }));
};

export const getProjectSeasonalityAdjustmentFactors = (
  projectProduction: IProjectProduction,
) => {
  switch (projectProduction.seasonality_adjustment_type) {
    case "MO": {
      return Array.from({ length: 12 }, (_, i) => ({
        name: format(new Date(2021, i, 1), "MMMM"),
        value: String(
          (projectProduction.seasonality_adjustment_factors || [])[i],
        ),
      }));
    }
    case "QT": {
      return Array.from({ length: 4 }, (_, i) => ({
        name: `Quarter ${i + 1}`,
        value: String(
          (projectProduction.seasonality_adjustment_factors || [])[i],
        ),
      }));
    }
    case "SA": {
      return [
        {
          name: "H1",
          value: String(
            (projectProduction.seasonality_adjustment_factors || [])[0],
          ),
        },
        {
          name: "H2",
          value: String(
            (projectProduction.seasonality_adjustment_factors || [])[1],
          ),
        },
      ];
    }
    default: {
      return [];
    }
  }
};

export function generateProjectCostScheduleRows(
  data: IProjectCostSchduleResponseData,
): ITableRow[] {
  const dates = Object.keys(data.items[0].series);
  const rows: ITableRow[] = [];

  // Separate items into cost type and credit_support type
  const costItems = data.items.filter((item) => item.cost_type === "cost");
  const creditSupportItems = data.items.filter(
    (item) => item.cost_type === "credit_support",
  );

  dates.forEach((date) => {
    const row: ITableRow = {
      cells: [
        {
          align: "left",
          value: format(new Date(convertDateString(date)), "M/d/yyyy"),
        },
      ],
    };

    costItems.forEach((item) => {
      row.cells.push({
        align: "left",
        value: numberToUSD.format(item.series[date]),
      });
    });

    row.cells.push({
      align: "left",
      value: numberToUSD.format(data.total.series[date]),
    });

    creditSupportItems.forEach((item) => {
      row.cells.push({
        align: "left",
        value: numberToUSD.format(item.series[date]),
      });
    });

    rows.push(row);
  });

  const totalRow: ITableRow = { cells: [{ align: "left", value: "Total" }] };

  costItems.forEach((item) => {
    totalRow.cells.push({
      align: "left",
      value: numberToUSD.format(item.total),
    });
  });

  totalRow.cells.push({
    align: "left",
    value: numberToUSD.format(data.total.total),
  });

  creditSupportItems.forEach((item) => {
    totalRow.cells.push({
      align: "left",
      value: numberToUSD.format(item.total),
    });
  });

  return [totalRow, ...rows, totalRow];
}

export function generateProjectCostScheduleColumns(
  data: IProjectCostSchduleResponseData | undefined,
): ITableColumn[] {
  const columns: ITableColumn[] = [
    { id: "Date", label: "Date", minWidth: 80, align: "left" },
  ];

  if (!data) return columns;

  // Separate items into cost type and credit_support type
  const costItems = data.items.filter((item) => item.cost_type === "cost");
  const creditSupportItems = data.items.filter(
    (item) => item.cost_type === "credit_support",
  );

  costItems.forEach((item) => {
    columns.push({
      align: "left",
      label: item.name,
      id: `${item.name}_${item.type}`,
      minWidth: 120,
    });
  });

  columns.push({
    align: "left",
    label: "Project Cost",
    id: "Project-Cost",
    minWidth: 100,
  });

  creditSupportItems.forEach((item) => {
    columns.push({
      align: "left",
      label: item.name,
      id: `${item.name}_${item.type}`,
      minWidth: 120,
    });
  });

  return columns;
}

export function generateProjectCostScheduleDetailsColumns(
  data: IProjectCostSchduleResponseData | undefined,
  totalLabel: string,
): ITableColumn[] {
  if (!data) return [];

  const columns: ITableColumn[] = [
    {
      label: "Dates",
      id: "date",
      align: "left",
      minWidth: 60,
      fontLight: true,
    },
  ];

  // Separate items into cost type and credit_support type
  const costItems = data.items.filter((item) => item.cost_type === "cost");
  const creditSupportItems = data.items.filter(
    (item) => item.cost_type === "credit_support",
  );

  costItems.forEach((item) => {
    columns.push({
      label: item.name,
      id: `${item.name}_${item.type}`,
      align: "left",
      minWidth: 120,
      fontLight: item.item_grouping !== "grouped",
    });
  });

  columns.push({
    label: totalLabel,
    id: `Total-${totalLabel}`,
    align: "left",
    minWidth: 100,
  });

  creditSupportItems.forEach((item) => {
    columns.push({
      label: item.name,
      id: `${item.name}_${item.type}`,
      align: "left",
      minWidth: 120,
      fontLight: item.item_grouping !== "grouped",
    });
  });

  return columns;
}

export function generateProjectCostScheduleDetailsRows(
  data: IProjectCostSchduleResponseData,
): ITableRow[] {
  // Extract unique dates from the series
  const dates = Object.keys(data.items[0].series);

  // Initialize rows array
  const rows: ITableRow[] = [];

  // Separate items into cost type and credit_support type
  const costItems = data.items.filter((item) => item.cost_type === "cost");
  const creditSupportItems = data.items.filter(
    (item) => item.cost_type === "credit_support",
  );

  // Iterate over each date
  dates.forEach((date) => {
    // Create a new row object for the current date
    const row: ITableRow = {
      cells: [
        {
          align: "left",
          value: format(new Date(convertDateString(date)), "M/d/yyyy"),
        },
      ],
    };

    // Populate cells array with item costs for current date
    costItems.forEach((item) => {
      const cell: ITableCell = {
        align: "left",
        value: numberToUSD.format(item.series[date]),
        bold: item.item_grouping === "grouped",
      };
      row.cells.push(cell);
    });

    // Add total cost for current date to cells array
    row.cells.push({
      align: "left",
      value: numberToUSD.format(data.total.series[date]),
      bold: true,
    });

    // Add credit_support items after the total column
    creditSupportItems.forEach((item) => {
      const cell: ITableCell = {
        align: "left",
        value: numberToUSD.format(item.series[date]),
        bold: item.item_grouping === "grouped",
      };
      row.cells.push(cell);
    });

    // Push the completed row object to rows array
    rows.push(row);
  });

  // Create totals row
  const totalsRow: ITableRow = {
    cells: [{ align: "left", value: "Total" }],
  };

  // Populate totalsRow with total costs for each item and overall total
  costItems.forEach((item) => {
    totalsRow.cells.push({
      align: "left",
      value: numberToUSD.format(item.total),
      bold: item.item_grouping === "grouped",
    });
  });

  totalsRow.cells.push({
    align: "left",
    value: numberToUSD.format(data.total.total),
    bold: true,
  });

  // Add credit_support items to the totals row
  creditSupportItems.forEach((item) => {
    totalsRow.cells.push({
      align: "left",
      value: numberToUSD.format(item.total),
      bold: item.item_grouping === "grouped",
    });
  });

  // Return an array with totalsRow followed by all date rows and then totalsRow again
  return [totalsRow, ...rows, totalsRow];
}
