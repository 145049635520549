import React from "react";
import { useParams } from "react-router-dom";

import ViewWrapper from "../../../../../components/view-wrapper";
import ReportTable from "../../../../../components/report-table";
import DealOutputFilterFields from "../../../../../components/deal-output-filter-fields";
import { CAPITAL_ACCOUNTS_REPORT_TABLE_COLUMNS } from "../../../../../constants";
import { useAPI, useSessionStorage } from "../../../../../utils/hooks";
import { AppContext } from "../../../../../utils/context/app-context";
import { downloadUserDealReport } from "../../../../../apis/report/deal";
import { useAxios } from "../../../../../components/axios-provider";
import {
  getDealReportingUrl,
  generateCapitalAccountsReportTableRows,
} from "../../../../../utils/helpers";
import {
  IReport,
  ITableRow,
  ReportTerm,
  ReportType,
  ReportPerspective,
  OutputReportPeriodicityType,
} from "../../../../../interfaces";

interface IProps {
  getDealReporting: (
    dealId: number,
    reportPerspective: ReportPerspective,
    reportType: ReportType,
    reportTerm: ReportTerm,
  ) => Promise<IReport>;
}

export default function DealOutputTaxEquityCapitalAccountsView({
  getDealReporting,
}: IProps): JSX.Element {
  const { dealId, caseDealId } = useParams();

  const { urlLoadingPercentages } = useAxios();

  const [report, setReport] = React.useState<IReport>();
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  const { setDealOutputLoaded } = React.useContext(AppContext);

  const [buyout, setBuyout] = useSessionStorage<ReportTerm>(
    `deal-${dealId}-output-buyout`,
    "default",
  );
  const [periodicityType, setPeriodicityType] =
    useSessionStorage<OutputReportPeriodicityType>(
      `deal-${dealId}-output-periodicity`,
      "MO",
    );

  React.useEffect(() => {
    if (Number(caseDealId)) {
      getDealReportingCallAPI(Number(caseDealId), buyout).then((response) => {
        if (response?.["pship/capital"]) {
          setReport(response);
        }
      });
    }
  }, [caseDealId, buyout]);

  const {
    callAPI: getDealReportingCallAPI,
    errored: getReportFailed,
    loading: loadingReport,
    errorObj: getReportErrorObj,
    showUsageLimitHitScreen,
  } = useAPI(
    (dealId: number, term: ReportTerm) =>
      getDealReporting(dealId, "taxequity", "capitalaccounts", term),
    {
      initialLoading: true,
    },
  );

  React.useEffect(() => {
    !loadingReport && setDealOutputLoaded(!getReportFailed);
  }, [loadingReport]);

  const cashTableRows: ITableRow[] = React.useMemo(() => {
    return report ? generateCapitalAccountsReportTableRows(report) : [];
  }, [report]);

  const handleDownloadReport = async () => {
    if (caseDealId) {
      setIsDownloading(true);
      await downloadUserDealReport(
        Number(caseDealId),
        "taxequity",
        "capitalaccounts",
        buyout,
        periodicityType,
      ).catch(() => null);
      setIsDownloading(false);
    }
  };

  return (
    <ViewWrapper
      loading={loadingReport}
      error={getReportFailed}
      errorHeading={getReportErrorObj?.heading}
      errorDescription={getReportErrorObj?.description}
      showLimitHitView={showUsageLimitHitScreen}
      loadingPercentage={
        urlLoadingPercentages[
          getDealReportingUrl(
            Number(caseDealId),
            "taxequity",
            "capitalaccounts",
            buyout,
          )
        ]
      }
    >
      <DealOutputFilterFields
        buyout={buyout}
        periodicityType={periodicityType}
        setBuyout={setBuyout}
        setPeriodicityType={setPeriodicityType}
        showBuyout={report?.has_investor_buyout}
        downloadReport={handleDownloadReport}
        disableDownload={isDownloading}
        showSizingRedirectionButton
      />

      <ReportTable
        columns={CAPITAL_ACCOUNTS_REPORT_TABLE_COLUMNS}
        rows={cashTableRows}
        boldTotal
        stickyTotal
      />
    </ViewWrapper>
  );
}
