import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  BarElement,
  BarController,
  TooltipItem,
  ChartEvent,
  ActiveElement,
} from "chart.js";

import {
  IDealReportByStage,
  IPipelineReportByYear,
  IProjectsStageReport,
  ProjectStageMap,
} from "../../../interfaces";
import { PROJECT_STAGE_OPTIONS } from "../../../constants";
import { AppContext } from "../../../utils/context/app-context";
import { useScreenWidth } from "../../../utils/hooks";

ChartJS.register(BarElement, BarController);

type ChartTypes = "dealStage" | "projectsStage" | "pipelineByYear";

interface IProps {
  projectsStage: IProjectsStageReport;
  pipelineByYear: IPipelineReportByYear;
  dealByStage: IDealReportByStage;
  showChart: ChartTypes;
  filter?: keyof ProjectStageMap | "";
}

const commonChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (ctx: TooltipItem<"bar">) => {
          const dataset = ctx.dataset.label;
          switch (dataset) {
            case "MW":
              return String(ctx.parsed.y) + " MW";
            case "Count":
              return String(ctx.parsed.y) + " Count";
            default:
              return String(ctx.parsed.y);
          }
        },
      },
    },
  },
  onHover: (e: ChartEvent, el: ActiveElement[]) => {
    const target = e.native?.target as HTMLElement;
    if (target?.style) {
      if (el.length) {
        target.style.cursor = "pointer";
      } else {
        target.style.cursor = "default";
      }
    }
  },
  scales: {
    x: {
      ticks: {
        autoSkip: true,
        maxTicksLimit: 15,
      },
    },
    y: {
      title: {
        display: true,
        text: "Megawatts (MW)",
      },
    },
  },
};

export default function Chart({
  dealByStage,
  pipelineByYear,
  projectsStage,
  showChart,
  filter,
}: IProps): JSX.Element {
  const navigate = useNavigate();
  const width = useScreenWidth();

  const { ctrlPressed } = React.useContext(AppContext);

  const dealByStageRef = React.useRef<HTMLCanvasElement | null>(null);
  const pipelineByYearRef = React.useRef<HTMLCanvasElement | null>(null);
  const projectsStageRef = React.useRef<HTMLCanvasElement | null>(null);

  function countTicksCallbackHandler(value: number | string) {
    // show only integer values for count because count can be integer only
    if (Number.isInteger(value)) {
      return value;
    }
    return undefined;
  }

  React.useEffect(() => {
    const projectsStage2DRef = projectsStageRef?.current?.getContext("2d");

    const projectsStageData = [
      { label: "Development", data: projectsStage.DEV, color: "#00B969" },
      {
        label: "Construction",
        data: projectsStage.CON,
        color: "#EDA211",
      },
      { label: "Operation", data: projectsStage.OP, color: "#686868" },
    ];

    const projectsStageChart =
      projectsStage2DRef &&
      new ChartJS(projectsStage2DRef as CanvasRenderingContext2D, {
        type: "bar",
        data: {
          labels: projectsStageData.map((pSD) => pSD.label),
          datasets: [
            {
              label: "Count",
              type: "line",
              yAxisID: "y1",
              pointStyle: "rectRot",
              showLine: false,
              backgroundColor: "#ddd",
              data: projectsStageData.map((pSD) => pSD.data.count),
              pointRadius: projectsStageData.map((pSD) =>
                pSD.data.count === 0 ? 0 : 6,
              ),
              pointHoverRadius: 9,
            },
            {
              label: "MW",
              data: projectsStageData.map((pSD) => pSD.data.mwac),
              yAxisID: "y",
              backgroundColor: projectsStageData.map((pSD) => pSD.color),
            },
          ],
        },
        options: {
          ...commonChartOptions,
          aspectRatio: 1.3,
          onClick: (e, c) => {
            const { Development, Construction, Operations } =
              PROJECT_STAGE_OPTIONS;
            const projectPhase = [
              Development.map((o) => o.value).join(","),
              Construction.map((o) => o.value).join(","),
              Operations.map((o) => o.value).join(","),
            ];
            const clickedPhase = c[0]?.index;
            if (clickedPhase >= 0) {
              if (ctrlPressed) {
                window.open(
                  `/project-list?sub_stage=${projectPhase[clickedPhase]}`,
                );
                return;
              }
              navigate({
                pathname: "/project-list",
                search: `sub_stage=${projectPhase[clickedPhase]}`,
              });
            }
          },
          scales: {
            y: {
              title: {
                display: true,
                text: "Megawatts (MW)",
              },
            },
            y1: {
              title: {
                display: true,
                text: "Count",
              },
              position: "right",
              ticks: {
                callback: countTicksCallbackHandler,
              },
              grid: {
                drawOnChartArea: false,
              },
            },
          },
        },
      });

    return () => {
      projectsStageChart?.destroy();
    };
  }, [width, projectsStage]);

  React.useEffect(() => {
    const dealByStage2DRef = dealByStageRef?.current?.getContext("2d");
    const dealStageData = [
      {
        label: "Pre-Launch",
        data: dealByStage.PL,
        color: "#5335FA",
      },
      {
        label: "Accepting Bids",
        data: dealByStage.AB,
        color: "#5335FA",
      },
      {
        label: "Evaluating Bids",
        data: dealByStage.EB,
        color: "#5335FA",
      },
      {
        label: "Term Sheet",
        data: dealByStage.TS,
        color: "#5335FA",
      },
      {
        label: "Documentation",
        data: dealByStage.DOC,
        color: "#5335FA",
      },
      {
        label: "Closing",
        data: dealByStage.CL,
        color: "#5335FA",
      },
      {
        label: "Post-Closing",
        data: dealByStage.PCL,
        color: "#5335FA",
      },
    ];

    const dealByStageChart =
      dealByStage2DRef &&
      new ChartJS(dealByStage2DRef as CanvasRenderingContext2D, {
        type: "bar",
        data: {
          labels: dealStageData.map((pSD) => pSD.label),
          datasets: [
            {
              data: dealStageData.map((pSD) => pSD.data.count),
              pointRadius: dealStageData.map((pSD) =>
                pSD.data.count === 0 ? 0 : 8,
              ),
              pointHoverRadius: 12,
              label: "Count",
              type: "line",
              yAxisID: "y1",
              pointStyle: "rectRot",
              showLine: false,
              backgroundColor: "#ddd",
            },
            {
              label: "MW",
              data: dealStageData.map((pSD) => pSD.data.mwac),
              yAxisID: "y",
              backgroundColor: dealStageData.map((pSD) => pSD.color),
            },
          ],
        },
        options: {
          ...commonChartOptions,
          aspectRatio: width < 1500 ? 3 : 4.2,
          onClick: (e, c) => {
            const filterParams = ["PL", "AB", "EB", "TS", "DOC", "CL", "PCL"];
            const clickedType = c[0]?.index;
            if (clickedType >= 0) {
              if (ctrlPressed) {
                window.open(`/deal-list?stage=${filterParams[clickedType]}`);
                return;
              }
              navigate({
                pathname: "/deal-list",
                search: `stage=${filterParams[clickedType]}`,
              });
            }
          },
          scales: {
            y: {
              title: {
                display: true,
                text: "Megawatts (MW)",
              },
            },
            y1: {
              title: {
                display: true,
                text: "Count",
              },
              position: "right",
              ticks: {
                callback: countTicksCallbackHandler,
              },
              grid: {
                drawOnChartArea: false,
              },
            },
          },
        },
      });

    return () => {
      dealByStageChart?.destroy();
    };
  }, [width, dealByStage]);

  React.useEffect(() => {
    const pipelineByYear2DRef = pipelineByYearRef?.current?.getContext("2d");

    const pipelineByYearChart =
      pipelineByYear2DRef &&
      new ChartJS(pipelineByYear2DRef as CanvasRenderingContext2D, {
        type: "bar",
        data: {
          labels: Object.keys(pipelineByYear).map((k) => k),
          datasets: [
            {
              data: Object.keys(pipelineByYear).map(
                (k) => pipelineByYear[k].count,
              ),
              pointRadius: Object.keys(pipelineByYear).map((k) =>
                pipelineByYear[k].count === 0 ? 0 : 6,
              ),
              pointHoverRadius: 9,
              label: "Count",
              type: "line",
              yAxisID: "y1",
              pointStyle: "rectRot",
              showLine: false,
              backgroundColor: "#ddd",
            },
            {
              label: "MW",
              data: Object.keys(pipelineByYear).map(
                (k) => pipelineByYear[k].mwac,
              ),
              yAxisID: "y",
              backgroundColor: "#00B969",
            },
          ],
        },
        options: {
          ...commonChartOptions,
          aspectRatio: 2.4,
          onClick: (e, c) => {
            const cod_years = Object.keys(pipelineByYear);
            const clickedYear = c[0]?.index;
            if (clickedYear >= 0) {
              if (ctrlPressed) {
                if (filter !== "") {
                  window.open(
                    `/project-list?cod=${cod_years[clickedYear]}&project_stage=${filter}`,
                  );
                } else {
                  window.open(`/project-list?cod=${cod_years[clickedYear]}`);
                }
                return;
              }
              if (filter !== "") {
                navigate({
                  pathname: "/project-list",
                  search: `cod=${cod_years[clickedYear]}&project_stage=${filter}`,
                });
              } else {
                navigate({
                  pathname: "/project-list",
                  search: `cod=${cod_years[clickedYear]}`,
                });
              }
            }
          },
          scales: {
            y: {
              title: {
                display: true,
                text: "Megawatts (MW)",
              },
            },
            y1: {
              title: {
                display: true,
                text: "Count",
              },
              position: "right",
              ticks: {
                callback: countTicksCallbackHandler,
              },
              grid: {
                drawOnChartArea: false,
              },
            },
          },
        },
      });

    return () => {
      pipelineByYearChart?.destroy();
    };
  }, [width, pipelineByYear]);

  const selectedChart = (chart: ChartTypes) => {
    switch (chart) {
      case "dealStage":
        return dealByStageRef;
      case "projectsStage":
        return projectsStageRef;
      case "pipelineByYear":
        return pipelineByYearRef;
      default:
        return undefined;
    }
  };

  return (
    <div>
      <canvas id={showChart} ref={selectedChart(showChart)} />
    </div>
  );
}
